// import { Tabs } from "antd";
import { FC, useEffect, useState } from "react";
import { UserAccess } from "../../../common/constants";
import { isCorporateProgrammeModuleAvailable } from "../../../common/functions";
import AccessDenied from "../../common/AccessDenied";
import { Tab, Row, Col, Nav } from 'react-bootstrap';
import { navContentStyle, navStyle } from "../../../common/components-style";
import InviteSelection from "./form/InviteSelection";
import InviteParameters from "./form/InviteParameters";
import PropertyNotInvite from "./form/PropertyNotInvite";



const InviteSelectionForm: FC<any> = ({ location }): JSX.Element => {
    const [tabKey, setTabKey] = useState<any>('inviteProperties');


    useEffect(() => {

        if (location) {
            setTabKey(location?.state?.tab)
        }
    }, [location, location?.state?.tab])


    return (
        <div style={{ marginLeft: '-40px', marginTop: '-38px' }} >
            <Tab.Container defaultActiveKey="inviteProperties" key={tabKey} activeKey={tabKey} onSelect={(tab) => setTabKey(tab)}>
                <Row >
                    <Col sm={2}>
                        <Nav variant="pills" className="flex-column secondry-menu" style={navStyle}>
                            <div style={{ marginTop: '0%' }}>

                                {<Nav.Item><Nav.Link eventKey="inviteProperties">Properties To Invite </Nav.Link></Nav.Item>}
                                {<Nav.Item><Nav.Link eventKey="inviteParameters">Invite Parameters</Nav.Link></Nav.Item>}
                                {<Nav.Item><Nav.Link eventKey="otherProperties">Properties Not Invited</Nav.Link></Nav.Item>}
                            </div>
                        </Nav>
                    </Col>
                    <Col sm={10}>
                        <Tab.Content >
                            <Tab.Pane eventKey="inviteProperties" >
                                <div style={navContentStyle}>
                                    {/* <p>test</p> */}
                                    {isCorporateProgrammeModuleAvailable(location?.state?.selectedProgramme?.clientId, location?.state?.selectedProgramme?.key, UserAccess.programme_access.programmeAdmin.code) ? <InviteSelection rfpName={location?.state?.rfpName} rfpColor={location?.state?.rfpColor} rfpStatus={location?.state?.rfpStatus} programmeId={location?.state?.programmeId} rfpProcessId={location?.state?.rfpProcessId} selectedClient={location?.state?.selectedClient} selectedProgramme={location?.state?.selectedProgramme} selectedClientNames={location?.state?.selectedClientName} selectedRfpProcess={location?.state?.selectedRfpProcess} /> : <AccessDenied />}
                                </div>
                            </Tab.Pane>
                            {<Tab.Pane eventKey="inviteParameters">
                                <div style={navContentStyle}>
                                    {isCorporateProgrammeModuleAvailable(location?.state?.selectedProgramme?.clientId, location?.state?.selectedProgramme?.key, UserAccess.programme_access.programmeAdmin.code) ? <InviteParameters rfpName={location?.state?.rfpName} rfpColor={location?.state?.rfpColor} rfpStatus={location?.state?.rfpStatus} programmeId={location?.state?.programmeId} rfpProcessId={location?.state?.rfpProcessId} selectedClient={location?.state?.selectedClient} selectedProgramme={location?.state?.selectedProgramme} selectedClientNames={location?.state?.selectedClientName} selectedRfpProcess={location?.state?.selectedRfpProcess} setTabKey={setTabKey}/> : <AccessDenied />}
                                </div>
                            </Tab.Pane>}
                            {<Tab.Pane eventKey="otherProperties">
                                <div style={navContentStyle}>
                                    {isCorporateProgrammeModuleAvailable(location?.state?.selectedProgramme?.clientId, location?.state?.selectedProgramme?.key, UserAccess.programme_access.programmeAdmin.code) ? <PropertyNotInvite rfpName={location?.state?.rfpName} rfpColor={location?.state?.rfpColor} rfpStatus={location?.state?.rfpStatus} programmeId={location?.state?.programmeId} rfpProcessId={location?.state?.rfpProcessId} selectedClient={location?.state?.selectedClient} selectedProgramme={location?.state?.selectedProgramme} selectedClientNames={location?.state?.selectedClientName} selectedRfpProcess={location?.state?.selectedRfpProcess} /> : <AccessDenied />}
                                </div>
                            </Tab.Pane>}
                        </Tab.Content>
                    </Col>
                </Row>
            </Tab.Container>

            {/* :isCorporateProgrammeModuleAvailable(location?.state?.selectedProgramme?.clientId, location?.state?.selectedProgramme?.key, UserAccess.programme_access.programmeAdmin.code) ? <RfpProcessDetails programmeId={location?.state?.programmeId} rfpProcessId={location?.state?.rfpProcessId} setFormOpen={location?.state?.setFormOpen} reload={location?.state?.reload} selectedClient={location?.state?.selectedClient} selectedProgramme={location?.state?.selectedProgramme} /> : <AccessDenied /> */}


        </div>
    )
}


export default InviteSelectionForm;
