import React, { useCallback, useEffect, useState } from 'react';
import { DirectionsRenderer, GoogleMap, InfoWindow, Marker, withGoogleMap, withScriptjs } from "react-google-maps";

const ShowCommuteMap: React.FC<any> = ({ location, commuteBuilding, travelMode, onResponse }): JSX.Element => {
   const [lat, setLat] = useState<number>(0);
   const [lng, setLng] = useState<number>(0);
   const [locLat, setLocLat] = useState<number>(0);
   const [buildingLat, setBuildingLat] = useState<number>(0);
   const [buildingLng, setBuildingLng] = useState<number>(0);
   const destination = { lat: buildingLat, lng: buildingLng };
   const [directions, setDirections] = useState<any>('');
   const origin = { lat: lat, lng: lng };
   const DirectionsService = new google.maps.DirectionsService();
   const [activeMarker, setActiveMarker] = useState(null);
   const [travelModeChange, setTravelModeChange] = useState('');



   DirectionsService.route(
      {
         origin: origin,
         destination: destination,
         travelMode: travelMode,

      },
      (result, status) => {
         if (status === google.maps.DirectionsStatus.OK) {

            if (locLat !== lat || travelModeChange !== travelMode) {
               setDirections(result);
               onResponse(result);
               setTravelModeChange(travelMode);
               setLocLat(lat)
            }
         }
      }
   )


   ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
   useEffect(() => {
      if (location) {
         setLat(location.lat);
         setLng(location.lng);
         setBuildingLat(Number(commuteBuilding?.property?.latitude));
         setBuildingLng(Number(commuteBuilding?.property?.longitude));
      } else {
         navigator.geolocation.getCurrentPosition(function (position) {
            setLat(position.coords.latitude);
            setLng(position.coords.longitude);
         });
      }
   }, [commuteBuilding, location]);

   //////////////////////////////////////////////////////////////////////////////////////////////////////////////////
   const onDragEnd = useCallback(({ latLng }) => {
      setLat(latLng.lat());
      setLng(latLng.lng());
   }, []);

   ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
   const handleActiveMarker = (marker) => {
      if (marker === activeMarker) {
         return;
      }

      setActiveMarker(marker);
   };

   /////////////////////////////////////////////////////////////////////////////////////////////////////////////////
   return (
      <div className="google-map">
         <GoogleMap
            key={Math.random()}
            defaultZoom={8}
            defaultCenter={{ lat, lng }}
         >
            <Marker draggable onDragEnd={onDragEnd} position={{ lat, lng }} />
            <Marker draggable onDragEnd={onDragEnd}
               position={{ lat: buildingLat, lng: buildingLng }}

               onClick={() => handleActiveMarker(commuteBuilding?.property?._id)}
            >
               {activeMarker === commuteBuilding?.property?._id ? (
                  <InfoWindow onCloseClick={() => setActiveMarker(null)} options={{}}>
                     <div>
                        <p>{commuteBuilding?.property?.propertyName}</p>
                        <p>{commuteBuilding?.property?.address1}</p>
                     </div>

                  </InfoWindow>
               ) : null}
            </Marker>
            <DirectionsRenderer
               directions={directions}
               options={{
                  polylineOptions: {
                     strokeOpacity: 1,
                     strokeColor: '#E7008C',
                  },
               }}
            />
         </GoogleMap>
      </div>
   )
};

export default withScriptjs(withGoogleMap(ShowCommuteMap));


