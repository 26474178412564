import { message } from "antd";
import moment from "moment";
import { OverlayTrigger, Tooltip, Modal, Form, Row, Col, Button, Spinner } from 'react-bootstrap'
import { FC, useCallback, useEffect, useMemo, useState } from "react";

import { brandRfpStatus, UserAccess } from "../../../common/constants";
import { canBrandModuleWrite, isBrandAdmin } from "../../../common/functions";
import DataTable from 'react-data-table-component';
import { tableStyles, overlayTriggerDelay, $mysaMegenta, $mysaTeal, $mysaRed } from '../../../common/components-style';
import { useStoreActions, useStoreState } from "easy-peasy";
import { QuestionForm } from "../../../common/interfaces";
import ViewEdit from '../../../images/icons/svg/outside/Icon-Function-View-Edit.svg';
import Archive from '../../../images/icons/svg/outside/Icon-Function-Archive.svg';
import Question from '../../../images/icons/svg/outside/Icon-Question.svg';
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { getStorage, setStorage } from "../../../common/storage";
import { useHistory } from "react-router-dom";

const BrandMyRfpProcessList: FC<any> = ({ shouldDataReload, setShouldDataReload, filteredBrandList, selectedBrandId }) => {
	const { register, handleSubmit, formState: { errors }, reset } = useForm<QuestionForm>();
	const [openModal, setOpenModal] = useState<boolean>(false);
	const [loading, setLoading] = useState<boolean>(false);
	const [declinedRfpProcess, setDeclinedRfpProcess] = useState<string>("");
	const [myRfpProcessesList, setMyRfpProcessesList] = useState<any>({});
	const [rfpId, setRfpId] = useState<any>();
	const today = moment(new Date());
	const myoSelectedBrand = getStorage("myo_selected_my_rfp_brand");
	const history = useHistory();

	//actions
	const {
		deleteBrandRfpProcess,
		getMyRfpProcessList,
		updateBrandQuestions
	} = useStoreActions<any>((actions) => ({
		deleteBrandRfpProcess: actions.rfpProcess.deleteBrandRfpProcess,
		getMyRfpProcessList: actions.rfpProcess.getMyRfpProcessList,
		updateBrandRFPData: actions.rfpProcess.setBrandRfpDetails,
		updateBrandQuestions: actions.rfpProcess.updateBrandQuestions
	}));

	//state
	const {
		deleteBrandRfpProcessSuccess,
		deleteBrandRfpProcessError,
		myRfpProcessListSuccess,
		myRfpProcessListError,
		updateBrandQuestionsSuccess,
		updateBrandQuestionsError
	} = useStoreState<any>((state) => ({
		deleteBrandRfpProcessSuccess: state.rfpProcess.deleteBrandRfpProcessSuccessResponse,
		deleteBrandRfpProcessLoading: state.rfpProcess.deleteBrandRfpProcessLoadingResponse,
		deleteBrandRfpProcessError: state.rfpProcess.deleteBrandRfpProcessErrorResponse,

		myRfpProcessListSuccess: state.rfpProcess.myRfpProcessListSuccess,
		myRfpProcessListError: state.rfpProcess.myRfpProcessListError,

		updateBrandQuestionsSuccess: state.rfpProcess.updateBrandQuestionsSuccess,
		updateBrandQuestionsError: state.rfpProcess.updateBrandQuestionsError
	}));

	useEffect(() => {
		if (myoSelectedBrand) {
			getMyRfpProcessList(myoSelectedBrand);
		} else {
			if (selectedBrandId) {
				getMyRfpProcessList(selectedBrandId);
			}
		}
		setStorage('myo_property_evaluation_tem', null);
		setStorage('myo_submission_management_tem', null);
		setStorage('myo_brand_evaluation_tem', null);
	}, [getMyRfpProcessList, myoSelectedBrand, selectedBrandId])

	// set updateRfpProcessSuccessResponse //////////////////////////////////////////////////////////////////////////////////////////
	useEffect(() => {
		if (updateBrandQuestionsSuccess) {
			setLoading(false);
			toast.success("Question submitted successfully", {
				position: toast.POSITION.BOTTOM_RIGHT,
				className: 'foo-bar'
			});
			setOpenModal(false);
			reset();
		}
		if (updateBrandQuestionsError) {
			setLoading(false);
			toast.error(updateBrandQuestionsError?.message, {
				position: toast.POSITION.BOTTOM_RIGHT,
				className: 'foo-bar'
			});
			setOpenModal(false);
		}
	}, [reset, updateBrandQuestionsError, updateBrandQuestionsSuccess]);

	/////////////////////////////////////////////////////
	useEffect(() => {
		if (shouldDataReload) {
			getMyRfpProcessList(myoSelectedBrand);
		}
		setShouldDataReload(false);
	}, [getMyRfpProcessList, myoSelectedBrand, setShouldDataReload, shouldDataReload])

	useEffect(() => {
		if (myRfpProcessListSuccess?.data) {
			setMyRfpProcessesList(myRfpProcessListSuccess?.data);
		}
		if (myRfpProcessListError?.message) {
			message.error(myRfpProcessListError?.message);
		}
	}, [myRfpProcessListError?.message, myRfpProcessListSuccess?.data])

	// initial rendered list ///////////////////////////////////////////////////////////////////////////////////
	const memoizedRfpProcessData = useMemo(() => {
		return myRfpProcessesList?.rfpIds?.filter(process => (process.brandRfpStatus !== brandRfpStatus.SUBMITTED && (new Date((new Date(process?.rfpId?.endDate)).setDate(new Date(process?.rfpId?.endDate).getDate() + 7)) > new Date())))
			.map((rfpData) => {
				const { _id, endDate, locations, clientId, programmeId,  overviewForInvite, keyClientRequirement, startDate,rfpName, questionSubmitDate } = rfpData?.rfpId;
				const brandSpecificLocations = rfpData?.locations;
				const cities = locations.map((item: any) => item.cities)
				const citiesArray = [].concat.apply([], cities);
				const cityNames = citiesArray.map((item: any) => item.cityName);
				return {
					key: myRfpProcessesList?._id,
					endDate,
					locations: cityNames?.length,
					locationsDetails: locations,
					companyName: clientId?.companyName,
					clientId: clientId?._id,
					programmeName: programmeId?.programmeName,
					overviewForInvite,
					keyClientRequirement,
					startDate,
					questionSubmitDate,
					brandId: myRfpProcessesList?.brandId,
					rfpId: _id,
					rfpName:rfpName,
					brandSpecificLocations: brandSpecificLocations,
					brandRfpStatus: rfpData?.brandRfpStatus,
					brandRfpId: rfpData?._id,
					certificateIdMYOManagement: rfpData?.certificateIdMYOManagement,
					rfpSubmission: rfpData?.rfpSubmission,
					brandSurveyDetailSubmission: rfpData?.brandSurveyDetailSubmission,
					propertySurveyDetailSubmission: rfpData?.propertySurveyDetailSubmission,
					accountManager:rfpData?.accountManager
				}
			});
	}, [myRfpProcessesList?._id, myRfpProcessesList?.brandId, myRfpProcessesList?.rfpIds]);

	// when decline button clicked ///////////////////////////////////////////////////////////////////////////////////////////////////
	useEffect(() => {
		if (declinedRfpProcess) {
			// setMyRfpProcessesList(myRfpProcessesList?.filter((item) => item._id !== declinedRfpProcess));
			// setDeclinedRfpProcess("");
		}
	}, [declinedRfpProcess]);

	// when remove process is successful ///////////////////////////////////////////////////////////////////////////////////////////////////
	useEffect(() => {
		if (deleteBrandRfpProcessSuccess) {
			setShouldDataReload(true);
		}
		if (deleteBrandRfpProcessError) {
		}
	}, [deleteBrandRfpProcessError, deleteBrandRfpProcessSuccess, setShouldDataReload])

	// when decline button clicked ///////////////////////////////////////////////////////////////////////////////////////////////////
	const handleDecline = useCallback(async (data) => {
		if (data?.key) {
			setDeclinedRfpProcess(data.key);
			deleteBrandRfpProcess({
				id: data.key, brandId: data?.brandId, rfpId: data?.rfpId
			});
		} else {
			setDeclinedRfpProcess("");
		}
	}, [deleteBrandRfpProcess]);

	/////////////////////////////////////////////////////////////////////////////////////////////////////
	// const handleQuestion = useCallback(async (data) => {
	// 	if (data) {

	// 		// if (moment(data.endDate).isSameOrBefore(today)) {
	// 		// 	setShowModal(true);
	// 		// } else {
	// 		// 	setShowModal(false);
	// 		// }
	// 	}

	// 	setOpenModal(true);

	// }, []);


	const handleOpen = useCallback((data) => {
		// setShowEvaluation(true);
		// need to create new route for brandevaluation page
		// /admin/brand-rfp-management/brandEvaluation

		const name = 'brandMyRfp';
		setTimeout(() => {
			history.push({
				pathname: `/admin/brand-rfp/${name}`,
				state: data,
			});
		}, 500);

		// updateBrandRFPData({ ...reduxBrandDetail, showHomePage: false, myRfpFormDetail: data, formData: data, showMyRfpBrandEvaluationPage: true, showMyRfpHomePage: false });
	}, [history]);

	////////////////////////////////////////  submit part ////////////////////////////////////////
	const onSubmit = useCallback((values) => {
		setLoading(true);
		const { userData } = getStorage('myo_logged_user');
		const formattedData = {
			brandId: myoSelectedBrand,
			rfpProcessId: rfpId,
			brandQuestion: values.brandQuestion,
			questionSubmittedBy: userData.firstName + ' ' + userData.lastName
		}

		updateBrandQuestions(formattedData);


	}, [myoSelectedBrand, rfpId, updateBrandQuestions]);

	//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	const columns1 = [
		{
			name: 'Company',
			// minWidth: '250px',
			selector: row => row.companyName,
		},
		{
			name: 'Programme',
			// minWidth: '300px',
			selector: row => {
				return (
					<OverlayTrigger

						placement="top"
						delay={overlayTriggerDelay}
						overlay={<Tooltip id='tooltip-table-top'>{row.programmeName}</Tooltip>}
					>
						<p style={{ marginBottom: '0px' }} >{String(row.programmeName).slice(0, 10) + "..."}</p>
					</OverlayTrigger>
				)
			}
		},
		{
			name: 'Name of Rfp',
			selector: row => row.rfpName,
			minWidth: '300px',
		},
		{
			name: 'Start date',
			selector: row => moment(row.startDate).format("D MMM YYYY"),
		},

		{
			name: 'End date',
			selector: row => moment(row.endDate).format("D MMM YYYY"),
		},
		{
			name: 'No. of Locations',
			selector: row => row.locations,
		},
		{
			name: 'Action',
			wrap: true,
			minWidth: '300px',
			cell: (data: any) => {
				return (<>
					<OverlayTrigger
						placement="top"
						delay={overlayTriggerDelay}
						overlay={<Tooltip id='tooltip-table-top'>View/Edit</Tooltip>}
					>
						<img className='iconSizes mr-2' src={ViewEdit} alt="" onClick={() => {
							handleOpen(data)
							setStorage("myo_selected_my_rfp", data);
						}} />
					</OverlayTrigger>

					<OverlayTrigger
						placement="top"
						delay={overlayTriggerDelay}
						overlay={<Tooltip id='tooltip-table-top'>Remove</Tooltip>}
					>
						<img className='iconSizes mr-2' src={Archive} alt="" hidden={(!canBrandModuleWrite(myoSelectedBrand !== '' ? myoSelectedBrand : filteredBrandList[0]._id, UserAccess.brand_access.tenders.code) && !isBrandAdmin(myoSelectedBrand !== '' ? myoSelectedBrand : filteredBrandList[0]._id))} onClick={() => { handleDecline(data) }} />
					</OverlayTrigger>

					<OverlayTrigger
						placement="top"
						delay={overlayTriggerDelay}
						overlay={<Tooltip id='tooltip-table-top'>Question</Tooltip>}
					>
						<img className='iconSizes3 mr-2' src={Question} alt="" hidden={(!canBrandModuleWrite(myoSelectedBrand !== '' ? myoSelectedBrand : filteredBrandList[0]._id, UserAccess.brand_access.tenders.code) && !isBrandAdmin(myoSelectedBrand !== '' ? myoSelectedBrand : filteredBrandList[0]._id)) || moment(data.questionSubmitDate).isBefore(today) === true || data.questionSubmitStatus === brandRfpStatus.COMPLETED} onClick={() => { setOpenModal(true); setRfpId(data?.rfpId); }} />
					</OverlayTrigger>
				</>)
			}
		},
		{
			name: "Status",
			selector: (row) => {
				let color = "";

				if (row.brandRfpStatus === brandRfpStatus.COMPLETED) color = $mysaTeal;
				if (row.brandRfpStatus === brandRfpStatus.SUBMITTED) color = $mysaTeal;
				if (row.brandRfpStatus === brandRfpStatus.INCOMPLETE) color = $mysaMegenta;
				if (row.brandRfpStatus !== brandRfpStatus.SUBMITTED && moment(row.endDate).add(1, 'days').isBefore(today)) color = $mysaRed;
				return (
					<span style={{ color: color, fontWeight: 'bold', textTransform: 'capitalize' }}>{row.brandRfpStatus !== brandRfpStatus.SUBMITTED && moment(row.endDate).add(1, 'days').isBefore(today) ? 'Closed' : row.brandRfpStatus}</span> 
				)
			}
		},
	];

	return (
		<>
			<div>
				<DataTable
					columns={columns1}
					data={memoizedRfpProcessData}
					pagination
					responsive
					customStyles={tableStyles}
					theme='mysaTable'
					striped={true}
					fixedHeader={true}
				/>
			</div>

			{/* {
				showEvaluation && <BrandAdminMyRfpDetails />
			} */}

			<Modal
				show={openModal}
				onHide={() => { setOpenModal(false); }}
				size="lg"
				aria-labelledby="contained-modal-title-vcenter"
				centered={false}

			>
				<Modal.Header closeButton>
				</Modal.Header>
				<Modal.Body>
					<Form onSubmit={handleSubmit(onSubmit)}>
						<Row>
							<Col md={1}></Col>
							<Col md={10}>
								<Row className='formSpace'>
									<Col style={{ textAlign: 'left' }}>
										<Form.Label>Please submit your question(s) in the text below.</Form.Label>
									</Col>
								</Row>
								<Row className='formSpace'>

									<Col style={{ textAlign: 'left' }}>
										<Form.Control as="textarea" rows={5} className={`${errors.brandQuestion ? 'is-invalid' : ''}`} defaultValue={''} {...register("brandQuestion", { required: true })} />
										<div className="invalid-feedback">Please input brand name!</div>
									</Col>
								</Row>
							</Col>
							<Col md={1}></Col>
						</Row>
						<Row>
							<Col md={1}></Col>
							<Col md={10}>
								<Row className='formSpace'>

									<Col >
										{
											!loading && <div style={{ textAlign: 'center' }}>

												<Button type="submit" id='edit-button' style={{
													height: "40px",
													width: '220px',
													borderRadius: 30,
													paddingLeft: "30px",
													paddingRight: "30px",
													marginRight: '20px'
												}}>Submit</Button>
											</div>
										}
										{
											loading && <div style={{ textAlign: 'center' }}><Spinner animation="border" variant="warning" /></div>
										}
									</Col>
								</Row>
							</Col>
							<Col md={1}></Col>
						</Row>
					</Form>
				</Modal.Body>
			</Modal>
		</>
	)
}

export default BrandMyRfpProcessList;