import React, { useCallback, useEffect, useState } from 'react';
import { Select, Checkbox } from 'antd';
import { canBrandPropertyModuleWrite } from '../../../../common/functions';
import { UserAccess, AmenityType } from '../../../../common/constants';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { Row, Col, Container, Form, Button, Spinner, Image } from 'react-bootstrap';
import { formButton } from '../../../../common/components-style';
import { Controller, useForm } from 'react-hook-form';
import { PropertyGuestComfortFormInput } from '../../../../common/interfaces';
import { ErrorMessage } from '@hookform/error-message';
import _ from 'lodash';
import { toast } from 'react-toastify';
import BreadCrumbPage from '../../../common/BreadCrumbPage';
// import { HelpModal } from '../../../common/HelpModal';

const { Option } = Select;
const plainOptions = [{ label: 'Duvet', value: 'Duvet', },
{ label: 'Fitted bed sheets', value: 'Fitted bed sheets' },

]
const GuestComfortDetailForm: React.FC<any> = ({ allInOnePropertyBreadCrumb, propertyId, selectedBrand, setFormOpen, tab }): JSX.Element => {

    ///////////////////// initialize form ////////////////////////////////////// 
    const { register, handleSubmit, formState: { errors }, control } = useForm<PropertyGuestComfortFormInput>();

    ///////////////////////// local state manage ////////////////////////////////////
    const [propertyData, setPropertyData] = useState<any>();
    const [guestComfortData, setGuestComfortData] = useState<any>();
    const [guestComfortCreate, setGuestComfortCreate] = useState<boolean>(false);
    const [amenityId, setAmenityId] = useState<any>();
    const [amenitiesShowList, setAmenitiesShowList] = useState<any>();
    const [amenitiesList, setAmenitiesList] = useState<any>();
    const [editEnable, setEditEnable] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [guestComfortId, setGuestComfort] = useState<boolean>();
    // const [doYouProvideWelcomePack, setDoYouProvideWelcomePack] = useState<boolean>();
    // const [houseKeeping, setHouseKeeping] = useState<boolean>();
    // const [houseKeepingRateIncluded, setHouseKeepingRateIncluded] = useState<boolean>();
    /////////////////////////// global state manage (actions)//////////////////////////////////////////////////////
    const { createGuestComfort, getGuestComfort, updateGuestComfort, getProperty, getAmenityList } = useStoreActions<any>((actions) => ({
        createGuestComfort: actions.property.createGuestComfort,
        getGuestComfort: actions.property.getGuestComfort,
        updateGuestComfort: actions.property.updateGuestComfort,
        getProperty: actions.property.getProperty,
        getAmenityList: actions.property.getAmenityList,
    }));

    /////////////////////////// global state manage (state)//////////////////////////////////////////////////////
    const { createGuestComfortSuccess, createGuestComfortError, getGuestComfortSuccess, updateGuestComfortSuccess, updateGuestComfortError, getPropertySuccess, getAmenityListSuccess } = useStoreState<any>((state) => ({
        createGuestComfortSuccess: state.property.createGuestComfortSuccess,
        createGuestComfortError: state.property.createGuestComfortError,
        getGuestComfortSuccess: state.property.getGuestComfortSuccess,
        updateGuestComfortSuccess: state.property.updateGuestComfortSuccess,
        updateGuestComfortError: state.property.updateGuestComfortError,
        getPropertySuccess: state.property.getPropertySuccess,
        getAmenityListSuccess: state.property.getAmenityListSuccess,
    }));

    //////////////////////////////// call apis using actions //////////////////////////////////////////////////////
    useEffect(() => {
        getAmenityList();
        getGuestComfort(propertyId);
        getProperty(propertyId);
    }, [getAmenityList, getGuestComfort, getProperty, propertyId]);



    const scrollToTop = () => {
        // messagesRef?.current?.scrollIntoView(({ behavior: "smooth", block: "start", inline: "start" }));
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    //////////////////////////////// get api response using state //////////////////////////////////////////////////////
    useEffect(() => {
        if (getAmenityListSuccess) {
            if (getAmenityListSuccess.data === null) {
                toast.warning("No Data found", {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    className: 'foo-bar'
                });
            }
            else {
                const amenity = getAmenityListSuccess.data;
                setAmenitiesList(amenity);
            }
        }

        if (getGuestComfortSuccess) {
            if (getGuestComfortSuccess.data) {
                const inventoryAmenity: Array<Object> = [];
                if (getGuestComfortSuccess.data?.amenities) {
                    const amenity = getGuestComfortSuccess.data?.amenities;
                    _.map(amenity, element =>
                        inventoryAmenity.push(element._id)
                    );
                    setAmenitiesShowList(amenity);
                    setAmenityId(inventoryAmenity);
                }
                setGuestComfortCreate(false);
                setGuestComfort(getGuestComfortSuccess.data._id);
                setGuestComfortData(getGuestComfortSuccess.data);
                // if (getGuestComfortSuccess.data.doYouProvideWelcomePack === CommonAnswer.YES) {
                //     setDoYouProvideWelcomePack(true);
                // } else {
                //     setDoYouProvideWelcomePack(false);
                // }

                // if (getGuestComfortSuccess.data.houseKeeping === CommonAnswer.NO) {
                //     setHouseKeeping(true);
                // } else {
                //     setHouseKeeping(false);
                // }

                // if (getGuestComfortSuccess.data.houseKeepingRateIncluded === CommonAnswer.NO) {
                //     setHouseKeepingRateIncluded(true);
                // } else {
                //     setHouseKeepingRateIncluded(false);
                // }
            } else {
                setGuestComfortCreate(true)
            }
        }

        if (createGuestComfortSuccess) {
            setLoading(false);
            toast.success("Guest comfort created successfully", {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'foo-bar'
            });
            setEditEnable(false);
            scrollToTop();
            getGuestComfort(propertyId);
        }

        if (createGuestComfortError) {
            setLoading(false);
            toast.error(createGuestComfortError.message, {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'foo-bar'
            });
        }

        if (updateGuestComfortSuccess) {
            setLoading(false);
            toast.success('Guest comfort update successfully', {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'foo-bar'
            });
            setEditEnable(false);
            scrollToTop();
            getGuestComfort(propertyId);
        }

        if (updateGuestComfortError) {
            setLoading(false);
            toast.error(updateGuestComfortError.message, {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'foo-bar'
            });
        }

        if (getPropertySuccess) {
            setPropertyData(getPropertySuccess.data);
        }
    }, [createGuestComfortError, createGuestComfortSuccess, getAmenityListSuccess, getGuestComfort, getGuestComfortSuccess, getPropertySuccess, propertyId, updateGuestComfortError, updateGuestComfortSuccess]);

    // //////////////////////////////////whatYouProvideForTheGuest validation manage ///////////////////////////
    // const onChangeDoYouProvideWelcomePack = useCallback((value) => {
    //     if (value === CommonAnswer.YES) {
    //         setDoYouProvideWelcomePack(true);
    //     } else {
    //         setDoYouProvideWelcomePack(false);
    //     }
    // }, []);

    // //////////////////////////////////costToTheHouseKeeping validation manage ///////////////////////////
    // const onChangeHouseKeeping = useCallback((value) => {
    //     if (value === CommonAnswer.NO) {
    //         setHouseKeeping(true);
    //     } else {
    //         setHouseKeeping(false);
    //     }
    // }, []);

    // //////////////////////////////////additionalCleanCost validation manage ///////////////////////////
    // const onChangeHouseKeepingRateIncluded = useCallback((value) => {
    //     if (value === CommonAnswer.NO) {
    //         setHouseKeepingRateIncluded(true);
    //     } else {
    //         setHouseKeepingRateIncluded(false);
    //     }
    // }, []);

    //////////////////////////////////////////////// Submit ///////////////////////////////////////////////////
    const onFinish = useCallback((values) => {
        if (!propertyId) {
            toast.error('Please create an property', {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'foo-bar'
            });
        }
        setLoading(true);
        const createUpdateValues = {
            propertyId: propertyId,
            localAndNationalDisabilityLaw: values.localAndNationalDisabilityLaw,
            areFacilitiesAndPublicAreaAccessible: values.areFacilitiesAndPublicAreaAccessible,
            carParkingBays: values.carParkingBays,
            haveAirConditioning: values.haveAirConditioning,
            complimentaryFans: values.complimentaryFans,
            doYouProvideWelcomePack: values.doYouProvideWelcomePack,
            whatYouProvideForTheGuest: values.whatYouProvideForTheGuest,
            groceryShopping: values.groceryShopping,
            washerDryer: values.washerDryer,
            freshBeanOrNespresso: values.freshBeanOrNespresso,
            houseKeeping: values.houseKeeping,
            costToTheHouseKeeping: values.costToTheHouseKeeping,
            houseKeepingRateIncluded: values.houseKeepingRateIncluded,
            additionalCleanCost: values.additionalCleanCost,
            provideCleaningMaterial: values.provideCleaningMaterial,
            cableOrSatelliteTv: values.cableOrSatelliteTv,
            bluetoothAudio: values.bluetoothAudio,
            wifiConnectedOrWiredSpeakers: values.wifiConnectedOrWiredSpeakers,
            freeStandingShower: values.freeStandingShower,
            haveABath: values.haveABath,
            changeMattresses: values.changeMattresses,
            doYouProvide: values.doYouProvide,
            renewBeddingMonths: values.renewBeddingMonths,
            renewPillowsMonths: values.renewPillowsMonths,
            detailsOfThePillows: values.detailsOfThePillows,
            convenienceFoodStore: values.convenienceFoodStore,
            nearestGym: values.nearestGym,
            gymShortTermMember: values.gymShortTermMember,
            gymPayAsYouGo: values.gymPayAsYouGo,
            amenities: values.amenities,
        }
        if (guestComfortId) {
            Object.assign(createUpdateValues, { guestComfortId });
            delete createUpdateValues.propertyId;
            updateGuestComfort(createUpdateValues)
        } else {
            createGuestComfort(createUpdateValues);
        }


    }, [createGuestComfort, guestComfortId, propertyId, updateGuestComfort]);

    const changeFormEditable = useCallback(() => {
        scrollToTop();
        if (editEnable) {
            setEditEnable(false);
        } else {
            setEditEnable(true);
        }
    }, [editEnable]);

    /////////////// amenities /////////////////
    const renderAmenities = useCallback(() => {
        if (amenitiesList) {
            const data = _.filter(amenitiesList, user => (user.type === AmenityType.all) || (user.type === AmenityType.guest_comfort));
            return data.map((at: any, key: number) => {
                return (
                    <Row className="mt-3" key={key}>
                        <Col md={2}>
                            <div style={{ textAlign: "right" }}>
                                <Image src={at.iconLocation} alt="" width={40} />
                            </div>
                        </Col>
                        <Col md={10} >
                            <div style={{ marginTop: "2%", marginLeft: '-9%' }}>
                                <Checkbox name='amenities' value={`${at._id}`}></Checkbox> <span style={{ marginLeft: '3%' }}>{at.name}</span>
                            </div>
                        </Col>
                    </Row>
                );
            });
        }
    }, [amenitiesList]);

    const renderShowAmenities = useCallback(() => {
        if (amenitiesShowList) {
            const data = _.filter(
                amenitiesShowList,
                (user) =>
                    user.type === AmenityType.all || user.type === AmenityType.guest_comfort
            );
            return data.map((at: any, key: number) => {
                return (
                    <>
                        <Row className="mt-3" key={key}>
                            <Col md={2}>
                                <div style={{ textAlign: "right" }}>
                                    <Image src={at.iconLocation} alt="" width={40} />
                                </div>
                            </Col>
                            <Col md={10}>
                                <div style={{ marginTop: "2%", marginLeft: '-9%' }}>
                                    <span style={{ marginLeft: '2%' }}>{at.name}</span>
                                </div>
                            </Col>
                        </Row>
                    </>
                );
            });
        }
    }, [amenitiesShowList]);


    const renderShowBedSheet = useCallback(() => {
        if (guestComfortData?.doYouProvide) {

            return guestComfortData?.doYouProvide.map((at: any, key: number) => {
                return (
                    <>
                        <Row className="mt-3" key={key}>
                            {/* <Col md={2}>

                            </Col> */}
                            <Col md={10}>
                                <div style={{ marginLeft: '-2%' }}>
                                    <span style={{ marginLeft: '2%' }}>{at}</span>
                                </div>
                            </Col>
                        </Row>
                    </>
                );
            });
        }
    }, [guestComfortData?.doYouProvide]);

    /////////////// bed sheet /////////////////
    const renderBedSheet = useCallback(() => {
        if (plainOptions) {

            return plainOptions.map((at: any, key: number) => {
                return (
                    <Row className="mt-3" key={key}>
                        <Col md={2}>

                        </Col>
                        <Col md={10} >
                            <div style={{ marginTop: "2%", marginLeft: '-21%' }}>
                                <Checkbox value={`${at.value}`}></Checkbox> <span style={{ marginLeft: '3%' }}>{at.label}</span>
                            </div>
                        </Col>
                    </Row>
                );
            });
        }
    }, []);
    return (
        <>
            <div className="content-title fixed_title">
                {
                    <BreadCrumbPage allInOnePropertyBreadCrumb={allInOnePropertyBreadCrumb} isPageName="Property" tab={tab} selectedBrand={selectedBrand} propertyData={propertyData} setFormOpen={setFormOpen} ></BreadCrumbPage>
                }
                <div className='edit_btn_wpr'>
                    <div className='d-flex align-items-center justify-content-end gx-2'>
                        {/* <HelpModal /> */}
                        {
                            !loading && !editEnable && propertyId && canBrandPropertyModuleWrite(selectedBrand.key ? selectedBrand.key : selectedBrand?._id, propertyId, UserAccess.property_access.details.code) && <Button id='edit-button' className='mb-0' style={formButton} onClick={changeFormEditable} >Edit</Button>
                        }
                    </div>
                </div>
            </div>
            <Container fluid className="p-0">
                <div className="jumbotron">
                    <Form onSubmit={handleSubmit(onFinish)}>
                        <Row>
                            <Col md={12}>
                                {/* <Row className='formSpace'>
                                    <Col md={6} style={{ textAlign: 'right' }}>
                                        <Form.Label>{editEnable && <span className='requiredMark'>*</span>} Does the property comply with all local and national disability law?</Form.Label>
                                    </Col>
                                    <Col md={6} style={{ textAlign: 'left' }}>
                                        {!editEnable && <p className="lead">{guestComfortData?.localAndNationalDisabilityLaw}</p>}
                                        {editEnable &&
                                            <Controller
                                                name="localAndNationalDisabilityLaw"
                                                defaultValue={guestComfortData?.localAndNationalDisabilityLaw}
                                                control={control}
                                                rules={{
                                                    required: true,
                                                }}
                                                render={({ field }) =>
                                                    <>
                                                        {
                                                            guestComfortData?.localAndNationalDisabilityLaw === 'Yes'
                                                                ?
                                                                <Form.Check
                                                                    inline
                                                                    value={'Yes'}
                                                                    name="localAndNationalDisabilityLaw"
                                                                    label={'Yes'}
                                                                    type="radio"
                                                                    defaultChecked
                                                                    onChange={(value) => { field.onChange(value.currentTarget.value) }}
                                                                />
                                                                :
                                                                <Form.Check
                                                                    inline
                                                                    value={'Yes'}
                                                                    name="localAndNationalDisabilityLaw"
                                                                    label={'Yes'}
                                                                    type="radio"
                                                                    onChange={(value) => { field.onChange(value.currentTarget.value) }}
                                                                />
                                                        }

                                                        {
                                                            guestComfortData?.localAndNationalDisabilityLaw === 'No'
                                                                ?
                                                                <Form.Check
                                                                    inline
                                                                    value={'No'}
                                                                    name="localAndNationalDisabilityLaw"
                                                                    label={'No'}
                                                                    type="radio"
                                                                    defaultChecked
                                                                    onChange={(value) => { field.onChange(value.currentTarget.value) }}
                                                                />
                                                                :
                                                                <Form.Check
                                                                    inline
                                                                    value={'No'}
                                                                    name="localAndNationalDisabilityLaw"
                                                                    label={'No'}
                                                                    type="radio"
                                                                    onChange={(value) => { field.onChange(value.currentTarget.value) }}
                                                                />
                                                        }

                                                    </>
                                                }
                                            />}
                                        {editEnable &&
                                            <ErrorMessage
                                                errors={errors}
                                                name="localAndNationalDisabilityLaw"
                                                render={({ message }) => <div className="invalid-feedback-custom">Please select your answer!</div>} />}
                                    </Col>
                                </Row> */}
                                <Row className='formSpace'>
                                    <Col md={6} style={{ textAlign: 'right' }}>
                                        <Form.Label>{editEnable && <span className='requiredMark'>*</span>} Are facilities and public areas accessible by people with physical disabilities?</Form.Label>
                                    </Col>
                                    <Col md={6} style={{ textAlign: 'left' }}>
                                        {!editEnable && <p className="lead">{guestComfortData?.areFacilitiesAndPublicAreaAccessible}</p>}
                                        {editEnable &&
                                            <Controller
                                                name="areFacilitiesAndPublicAreaAccessible"
                                                defaultValue={guestComfortData?.areFacilitiesAndPublicAreaAccessible}
                                                control={control}
                                                rules={{
                                                    required: true,
                                                }}
                                                render={({ field }) =>
                                                    <>
                                                        {
                                                            guestComfortData?.areFacilitiesAndPublicAreaAccessible === 'Yes'
                                                                ?
                                                                <Form.Check
                                                                    inline
                                                                    value={'Yes'}
                                                                    name="areFacilitiesAndPublicAreaAccessible"
                                                                    label={'Yes'}
                                                                    type="radio"
                                                                    defaultChecked
                                                                    onChange={(value) => { field.onChange(value.currentTarget.value) }}
                                                                />
                                                                :
                                                                <Form.Check
                                                                    inline
                                                                    value={'Yes'}
                                                                    name="areFacilitiesAndPublicAreaAccessible"
                                                                    label={'Yes'}
                                                                    type="radio"
                                                                    onChange={(value) => { field.onChange(value.currentTarget.value) }}
                                                                />
                                                        }

                                                        {
                                                            guestComfortData?.areFacilitiesAndPublicAreaAccessible === 'No'
                                                                ?
                                                                <Form.Check
                                                                    inline
                                                                    value={'No'}
                                                                    name="areFacilitiesAndPublicAreaAccessible"
                                                                    label={'No'}
                                                                    type="radio"
                                                                    defaultChecked
                                                                    onChange={(value) => { field.onChange(value.currentTarget.value) }}
                                                                />
                                                                :
                                                                <Form.Check
                                                                    inline
                                                                    value={'No'}
                                                                    name="areFacilitiesAndPublicAreaAccessible"
                                                                    label={'No'}
                                                                    type="radio"
                                                                    onChange={(value) => { field.onChange(value.currentTarget.value) }}
                                                                />
                                                        }

                                                    </>
                                                }
                                            />}
                                        {editEnable &&
                                            <ErrorMessage
                                                errors={errors}
                                                name="areFacilitiesAndPublicAreaAccessible"
                                                render={({ message }) => <div className="invalid-feedback-custom">Please select your answer!</div>} />}
                                    </Col>
                                </Row>
                                <Row className='formSpace'>
                                    <Col md={6} style={{ textAlign: 'right' }}>
                                        <Form.Label>{editEnable && <span className='requiredMark'>*</span>} Does the property provide disabled car parking bays?</Form.Label>
                                    </Col>
                                    <Col md={6} style={{ textAlign: 'left' }}>
                                        {!editEnable && <p className="lead">{guestComfortData?.carParkingBays}</p>}
                                        {editEnable &&
                                            <Controller
                                                name="carParkingBays"
                                                defaultValue={guestComfortData?.carParkingBays}
                                                control={control}
                                                rules={{
                                                    required: true,
                                                }}
                                                render={({ field }) =>
                                                    <>
                                                        {
                                                            guestComfortData?.carParkingBays === 'Yes'
                                                                ?
                                                                <Form.Check
                                                                    inline
                                                                    value={'Yes'}
                                                                    name="carParkingBays"
                                                                    label={'Yes'}
                                                                    type="radio"
                                                                    defaultChecked
                                                                    onChange={(value) => { field.onChange(value.currentTarget.value) }}
                                                                />
                                                                :
                                                                <Form.Check
                                                                    inline
                                                                    value={'Yes'}
                                                                    name="carParkingBays"
                                                                    label={'Yes'}
                                                                    type="radio"
                                                                    onChange={(value) => { field.onChange(value.currentTarget.value) }}
                                                                />
                                                        }

                                                        {
                                                            guestComfortData?.carParkingBays === 'No'
                                                                ?
                                                                <Form.Check
                                                                    inline
                                                                    value={'No'}
                                                                    name="carParkingBays"
                                                                    label={'No'}
                                                                    type="radio"
                                                                    defaultChecked
                                                                    onChange={(value) => { field.onChange(value.currentTarget.value) }}
                                                                />
                                                                :
                                                                <Form.Check
                                                                    inline
                                                                    value={'No'}
                                                                    name="carParkingBays"
                                                                    label={'No'}
                                                                    type="radio"
                                                                    onChange={(value) => { field.onChange(value.currentTarget.value) }}
                                                                />
                                                        }

                                                    </>
                                                }
                                            />}
                                        {editEnable &&
                                            <ErrorMessage
                                                errors={errors}
                                                name="carParkingBays"
                                                render={({ message }) => <div className="invalid-feedback-custom">Please select your answer!</div>} />}
                                    </Col>
                                </Row>

                                <Row className='formSpace'>
                                    <Col md={6} style={{ textAlign: 'right' }}>
                                        <Form.Label>{editEnable && <span className='requiredMark'>*</span>} How often do you renew the mattresses (Years)?</Form.Label>
                                    </Col>
                                    <Col md={6} style={{ textAlign: 'left' }}>
                                        {!editEnable && <p className="lead">{guestComfortData?.changeMattresses}</p>}
                                        {editEnable && <Form.Control type='number' min={0} className={`${errors.changeMattresses ? 'is-invalid' : ''}`} defaultValue={guestComfortData?.changeMattresses} {...register("changeMattresses", { required: true })} />}
                                        {editEnable && <div className="invalid-feedback">Please input your answer!</div>}
                                    </Col>
                                </Row>

                                <Row className="formSpace">
                                    <Col md={6} style={{ textAlign: "right" }}>
                                        <Form.Label className="mt-3">
                                            {editEnable && <span className='requiredMark'>*</span>}What bedding do you provide?
                                        </Form.Label>
                                    </Col>
                                    <Col md={6} style={{ textAlign: "left" }}>
                                        {!editEnable && renderShowBedSheet()}
                                        {editEnable && (
                                            <Controller
                                                name="doYouProvide"
                                                defaultValue={guestComfortData?.doYouProvide}
                                                control={control}
                                                // rules={{
                                                //     required: true,
                                                // }}
                                                render={({ field }) => (
                                                    <>
                                                        <Checkbox.Group defaultValue={guestComfortData?.doYouProvide} style={{ width: '100%' }} onChange={(value) => { field.onChange(value); }}>
                                                            {renderBedSheet()}
                                                        </Checkbox.Group>
                                                    </>
                                                )}
                                            />
                                        )}
                                        {editEnable && errors.doYouProvide && (
                                            <div className="invalid-feedback-custom">
                                                Please select a options!
                                            </div>
                                        )}
                                    </Col>
                                </Row>

                                <Row className='formSpace'>
                                    <Col md={6} style={{ textAlign: 'right' }}>
                                        <Form.Label>{editEnable && <span className='requiredMark'>*</span>} How often do you renew the bedding (Months)?</Form.Label>
                                    </Col>
                                    <Col md={6} style={{ textAlign: 'left' }}>
                                        {!editEnable && <p className="lead">{guestComfortData?.renewBeddingMonths}</p>}
                                        {editEnable && <Form.Control type='number' min={0} className={`${errors.renewBeddingMonths ? 'is-invalid' : ''}`} defaultValue={guestComfortData?.renewBeddingMonths} {...register("renewBeddingMonths", { required: true })} />}
                                        {editEnable && <div className="invalid-feedback">Please input your answer!</div>}
                                    </Col>
                                </Row>

                                <Row className='formSpace'>
                                    <Col md={6} style={{ textAlign: 'right' }}>
                                        <Form.Label>{editEnable && <span className='requiredMark'>*</span>} How often do you renew your pillows (Months)?</Form.Label>
                                    </Col>
                                    <Col md={6} style={{ textAlign: 'left' }}>
                                        {!editEnable && <p className="lead">{guestComfortData?.renewPillowsMonths}</p>}
                                        {editEnable && <Form.Control type='number' min={0} className={`${errors.renewPillowsMonths ? 'is-invalid' : ''}`} defaultValue={guestComfortData?.renewPillowsMonths} {...register("renewPillowsMonths", { required: true })} />}
                                        {editEnable && <div className="invalid-feedback">Please input your answer!</div>}
                                    </Col>
                                </Row>

                                <Row className='formSpace'>
                                    <Col md={6} style={{ textAlign: 'right' }}>
                                        <Form.Label>{editEnable && <span className='requiredMark'>*</span>} Please provide details of the pillows you provide</Form.Label>
                                    </Col>
                                    <Col md={6} style={{ textAlign: 'left' }}>
                                        {!editEnable && <p className="lead">{
                                            guestComfortData?.detailsOfThePillows.map((value) => {
                                                return (
                                                    <span key={value}>{value}, </span>
                                                );
                                            })
                                        }</p>}
                                        {editEnable &&
                                            <Controller
                                                name="detailsOfThePillows"
                                                defaultValue={guestComfortData?.detailsOfThePillows}
                                                control={control}
                                                rules={{
                                                    required: true,
                                                }}
                                                render={({ field }) =>
                                                    <>
                                                        {guestComfortData?.detailsOfThePillows
                                                            ?
                                                            <Select defaultValue={Object(guestComfortData?.detailsOfThePillows)} onChange={(value) => {
                                                                field.onChange(value);
                                                            }} mode="multiple" style={{ width: '100%' }}>
                                                                <Option key={`FEATHER`} value={`Feather`} >Feather</Option>
                                                                <Option key={`DOWN`} value={`Down`} >Down</Option>
                                                                <Option key={`MEMORY_FOAM`} value={`Memory foam`} >Memory foam</Option>
                                                                <Option key={`KAPOK`} value={`Kapok`} >Kapok</Option>
                                                                <Option key={`POLYFILL`} value={`Polyfill`} >Polyfill</Option>
                                                                <Option key={`MICRO_BEAD`} value={`Micro-bead`} >Micro-bead</Option>
                                                                <Option key={`BUCKWHEAT`} value={`Buckwheat`} >Buckwheat</Option>
                                                                <Option key={`Hypoallergenic`} value={`Hypoallergenic`} >Hypoallergenic</Option>
                                                            </Select>
                                                            :
                                                            <Select onChange={(value) => {
                                                                field.onChange(value);
                                                            }} mode="multiple" style={{ width: '100%' }}>
                                                                <Option key={`FEATHER`} value={`Feather`} >Feather</Option>
                                                                <Option key={`DOWN`} value={`Down`} >Down</Option>
                                                                <Option key={`MEMORY_FOAM`} value={`Memory foam`} >Memory foam</Option>
                                                                <Option key={`KAPOK`} value={`Kapok`} >Kapok</Option>
                                                                <Option key={`POLYFILL`} value={`Polyfill`} >Polyfill</Option>
                                                                <Option key={`MICRO_BEAD`} value={`Micro-bead`} >Micro-bead</Option>
                                                                <Option key={`Hypoallergenic`} value={`Hypoallergenic`} >Hypoallergenic</Option>
                                                            </Select>
                                                        }

                                                    </>
                                                }
                                            />}
                                        {editEnable &&
                                            <ErrorMessage
                                                errors={errors}
                                                name="haveABath"
                                                render={({ message }) => <div className="invalid-feedback-custom">Please select your answer!</div>} />}
                                    </Col>
                                </Row>

                                <Row className='formSpace'>
                                    <Col md={6} style={{ textAlign: 'right' }}>
                                        <Form.Label>{editEnable && <span className='requiredMark'>*</span>} How long would it take to walk to nearest convenience food store (mins)?</Form.Label>
                                    </Col>
                                    <Col md={6} style={{ textAlign: 'left' }}>
                                        {!editEnable && <p className="lead">{guestComfortData?.convenienceFoodStore}</p>}
                                        {editEnable && <Form.Control type='number' min={0} className={`${errors.convenienceFoodStore ? 'is-invalid' : ''}`} defaultValue={guestComfortData?.convenienceFoodStore} {...register("convenienceFoodStore", { required: true })} />}
                                        {editEnable && <div className="invalid-feedback">Please input your answer!</div>}
                                    </Col>
                                </Row>
                                <Row className='formSpace'>
                                    <Col md={6} style={{ textAlign: 'right' }}>
                                        <Form.Label>{editEnable && <span className='requiredMark'>*</span>} How far is the nearest external gymnasium (mins)?</Form.Label>
                                    </Col>
                                    <Col md={6} style={{ textAlign: 'left' }}>
                                        {!editEnable && <p className="lead">{guestComfortData?.nearestGym}</p>}
                                        {editEnable && <Form.Control type='number' min={0} className={`${errors.nearestGym ? 'is-invalid' : ''}`} defaultValue={guestComfortData?.nearestGym} {...register("nearestGym", { required: true })} />}
                                        {editEnable && <div className="invalid-feedback">Please input your answer!</div>}
                                    </Col>
                                </Row>
                                <Row className='formSpace'>
                                    <Col md={6} style={{ textAlign: 'right' }}>
                                        <Form.Label>{editEnable && <span className='requiredMark'>*</span>} Does this gymnasium offer short term membership?</Form.Label>
                                    </Col>
                                    <Col md={6} style={{ textAlign: 'left' }}>
                                        {!editEnable && <p className="lead">{guestComfortData?.gymShortTermMember}</p>}
                                        {editEnable &&
                                            <Controller
                                                name="gymShortTermMember"
                                                defaultValue={guestComfortData?.gymShortTermMember}
                                                control={control}
                                                rules={{
                                                    required: true,
                                                }}
                                                render={({ field }) =>
                                                    <>
                                                        {
                                                            guestComfortData?.gymShortTermMember === 'Yes'
                                                                ?
                                                                <Form.Check
                                                                    inline
                                                                    value={'Yes'}
                                                                    name="gymShortTermMember"
                                                                    label={'Yes'}
                                                                    type="radio"
                                                                    defaultChecked
                                                                    onChange={(value) => { field.onChange(value.currentTarget.value); }}
                                                                />
                                                                :
                                                                <Form.Check
                                                                    inline
                                                                    value={'Yes'}
                                                                    name="gymShortTermMember"
                                                                    label={'Yes'}
                                                                    type="radio"
                                                                    onChange={(value) => { field.onChange(value.currentTarget.value) }}
                                                                />
                                                        }

                                                        {
                                                            guestComfortData?.gymShortTermMember === 'No'
                                                                ?
                                                                <Form.Check
                                                                    inline
                                                                    value={'No'}
                                                                    name="gymShortTermMember"
                                                                    label={'No'}
                                                                    type="radio"
                                                                    defaultChecked
                                                                    onChange={(value) => { field.onChange(value.currentTarget.value); }}
                                                                />
                                                                :
                                                                <Form.Check
                                                                    inline
                                                                    value={'No'}
                                                                    name="gymShortTermMember"
                                                                    label={'No'}
                                                                    type="radio"
                                                                    onChange={(value) => { field.onChange(value.currentTarget.value); }}
                                                                />
                                                        }

                                                    </>
                                                }
                                            />}
                                        {editEnable &&
                                            <ErrorMessage
                                                errors={errors}
                                                name="gymShortTermMember"
                                                render={({ message }) => <div className="invalid-feedback-custom">Please select your answer!</div>} />}
                                    </Col>
                                </Row>
                                <Row className='formSpace'>
                                    <Col md={6} style={{ textAlign: 'right' }}>
                                        <Form.Label>{editEnable && <span className='requiredMark'>*</span>} Does this gymnasium offer pay-as-you-go?</Form.Label>
                                    </Col>
                                    <Col md={6} style={{ textAlign: 'left' }}>
                                        {!editEnable && <p className="lead">{guestComfortData?.gymPayAsYouGo}</p>}
                                        {editEnable &&
                                            <Controller
                                                name="gymPayAsYouGo"
                                                defaultValue={guestComfortData?.gymPayAsYouGo}
                                                control={control}
                                                rules={{
                                                    required: true,
                                                }}
                                                render={({ field }) =>
                                                    <>
                                                        {
                                                            guestComfortData?.gymPayAsYouGo === 'Yes'
                                                                ?
                                                                <Form.Check
                                                                    inline
                                                                    value={'Yes'}
                                                                    name="gymPayAsYouGo"
                                                                    label={'Yes'}
                                                                    type="radio"
                                                                    defaultChecked
                                                                    onChange={(value) => { field.onChange(value.currentTarget.value); }}
                                                                />
                                                                :
                                                                <Form.Check
                                                                    inline
                                                                    value={'Yes'}
                                                                    name="gymPayAsYouGo"
                                                                    label={'Yes'}
                                                                    type="radio"
                                                                    onChange={(value) => { field.onChange(value.currentTarget.value) }}
                                                                />
                                                        }

                                                        {
                                                            guestComfortData?.gymPayAsYouGo === 'No'
                                                                ?
                                                                <Form.Check
                                                                    inline
                                                                    value={'No'}
                                                                    name="gymPayAsYouGo"
                                                                    label={'No'}
                                                                    type="radio"
                                                                    defaultChecked
                                                                    onChange={(value) => { field.onChange(value.currentTarget.value); }}
                                                                />
                                                                :
                                                                <Form.Check
                                                                    inline
                                                                    value={'No'}
                                                                    name="gymPayAsYouGo"
                                                                    label={'No'}
                                                                    type="radio"
                                                                    onChange={(value) => { field.onChange(value.currentTarget.value); }}
                                                                />
                                                        }

                                                    </>
                                                }
                                            />}
                                        {editEnable &&
                                            <ErrorMessage
                                                errors={errors}
                                                name="gymPayAsYouGo"
                                                render={({ message }) => <div className="invalid-feedback-custom">Please select your answer!</div>} />}
                                    </Col>
                                </Row>
                                <Row className="formSpace">
                                    <Col md={6} style={{ textAlign: "right" }}>
                                        <Form.Label className="mt-3">
                                            {editEnable && <>
                                                <span>Please indicate what amenities you offer within the building</span></>}
                                            {!editEnable && <span > </span>}
                                        </Form.Label>
                                    </Col>
                                    <Col md={6} style={{ textAlign: "left" }}>
                                        {!editEnable && renderShowAmenities()}
                                        {editEnable && (
                                            <Controller
                                                name="amenities"
                                                defaultValue={amenityId}
                                                control={control}
                                                // rules={{
                                                //     required: true,
                                                // }}
                                                render={({ field }) => (
                                                    <>
                                                        <Checkbox.Group defaultValue={amenityId} style={{ width: '100%' }} onChange={(value) => { field.onChange(value); }}>
                                                            {renderAmenities()}
                                                        </Checkbox.Group>
                                                    </>
                                                )}
                                            />
                                        )}
                                        {editEnable && errors.amenities && (
                                            <div className="invalid-feedback-custom">
                                                Please select a amenities!
                                            </div>
                                        )}
                                    </Col>
                                </Row>
                                <Row className='formSpace'>
                                    <Col md={6} style={{ textAlign: 'right' }}>
                                    </Col>
                                    <Col md={6} style={{ textAlign: 'left' }}>
                                        {!loading && editEnable && propertyId && canBrandPropertyModuleWrite(selectedBrand.key ? selectedBrand.key : selectedBrand?._id, propertyId, UserAccess.property_access.details.code) &&
                                            <Button type="submit" id='approve-button' style={formButton}> {guestComfortCreate ? 'Update' : 'Update'} </Button>
                                        }

                                        {
                                            loading && <div style={{ textAlign: 'center' }}><Spinner animation="border" variant="warning" /></div>
                                        }
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Form>
                </div>
            </Container>
        </>
    );
};

export default GuestComfortDetailForm;