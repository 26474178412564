import { Breadcrumb } from 'react-bootstrap';
import { FC, useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useStoreActions, useStoreState } from "easy-peasy";
import { getStorage } from "../../../common/storage";
import moment from 'moment';
import { useHistory } from 'react-router-dom';


import ContractPropertyDataFrom from './form/ContractPropertyDataFrom';

const ContractedPropertyDataHome: FC<any> = ({ location }): JSX.Element => {

	const [selectedClientName, setSelectClientName] = useState<String>("");
	const [programmeName, setProgrammeName] = useState<String>("");
	const [formOpen, setFormOpen] = useState<boolean>(false);
	const [reportId, setReportId] = useState<any>(null);
	const [selectedProgramme, setSelectedProgramme] = useState<any>(null);
	const [startDate, setStartDate] = useState<any>(null);
	const [endDate, setEndDate] = useState<any>(null);
	const [refreshReportList, setRefreshReportList] = useState<boolean>(false);
	const [programmeId, setProgrammeId] = useState<any>(null);
	const [selectedClient, setSelectedClient] = useState<any>(null);
	const [accommodationType, setAccommodationType] = useState<any>(null);
	const [reportName, setReportName] = useState<any>(null);
	const [associateProgrammes, setAssociateProgrammes] = useState<any>(null);
	const [currency, setCurrency] = useState<any>(null);
	const [tabKey, setTabKey] = useState<any>('contractPropertyReport');
	const history = useHistory<any>();

	useEffect(() => {
		if (history?.location?.state) {
			setTabKey(history?.location?.state?.tabKey);
			setReportName(history?.location?.state?.reportName);
			setCurrency(history?.location?.state?.currency);
			setAssociateProgrammes(history?.location?.state?.associateProgrammes);
		}
	}, [history, location?.state]);

	//actions
	const {
		getCorporateProgrammeById,
		getReportByProgrammeId,
	} = useStoreActions<any>((actions) => ({
		getReportList: actions.booking.getReportList,
		getCorporateProgrammeById: actions.client.getCorporateProgrammeById,
		getReportByProgrammeId: actions.booking.getReportByProgrammeId,
	}));

	//state
	const {
		getCorporateProgrammeByIdError,
		getCorporateProgrammeByIdSuccess,
	} = useStoreState<any>((state) => ({

		getCorporateProgrammeByIdSuccess: state.client.getCorporateProgrammeByIdSuccess,
		getCorporateProgrammeByIdError: state.client.getCorporateProgrammeByIdError,

	}));
	///////////////////////////////////////////////////////////////////

	// set the selected client to the state //////////////////////////////////////////////////////
	useEffect(() => {
		if (location) {
			setSelectedClient(location?.state?.selectedClient);
			setProgrammeId(location?.state?.programmeId);
			setProgrammeName(location?.state?.selectedProgramme?.programmeName);
			setSelectedProgramme(location?.state?.selectedProgramme);
			setCurrency(location?.state?.currency);
		}
	}, [location]);

	/////////////////////////////////////////////////////////////////////////////////////////////

	useEffect(() => {
		if (location?.state) {
			setFormOpen(location?.state?.formOpen);
			setReportId(location?.state?.reportId);
			setStartDate(moment(location?.state?.startDate).format('DD MMM YYYY'));
			setEndDate(moment(location?.state?.endDate).format('DD MMM YYYY'));
			setAccommodationType(location?.state?.accommodationType);
			setAssociateProgrammes(location?.state?.associateProgrammes);
		}
	}, [location?.state]);


	// to get the selectedProgramme from the localStorage //////////////////////////////////////////////////////
	useEffect(() => {
		const programme = getStorage('myo_selected_programme');
		if (programme) {

			setSelectedProgramme(programme);
		}

	}, []);

	// call getCorporateProgrammeById //////////////////////////////////////////////////////
	useEffect(() => {
		if (programmeId) {
			getCorporateProgrammeById(programmeId);
		}
	}, [getCorporateProgrammeById, programmeId]);

	// set state for programme data //////////////////////////////////////////////////////
	useEffect(() => {
		if (getCorporateProgrammeByIdError) {
			setProgrammeName(getCorporateProgrammeByIdError?.programmeName)
		}
		if (getCorporateProgrammeByIdSuccess?.data) {
			const { programmeName } = getCorporateProgrammeByIdSuccess.data;
			setProgrammeName(programmeName);
		}
	}, [getCorporateProgrammeByIdSuccess, getCorporateProgrammeByIdError])

	// to fetch  data for the report list //////////////////////////////////////////////////////
	useEffect(() => {
		if (programmeId) {

			const payload = {
				programmeId: programmeId,
				clientId: selectedClient?.key ? selectedClient?.key : selectedClient?._id,
			};
			getReportByProgrammeId(payload);
		}
	}, [getReportByProgrammeId, programmeId, selectedClient?._id, selectedClient?.key]
	);

	////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	// refresh 
	useEffect(() => {
		if (programmeId && refreshReportList) {
			const payload = {
				programmeId: programmeId,
				clientId: selectedClient?.key ? selectedClient?.key : selectedClient?._id,
			};
			getReportByProgrammeId(payload);
			setRefreshReportList(false);
		}
	}, [getReportByProgrammeId, programmeId, refreshReportList, selectedClient?._id, selectedClient?.key]
	);

	// set the selected client to the state //////////////////////////////////////////////////////
	useEffect(() => {
		if (location?.state?.selectedClient?.companyName) {
			setSelectClientName(location?.state?.selectedClient.companyName)
		}
	}, [location]);

	///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	const loadForm = useCallback((data) => {
		setFormOpen(true);
		if (data !== null) {
			setReportId(data.key);
			setStartDate(moment(data.startDate).format('DD MMM YYYY'));
			setEndDate(moment(data.endDate).format('DD MMM YYYY'));
			setAccommodationType(data.accommodationType);
			setReportName(data.reportName);
			setCurrency(data.currency);
			setAssociateProgrammes(data.associateProgrammes);
		} else {
			setReportId(null);
		}
	}, []);

	// useEffect(() => {
	// 	document.querySelectorAll('.customSpacing').forEach(element => {
	// 	  (element as HTMLElement).style.marginTop = ''; 
	// 	});
	  
	// 	document.querySelectorAll('.customAddSpacing').forEach(element => {
	// 	  (element as HTMLElement).style.marginTop = '80px';
	// 	});
	//   }, []);  

	//////////////////////////////////////////////////////////////////////////////////////////////////////////////
	return (
		<div className='rfp-process-home location_reports customAddSpacing' style={{ width: "calc(100% + 25px)" }}>
			<div className="flex justify-between">
				{!location?.state?.selectedClient && <p className="ext-2xl mb-10"></p>}
				{location?.state?.selectedClient && !reportId && <Breadcrumb className='mb-10' >
					<Breadcrumb.Item>
						<Link to={{ pathname: "/admin/client-management", state: { selectedClient } }} >{selectedClientName}</Link>
					</Breadcrumb.Item>
					<Breadcrumb.Item>
						<Link to={{
							pathname: '/admin/programme-management',
							state: {
								selectedProgramme, selectedClient
							}
						}} >
							{programmeName}
						</Link>
					</Breadcrumb.Item>

					{formOpen && reportId && <Breadcrumb.Item active>
						{startDate}
					</Breadcrumb.Item>}
					{formOpen && !reportId && <Breadcrumb.Item active>
						New Report
					</Breadcrumb.Item>}
				</Breadcrumb>}
			</div>
			<ContractPropertyDataFrom programmeId={programmeId} reportId={reportId} loadForm={loadForm} selectedProgramme={selectedProgramme} selectedClient={selectedClient} startDate={startDate} endDate={endDate} location={location} selectedClientName={selectedClientName} accommodationType={accommodationType} reportName={reportName} associateProgrammes={associateProgrammes} currency={currency} />
		</div>
	)

}

export default ContractedPropertyDataHome;
