import { FC, useState, useEffect } from "react";
import { UserAccess } from "../../../../common/constants";
import { isCorporateProgrammeModuleAvailable } from "../../../../common/functions";
import AccessDenied from "../../../common/AccessDenied";
import { Tab, Row, Col, Nav } from 'react-bootstrap';
import { navStyle } from "../../../../common/components-style";
import ContractedPropertyLocationData from "./ContractedPropertyLocationData";
import ContractedPropertyPerformanceByHotelData from "./ContractedPropertyPerformanceByHotelData";
import NonContractedPropertyData from "./NonContractedPropertyData";

const ContractedPropertyDataFrom: FC<any> = ({ reportId, programmeId, setFormOpen, reload, selectedProgramme, selectedClient, startDate, endDate, location, selectedClientName, accommodationType, reportName, associateProgrammes, currency }): JSX.Element => {
    const [tabKey, setTabKey] = useState<any>(location.state?.tabKey ? location.state?.tabKey : 'contractedPropertyPerformanceByLocation');

    useEffect(() => {
        if (location?.state?.defaultKey) {
            setTabKey(location?.state?.defaultKey);
        }
    }, [location?.state?.defaultKey]);


    return (
        <div style={{ marginLeft: '-40px', marginTop: '-38px' }} >
            {reportId ? <Tab.Container defaultActiveKey="contractedPropertyPerformanceByLocation" key={tabKey} activeKey={tabKey} onSelect={(tab) => setTabKey(tab)}>
                <Row >
                    <Col sm={2}>
                        <Nav variant="pills" className="flex-column secondry-menu" style={navStyle}>
                            <div style={{ marginTop: '0%' }}>
                                {reportId && <Nav.Item>
                                    <Nav.Link eventKey="contractedPropertyPerformanceByLocation">Performance by location</Nav.Link>
                                </Nav.Item>}
                                {reportId && <Nav.Item>
                                    <Nav.Link eventKey="contractedPropertyPerformanceByHotel">Performance by hotel</Nav.Link>
                                </Nav.Item>}
                                {reportId && <Nav.Item>
                                    <Nav.Link eventKey="nonContractedStatistics">Non-Contracted Statistics</Nav.Link>
                                </Nav.Item>}
                            </div>
                        </Nav>
                    </Col>
                    <Col sm={10}>
                        <Tab.Content>
                            <Tab.Pane eventKey="contractedPropertyPerformanceByLocation">
                                <div>
                                    {isCorporateProgrammeModuleAvailable(selectedProgramme?.clientId, selectedProgramme?.key, UserAccess.programme_access.programmeAdmin.code) ? <ContractedPropertyLocationData programmeId={programmeId} reportId={reportId} setFormOpen={setFormOpen} reload={reload} selectedClient={selectedClient} selectedProgramme={selectedProgramme} accommodationType={accommodationType} reportName={reportName} currency={currency} associateProgrammes={associateProgrammes} /> : <AccessDenied />}
                                </div>
                            </Tab.Pane>
                            <Tab.Pane eventKey="contractedPropertyPerformanceByHotel">
                                <div>
                                    {isCorporateProgrammeModuleAvailable(selectedProgramme?.clientId, selectedProgramme?.key, UserAccess.programme_access.programmeAdmin.code) ? <ContractedPropertyPerformanceByHotelData programmeId={programmeId} reportId={reportId} setFormOpen={setFormOpen} reload={reload} selectedClient={selectedClient} selectedProgramme={selectedProgramme} accommodationType={accommodationType} reportName={reportName} currency={currency} associateProgrammes={associateProgrammes} /> : <AccessDenied />}
                                </div>
                            </Tab.Pane>
                            <Tab.Pane eventKey="nonContractedStatistics">
                                <div>
                                    {isCorporateProgrammeModuleAvailable(selectedProgramme?.clientId, selectedProgramme?.key, UserAccess.programme_access.programmeAdmin.code) ? <NonContractedPropertyData programmeId={programmeId} reportId={reportId} setFormOpen={setFormOpen} reload={reload} selectedClient={selectedClient} selectedProgramme={selectedProgramme} accommodationType={accommodationType} reportName={reportName} currency={currency} associateProgrammes={associateProgrammes} /> : <AccessDenied />}
                                </div>
                            </Tab.Pane>
                        </Tab.Content>
                    </Col>
                </Row>
            </Tab.Container> : null
            }
        </div>
    )
}


export default ContractedPropertyDataFrom;
