import { PlusOutlined } from "@ant-design/icons";
import { Upload } from "antd";
import ImgCrop from "antd-img-crop";
import React, { useCallback, useState } from "react";
import { toast } from 'react-toastify';
import ViewEdit from '../../images/icons/svg/outside/Icon-Function-View-Edit.svg';
import Attachment from '../../images/icons/Attachment.png';

type TImageType = "image" | "pdf" | "file";

interface IProps {
  getImageBlob: any;
  type: TImageType;
  value: any;
  disabled: any;
  crop?: boolean;
  visible?: boolean;
  newUpload?: boolean;
  accept?: any;
  id?:any;
}

const pdfUrl =
  "https://res.cloudinary.com/mysa/image/upload/v1613371019/common/pdf_gbsuky.jpg";

const HotelImageUpload: React.FC<IProps> = ({
  getImageBlob,
  type,
  value,
  disabled,
  crop = true,
  visible = true,
  newUpload = false,
  id = ""
}): JSX.Element => {
  const [imageUrl, setImageUrl] = useState();

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  const getBase64 = useCallback((img, callback) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  }, []);

  const beforeUpload = useCallback(
    (file) => {
      if (type === "pdf") {
        const isPDF = file.type === "application/pdf";
        if (!isPDF) {
          toast.error(`please upload a PDF document`, {
            position: toast.POSITION.BOTTOM_RIGHT,
            className: 'foo-bar'
          });
        } else {
          getBase64(file, (imageUrl: React.SetStateAction<undefined>) => {
            setImageUrl(imageUrl);
            getImageBlob(imageUrl);
          });
          return false;
        }
      } else {
        getBase64(file, (imageUrl: React.SetStateAction<undefined>) => {
          setImageUrl(imageUrl);
          getImageBlob(imageUrl);
        });
        return false;
      }
    },
    [getBase64, getImageBlob, type]
  );

  return (
    <div className="image-upload">
      {type === "image" && (
        <>
          {crop ? (
            <ImgCrop aspect={5 / 5} rotate>
              <Upload
                id={id}
                name="avatar"
                listType="picture-card"
                className="image-uploader"
                showUploadList={false}
                beforeUpload={beforeUpload}
                disabled={disabled}
              >
                {visible && (value || imageUrl) && (
                  <img
                    src={imageUrl || value}
                    alt="avatar"
                    style={{ width: "100%" }}
                  />
                )}
                {!value && !imageUrl && uploadButton}
              </Upload>
            </ImgCrop>
          ) : (
            <Upload
              id={id}
              name="avatar"
              listType="picture-card"
              className="image-uploader"
              showUploadList={false}
              beforeUpload={beforeUpload}
              disabled={disabled}
            >
              {visible && (value || imageUrl) && (
                <img
                  src={imageUrl || value}
                  alt="avatar"
                  style={{ width: "100%" }}
                />
              )}
              {!value && !imageUrl && uploadButton}
            </Upload>
          )}
        </>
      )}
      {type === "pdf" && (
        <div>
          <Upload
            disabled={disabled}
            name="avatar"
            listType="picture-card"
            className="image-uploader"
            showUploadList={false}
            beforeUpload={beforeUpload}
          >
            {(value || imageUrl) && (
              <img src={pdfUrl} alt="avatar" style={{ width: "100%" }} />
            )}
            {!value && !imageUrl && uploadButton}
          </Upload>
          {value && !newUpload && (
            <a target="_new" className="flex items-center" href={value}>
              {" "}
              <span className="ml-5 text-blue-400 mr-1" style={{ fontWeight: 'bold', color: "#000000" }}>Preview</span>{" "}
              <img className='iconSizes' src={ViewEdit} alt="" />
            </a>
          )}
        </div>
      )}
      {type === "file" && (
        <div>
          <Upload
            disabled={disabled}
            name="avatar"
            listType="picture-card"
            className="image-uploader"
            showUploadList={false}
            beforeUpload={beforeUpload}
          >
            {(value || imageUrl) && (
              <img src={Attachment} alt="avatar" style={{ width: "100%" }} />
            )}
            {!value && !imageUrl && uploadButton}
          </Upload>
          {value && !newUpload && (
            <a target="_new" className="flex items-center" href={value}>
              {" "}
              <span className="ml-5 text-blue-400 mr-1" style={{ fontWeight: 'bold', color: "#000000" }}>Preview</span>{" "}
              <img className='iconSizes' src={ViewEdit} alt="" />
            </a>
          )}
        </div>
      )}
    </div>
  );
};

export default HotelImageUpload;
