import React, { useEffect, useState, useCallback } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import DataTable from "react-data-table-component";
import { useStoreState } from "easy-peasy";
import _ from "lodash";
import plus from "../../../images/dashboard/plus.png";
import minus from "../../../images/dashboard/minus.png";
import copy from "../../../images/dashboard/copy.svg";
import cancel from "../../../images/dashboard/cancel.svg";
import arrow from "../../../images/dashboard/right-arrow.svg";
import { Button } from 'react-bootstrap';
import {
  tableStyles
} from "../../../common/components-style";
import ViewEdit from "../../../images/icons/svg/outside/Icon-Function-View-Edit.svg";
interface InventoryItem {
  accommodationDetail?: string;
  status?: string;
  inventoryImages?: any[];
  inventoryAmenities?: any[];
  accommodationConfiguration?: string;
  description?: string;
  moderate?: any;
  bedType?: any;
}
interface RowData {
  inventories?: InventoryItem[];
  propertyStatus: string;
  transportData: any;
  guestComfortsData: any;
  propertySustainabilitiesData: any;
  propertyFinancesData: any;
  propertyImagesData: any;
  propertyAmenitiesData: any;
  propertyCheckInProcessesData: any;
  propertyName: any;
  country: any;
  city: any;
  propertyType: any;
  description: any;
  systemNotificationEmail: any;
  contactEmail: 1;
  termPdf: 1;
  source: string;
  hotelName: any;
  hotelStatus: string;
  hotelInvoiceData: string;
}

const IncompletePropertiesList: React.FC<any> = (): JSX.Element => {
  const history = useHistory();
  const [incompletePropertiesData, setIncompletePropertiesData] = useState<any>([]);
  const [visibleData, setVisibleData] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(7);
  const [isExpanded, setIsExpanded] = useState(false);
  const [loadModel, setLoadModel] = useState<boolean>(false);
  const [loadPropertyModel, setLoadPropertyModel] = useState<boolean>(false);
  const [loadInventoryModel, setLoadInventoryModel] = useState<boolean>(false);
  const [selectedRowData, setSelectedRowData] = useState<RowData | null>(null);
  const [selectedInventory, setSelectedInventory] = useState<InventoryItem | null>(null);
  const { superAdminDashboardSuccessOnboarding, superAdminDashboardErrorOnboarding } = useStoreState<any>(
    (state) => {
      return {
        superAdminDashboardSuccessOnboarding: state.DashboardModel.superAdminDashboardSuccessOnboarding,
        superAdminDashboardErrorOnboarding: state.DashboardModel.superAdminDashboardErrorOnboarding,
      }
    }
  );
  useEffect(() => {
    if (superAdminDashboardErrorOnboarding) {
      toast.error(superAdminDashboardErrorOnboarding.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });
    }
    if (superAdminDashboardSuccessOnboarding) {
      setIncompletePropertiesData(superAdminDashboardSuccessOnboarding?.data?.getAllPropertiesCount?.incompleteData);
    }
  }, [superAdminDashboardErrorOnboarding, superAdminDashboardSuccessOnboarding]);

  useEffect(() => {
    setVisibleData(incompletePropertiesData?.slice(0, 7) || []);
  }, [incompletePropertiesData]);

  const loadMoreData = () => {
    const newIndex = currentIndex + 7;
    setVisibleData(incompletePropertiesData?.slice(0, newIndex) || []);
    setCurrentIndex(newIndex);
  };

  const incompletePropertiesHandler = () => {
    if (isExpanded) {
      setVisibleData(incompletePropertiesData?.slice(0, 7) || []);
      setCurrentIndex(7);
    } else {
      setVisibleData(incompletePropertiesData);
      setCurrentIndex(incompletePropertiesData?.length);
    }
    setIsExpanded(!isExpanded);
  };
  const handleRowClick = (row) => {
    setSelectedRowData(row);
    setLoadModel(true);
  };

  const handleInventoryClick = (item) => {
    setSelectedInventory(item);
    setLoadInventoryModel(true);
  };

  const handleOpen = useCallback(
    (data) => {

      const transformBrandData = (brandData) => ({
        key: brandData._id,
        extId: null,
        image: brandData.image,
        name: brandData.name,
        brandStatus: brandData.brandStatus,
        countPending: 0,
        countIncomplete: 0,
        myoManagementReference: brandData.myoManagementReference
      });

      const selectedBrand = transformBrandData(data?.brand);
      setTimeout(() => {
        history.push({
          pathname: `/admin/property-management`,
          state: {
            defaultKey: 'details',
            propertyId: data?._id,
            formOpen: true,
            allInOnePropertyBreadCrumb: true,
            selectedBrand: selectedBrand,
            allInOneProperty: data
          }
        });
      }, 500);
    },
    [history]
  );
  const columns = [
    {
      name: "Brand",
      cell: (row) => (
        row.brand.name
      ),
    },
    {
      name: 'Property',
      selector: (row) => row.source === 'hotel' ? row.hotelName : row.propertyName,
    },
    {
      name: "Country",
      cell: (row) => row.country
    },
    {
      name: "City",
      cell: (row) => row.city
    },
    {
      name: "Inventories",
      selector: (row) => {
        const value = row?.noOfRoomTypesOffered;
        return value;
      },
    },
    {
      name: 'Action',
      button: false,
      cell: (row: any) => {
        return (
          <>
            <img className="mr-4 copy-icons" src={copy} alt="icon" onClick={() => {
              handleRowClick(row);
            }} />

            <img
              className="iconSizes"
              src={ViewEdit}
              alt=""
              onClick={() => {
                handleOpen(row);
              }}
            />
          </>)
      },
    },
  ];
  return (
    <>
      <div className={`incomplete-table superadmin-table ${loadModel ? `show ${visibleData?.length < 4 ? 'superadmin-height' : ''}` : ''}`}>
        <div className="tbl_accr_itm mysa-yellow">
          <div className="tbl_hdr mysa-yellow">
            <h5 className="m-0 fw-bold text-white">Incomplete Properties</h5>
            <button className="text-white pointer" onClick={incompletePropertiesHandler}>
              Expand
              <span>
                <img src={isExpanded ? minus : plus} alt="icon" />
              </span>
            </button>
          </div>
          <div className="acrd_tbl">
            <DataTable
              columns={columns}
              data={visibleData}
              striped={true}
              customStyles={tableStyles}
              theme="mysaTable"
              fixedHeader={true}
              noDataComponent={"Updating records"}
              conditionalRowStyles={[
                {
                  when: (row) => true,
                  classNames: ['row-item'],
                },
              ]}
            />
            {currentIndex < incompletePropertiesData?.length && (
              <div className="more-data-section">
                <Button onClick={loadMoreData} className="more_button">More</Button>
              </div>
            )}
          </div>
        </div>
        <div className="brand-modal">
          <div className="brand-dialog">
            <div className="brand_content">
              <div className="brand_modal">
                <div className="brand_header mysa-yellow">
                  <h3>{selectedRowData?.source === 'hotel' ? selectedRowData?.hotelName : selectedRowData?.propertyName}</h3>
                  <img className="cancel-icon" src={cancel} alt="icon" onClick={() => {
                    setLoadModel(false);
                  }} />
                </div>
                <div className="brand_body">
                  <ul>
                    <li>{selectedRowData?.source === 'hotel' ? 'Hotel details' : 'Property details'}
                      <div
                        className={`point-circle
                                    ${(selectedRowData?.source === 'hotel' ? selectedRowData?.hotelName : selectedRowData?.propertyName &&
                            selectedRowData?.city &&
                            selectedRowData?.country &&
                            selectedRowData?.propertyType &&
                            selectedRowData?.description &&
                            selectedRowData?.systemNotificationEmail &&
                            selectedRowData?.contactEmail &&
                            selectedRowData?.termPdf &&
                            selectedRowData?.source === 'hotel' ? selectedRowData?.hotelStatus : selectedRowData?.propertyStatus)
                            ? 'mysa-green'
                            :
                            (selectedRowData?.source === 'hotel' ? selectedRowData?.hotelStatus : selectedRowData?.propertyStatus &&
                              selectedRowData?.source === 'hotel' ? selectedRowData?.hotelName : selectedRowData?.propertyName &&
                              selectedRowData?.city &&
                            selectedRowData?.country)
                              ? 'mysa-pink'
                              : ''
                          }`}
                      ></div>
                    </li>
                    <li>Transport <div className={`${selectedRowData?.transportData ? 'mysa-green' : 'mysa-pink'} point-circle`}></div></li>
                    <li>Guest comfort <div className={`${selectedRowData?.guestComfortsData ? 'mysa-green' : 'mysa-pink'} point-circle`}></div></li>
                    {(selectedRowData?.source !== 'hotel') && (
                      <li>
                        Property sustainability
                        <div className={`${selectedRowData?.propertySustainabilitiesData ? 'mysa-green' : 'mysa-pink'} point-circle`}></div>
                      </li>
                    )}
                    <li>{(selectedRowData?.source !== 'hotel' ? 'Property finance' : 'Hotel invoice')} <div className={`${selectedRowData?.propertyFinancesData ? 'mysa-green' : 'mysa-pink'} point-circle`}></div></li>
                    <li>Building images <div className={`${selectedRowData?.propertyImagesData?.length > 0 ? 'mysa-green' : 'mysa-pink'} point-circle`}></div></li>
                    <li>Building  amenities <div className={`${selectedRowData?.propertyAmenitiesData ? 'mysa-green' : 'mysa-pink'} point-circle`}></div></li>
                    <li>Check-in <div className={`${selectedRowData?.propertyCheckInProcessesData ? 'mysa-green' : 'mysa-pink'} point-circle`}></div></li>
                    <li className="inventory-btn"><a onClick={() => {
                      setLoadPropertyModel(true);
                    }} className="inventory-btn">Inventory
                      <img src={arrow} alt="icon" /></a></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className={`inventory-sub-menu ${loadPropertyModel ? "inventory-modal-show" : "inventory-modal-hide"}`}>
          {loadPropertyModel ? <div className="white_layer"></div> : null}
          <div className="brand-modal">
            <div className="brand-dialog">
              <div className="brand_content">
                <div className="brand_modal">
                  <div className="brand_header mysa-yellow">
                    <img className="cancel-icon" src={cancel} alt="icon" onClick={() => {
                      setLoadPropertyModel(false);
                    }} />
                  </div>
                  <div className="brand_body">
                    <ul>
                      {Array.isArray(selectedRowData?.inventories) && selectedRowData?.inventories?.length ? (
                        selectedRowData.inventories.map((item, index) => (
                          <li className={`${loadInventoryModel ? 'active' : ''} ${!item.accommodationDetail ? 'no-accommodation' : ''}`} onMouseEnter={() => {
                            if (item.accommodationDetail) {
                              handleInventoryClick(item);
                            }
                          }} onMouseLeave={() => setLoadInventoryModel(false)} key={index}>{item.accommodationDetail || "Not created"}</li>
                        ))
                      ) : (
                        <li>Not created</li>
                      )}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={`inventory-sub-menu-list ${loadInventoryModel ? "inventory-modal-show" : "inventory-modal-hide"}`}>
          <div className="brand-modal">
            <div className="brand-dialog">
              <div className="brand_content">
                <div className="brand_modal">
                  <div className="brand_header mysa-yellow">
                  </div>
                  <div className="brand_body">
                    <ul>
                      <li>Inventory details
                        <div
                          className={`point-circle
                                    ${(selectedInventory?.accommodationConfiguration &&
                              (selectedInventory?.moderate?.details?.new_value || selectedInventory?.accommodationDetail) &&
                              selectedInventory?.bedType?.length > 0)
                              ? 'mysa-green'
                              : 'mysa-pink'
                            }`}
                        ></div>
                      </li>
                      <li>Inventory images <div className={`point-circle ${selectedInventory?.inventoryImages?.length ? 'mysa-green' : 'mysa-pink'}`}></div></li>
                      <li>Inventory amenities <div className={`point-circle ${selectedInventory?.inventoryAmenities?.length ? 'mysa-green' : 'mysa-pink'}`}></div></li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default IncompletePropertiesList;
