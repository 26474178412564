import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useStoreActions, useStoreState } from 'easy-peasy';
import moment from 'moment';
import ReactHtmlParser from 'react-html-parser';
import { RfpProcessStatus } from '../../../common/constants';

// const socket = io(process.env.REACT_APP_API_URL?? '');

const NotificationList: React.FC<any> = ({ myoSelectedBrand }): JSX.Element => {
    const [notification, setNotification] = useState<any>([]);
    const [listData, setListData] = useState<any>([]);
    // const [socketNotification, setSocketNotification] = useState<any>();
    const { brandDashboardSuccess, brandDashboardError, getAllCreatedRfpProcessListSuccess } = useStoreState<any>((state) => ({
        brandDashboardSuccess: state.DashboardModel.brandDashboardSuccess,
        brandDashboardError: state.DashboardModel.brandDashboardError,
        getAllCreatedRfpProcessListSuccess: state.rfpProcess.getAllCreatedRfpProcessListSuccess,
    }));

    const {

        getAllCreatedRfpProcessList,

        // updateBrandRFPData
    } = useStoreActions<any>((actions) => ({

        getAllCreatedRfpProcessList: actions.rfpProcess.getAllCreatedRfpProcessList,

    }));


    useEffect(() => {
        getAllCreatedRfpProcessList();
    }, [getAllCreatedRfpProcessList]);

    //////////////////////////////////////////////////////////////////////////////////
    useEffect(() => {
        if (getAllCreatedRfpProcessListSuccess?.data) {
            // console.log(getAllCreatedRfpProcessListSuccess?.data, 'filteredRfpProcessList')
            // const filteredRfpProcessList = getAllCreatedRfpProcessListSuccess?.data.filter(process => (process.rfpStatus === RfpProcessStatus.INPROGRESS));

            if (getAllCreatedRfpProcessListSuccess?.data?.length > 0) {

                const data = getAllCreatedRfpProcessListSuccess?.data
                    // .filter((process) => !process.declinedBy.includes(reduxBrandDetail?.selectedBrandId))
                    // .filter((process) => !process.addedBy.includes(brandId))
                    .map(({ endDate, locations, _id, clientId, programmeId, overviewForInvite, keyClientRequirement, property, addedProperties, startDate, rfpName, questionSubmitDate, isDeclined, certificateTemplateId, brandRfpStatus }) => {
                        const cities = locations.map((item: any) => item.cities)
                        const citiesArray = [].concat.apply([], cities);
                        const cityNames = citiesArray.map((item: any) => item.cityName);
                        // console.log(property, 'addedProperties');
                        const updatedAddedProperties = addedProperties.map((addedProp) => {
                            const matchingProperty = property.find(
                                (prop) => prop._id === addedProp.propertyId
                            );

                            return {
                                ...addedProp,
                                brandId: matchingProperty?.brandId ?? null, // Add brandId if found, otherwise null
                            };
                        });

                        // console.log(myoSelectedBrand, 'brandId')
                        // console.log(updatedAddedProperties, 'addedProperties');
                        const list = updatedAddedProperties.filter(addedProperty => (addedProperty.propertySelection === false && addedProperty.brandId === myoSelectedBrand));


                        // console.log(list, 'list');

                        // if (selectedProperty?.length === 0) {

                        // }
                        return {
                            list,
                            rfpId: _id
                        }
                    });

                setListData(data);
                // setMemoizedRfpProcessData(data);
            }
        }



    }, [myoSelectedBrand, getAllCreatedRfpProcessListSuccess?.data]);


    useEffect(() => {
        // console.log(listData, 'listData')
        const data: any = [];
        if (brandDashboardSuccess) {
            // console.log(brandDashboardSuccess?.data?.notification,'brandDashboardSuccess?.data?.notification')
            const mergedData = brandDashboardSuccess?.data?.notification.map(entity => {
                const matchingRFP = listData.find(rfp => rfp.rfpId === entity.entityId);

                return {
                    ...entity,
                    list: matchingRFP ? matchingRFP.list : [] // Merge list if a match is found, else return an empty array
                };
            });
            // console.log(mergedData, 'mergedData')
            // // if (listData?.legth) {
            // //     setNotification(brandDashboardSuccess?.data?.notification)
            // // }
            if (mergedData) {
                mergedData.map(en => {
                    if (en.list?.length > 0) {
                        data.push(en);

                    } else {

                    }
                })
            }
            setNotification(data);
            // console.log(data, 'data')
            // setNotification(brandDashboardSuccess?.data?.notification);

        }
    }, [brandDashboardError, brandDashboardSuccess, listData,]);


    // console.log(notification, 'notification')


    // useEffect(() => {
    //     socket.on("receive_message", (data) => {
    //         if(data !== undefined)
    //         setSocketNotification(data); 
    //       });

    //     return () => {
    //       socket.off("socket_id");
    //       socket.off("receive_message");
    //     };
    // }, []);


    // return (
    //     <>
    //    <ul className='ntf_list m-0'>
    //     {socketNotification ? (
    //           <>
    //          <li className='ntf_list_item'>
    //          <div>
    //          {ReactHtmlParser (socketNotification)}
    //          </div>
    //          <div>
    //          { moment().format('DD-MMM-YYYY') }
    //          </div>
    //       </li>
    //       </>
    // 					) : (
    // 						<></>
    //         )}
    //        {
    //         notification.map((notification,index) => {
    //         return <li key={index} className='ntf_list_item'>
    //         <div>
    //         {ReactHtmlParser (notification.text)}
    //         </div>
    //         <div>
    //         {notification.createdAt ? moment(notification.createdAt).format('DD-MMM-YYYY') : moment().format('DD-MMM-YYYY')}
    //         </div>
    //        </li>
    //          })}
    //     </ul>  
    //     </>

    // )

    // const columns = [
    //     {
    //         name: 'Notification',
    //         maxWidth: '1000px' ,
    //         cell: (row) => {


    //             return (
    //                 <div dangerouslySetInnerHTML={{ __html: row?.text }}></div>
    //             )
    //         }
    //     },
    //     {
    //         name: 'Created',
    //         maxWidth: '250px' ,
    //         selector: row => moment(row.createdAt).format('D MMM YYYY'),
    //     },
    // ];

    // return (
    //     <>
    //         <div className='tbl_accr_itm' style={{ backgroundColor: "#52d1c2" }}>
    //             <div className='tbl_hdr' style={{ backgroundColor: "#52d1c2" }}>
    //                 <h5 className='m-0 fw-bold text-white'>Notifications</h5>
    //                 <button className='text-white pointer' onClick={ntfHandler}>Expand
    //                     <span>
    //                         <img src={notification ? minus : plus} alt="icon" />
    //                     </span>
    //                 </button>
    //             </div>
    //             <div className='acrd_tbl'>
    //                 <DataTable
    //                     columns={columns}
    //                     data={notifications}
    //                     striped={true}
    //                     customStyles={tableStyles}
    //                     theme='mysaTable'
    //                     fixedHeader={true}
    //                     noDataComponent={'Updating records'}
    //                 />
    //             </div>
    //         </div>
    //     </>
    // )


    return (
        <>
            <ul className='ntf_list m-0'>
                {
                    notification.map((notification, index) => {

                        return <li key={index} className='ntf_list_item'>
                            <div>
                                {ReactHtmlParser(notification.text)}
                            </div>
                            <div>
                                {notification.updatedAt ? moment(notification.updatedAt).format('DD-MMM-YYYY') : moment().format('DD-MMM-YYYY')}
                            </div>
                        </li>
                    })}
            </ul>
        </>

    )
}

export default NotificationList;