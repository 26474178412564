import { FC, useState, useEffect } from "react";
import { UserAccess } from "../../../../common/constants";
import { isCorporateProgrammeModuleAvailable } from "../../../../common/functions";
import AccessDenied from "../../../common/AccessDenied";
import { Tab, Row, Col, Nav } from 'react-bootstrap';
import { navStyle } from "../../../../common/components-style";
import { useHistory } from 'react-router-dom';
import ChainOverviewData from "../form/ChainOverviewData";
import BrandOverviewData from "../form/BrandOverviewData";

const ChainOverviewDataFrom: FC<any> = ({ reportId, programmeId, setFormOpen, reload, selectedProgramme, selectedClient, startDate, endDate, location, selectedClientName, accommodationType, reportName, associateProgrammes, currency }): JSX.Element => {
    const [tabKey, setTabKey] = useState<any>(location.state?.tabKey ? location.state?.tabKey : 'chainOverviewReport');
    const history = useHistory();

    useEffect(() => {
        if (location?.state?.defaultKey) {
            setTabKey(location?.state?.defaultKey);
        }
    }, [location?.state?.defaultKey]);


    return (
        <div style={{ marginLeft: '-40px', marginTop: '-38px' }} >
            {reportId ? <Tab.Container defaultActiveKey="chainOverviewReport" key={tabKey} activeKey={tabKey} onSelect={(tab) => setTabKey(tab)}>
                <Row >
                    <Col sm={2}>
                        <Nav variant="pills" className="flex-column secondry-menu" style={navStyle}>
                            <div style={{ marginTop: '0%' }}>
                                {reportId && <Nav.Item>
                                    <Nav.Link eventKey="chainOverviewReport">Chain Overview</Nav.Link>
                                </Nav.Item>}
                                {reportId && <Nav.Item>
                                    <Nav.Link eventKey="brandOverview">Brand Overview</Nav.Link>
                                </Nav.Item>}
                            </div>
                        </Nav>
                    </Col>
                    <Col sm={10}>
                        <Tab.Content>
                            <Tab.Pane eventKey="chainOverviewReport">
                                <div>
                                    {isCorporateProgrammeModuleAvailable(selectedProgramme?.clientId, selectedProgramme?.key, UserAccess.programme_access.programmeAdmin.code) ? <ChainOverviewData programmeId={programmeId} reportId={reportId} setFormOpen={setFormOpen} reload={reload} selectedClient={selectedClient} selectedProgramme={selectedProgramme} accommodationType={accommodationType} reportName={reportName} currency={currency} associateProgrammes={associateProgrammes} location={location} /> : <AccessDenied />}
                                </div>
                            </Tab.Pane>
                            <Tab.Pane eventKey="brandOverview">
                                <div>
                                    {isCorporateProgrammeModuleAvailable(selectedProgramme?.clientId, selectedProgramme?.key, UserAccess.programme_access.programmeAdmin.code) ? <BrandOverviewData programmeId={programmeId} reportId={reportId} setFormOpen={setFormOpen} reload={reload} selectedClient={selectedClient} selectedProgramme={selectedProgramme} accommodationType={accommodationType} reportName={reportName} currency={currency} associateProgrammes={associateProgrammes} location={location} /> : <AccessDenied />}
                                </div>
                            </Tab.Pane>
                        </Tab.Content>
                    </Col>
                </Row>
            </Tab.Container> : null
            }
        </div>
    )
}


export default ChainOverviewDataFrom;
