import { Form, Col, Row, Button } from "react-bootstrap";
import { FC, useCallback, useState, useMemo } from "react";
import { useStoreActions } from "easy-peasy";
import { toast } from 'react-toastify';
import RFPBreadCrumbPage from "../../../common/RFPBredcrumPage";
import { useDropzone } from 'react-dropzone';
import * as XLSX from 'xlsx';
import Papa from 'papaparse';


const UploadPropertyData: FC<any> = ({ reportId, programmeId, setFormOpen, reload, selectedClient, selectedProgramme, accommodationType, reportName }): JSX.Element => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const {

        createHotelTempData
    } = useStoreActions<any>((actions) => ({

        getCorporateProgrammeById: actions.client.getCorporateProgrammeById,
        createHotelTempData: actions.hotel.createHotelTempData,
    }))

    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    const requiredHeaders = useMemo(() => ["brand", "hotel", "address", "state", "city", "country"], []);

    const validateHeaders = useCallback((headers) => {
        const normalizedHeaders = headers.map((header) => header.toLowerCase());
        return requiredHeaders.every((requiredHeader) =>
            normalizedHeaders.includes(requiredHeader)
        );
    }, [requiredHeaders]);


    const callApi = useCallback(async (user) => {
        if (user) {
            setIsLoading(true);

            let newData = user.map(obj => {
                let newObj = {};
                for (let key in obj) {
                    let newKey = key.replace(/\s+/g, '').toLowerCase();
                    newObj[newKey] = obj[key];
                }
                return newObj;
            });

            const validData = newData.filter(data =>
                data.brand?.trim() || data.hotel?.trim() || data.address?.trim() || data.city?.trim() || data.state?.trim() || data.country?.trim()
            );

            for (const [index, data] of validData.entries()) {
                const payload = {
                    clientId: selectedClient?.key ? selectedClient?.key : selectedClient?._id,
                    programmeId: programmeId,
                    reportId: reportId,
                    ...(data.brand?.trim() ? { brandName: data.brand.trim() } : {}),
                    ...(data.hotel?.trim() ? { hotelName: data.hotel.trim() } : {}),
                    ...(data.address?.trim() ? { address: data.address.trim() } : {}),
                    ...(data.city?.trim() ? { city: data.city.trim() } : {}),
                    ...(data.state?.trim() ? { state: data.state.trim() } : {}),
                    ...(data.country?.trim() ? { country: data.country.trim() } : {}),
                };

                if (Object.keys(payload).length > 2) {
                    try {
                        await createHotelTempData(payload);
                        if (index === validData.length - 1) {
                            toast.success("File uploaded successfully!", {
                                position: toast.POSITION.BOTTOM_RIGHT,
                                className: 'foo-bar'
                            });
                        }
                    } catch (error) {
                        console.error("Error occurred during data upload:", error);
                        toast.error("Error uploading data. Please try again.", {
                            position: toast.POSITION.BOTTOM_RIGHT,
                            className: 'foo-bar'
                        });
                    }
                }
            }

            setIsLoading(false);
        }
    }, [createHotelTempData, programmeId, reportId, selectedClient?._id, selectedClient?.key]);



    const onDrop = useCallback((acceptedFiles) => {
        const file = acceptedFiles[0];
        const reader = new FileReader();

        reader.onload = () => {
            const binaryStr = reader.result;

            if (file.name.endsWith('.xlsx') || file.name.endsWith('.xls')) {
                const workbook = XLSX.read(binaryStr, { type: 'binary' });
                const sheetName = workbook.SheetNames[0];
                const worksheet = workbook.Sheets[sheetName];
                const json = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

                const headers = json[0];
                if (validateHeaders(headers)) {
                    const dataRows = XLSX.utils.sheet_to_json(worksheet);
                    callApi(dataRows);
                } else {
                    alert('Invalid file format. Required headers: brand, hotel, address, state, city, country');
                }

            } else if (file.name.endsWith('.csv')) {
                Papa.parse(binaryStr, {
                    header: true,
                    complete: (results) => {
                        const headers = Object.keys(results.data[0]);
                        if (validateHeaders(headers)) {
                            callApi(results.data);
                        } else {
                            console.error('Invalid file format');
                            alert('Invalid file format. Required headers: brand, hotel, address, state, city, country');
                        }
                    },
                });
            } else {
                console.error('Unsupported file format');
                alert('Unsupported file format. Please upload a valid .xlsx or .csv file.');
            }
        };
        reader.readAsBinaryString(file);
    }, [callApi, validateHeaders]);

    const { open } = useDropzone({ onDrop });

    ////////////////////////////////////////////////////////////////////////////////////////////////////

    return (
        <>

            {reportId &&
                <RFPBreadCrumbPage isPageName="programmeSpendReport" selectedClient={selectedClient} selectedProgramme={selectedProgramme} selectedClientName={selectedClient?.companyName} selectedProgrammeName={selectedProgramme?.programmeName} reportId={reportId} programmeId={programmeId} startDate={reportName}></RFPBreadCrumbPage>
            }
            <div className="create-update-wrapper">
                <Form>
                    <Row className='formSpace'>
                        <Col md={3} style={{ textAlign: 'right', }}>
                            <Form.Label>
                                Company Name
                            </Form.Label>
                        </Col>

                        <Col md={9} style={{ textAlign: 'left' }}>
                            <p className="lead">{selectedClient?.companyName}</p>
                        </Col>
                    </Row>
                    <Row className='formSpace'>
                        <Col md={3} style={{ textAlign: 'right' }}>
                            <Form.Label> Name of programme</Form.Label>
                        </Col>

                        <Col md={9} style={{ textAlign: 'left' }}>
                            <p className="lead">{selectedProgramme?.programmeName}</p>
                        </Col>
                    </Row>
                    <Row className='formSpace'>
                        <Col md={3} style={{ textAlign: 'right' }}>
                            <Form.Label> Accommodation type</Form.Label>
                        </Col>

                        <Col md={9} style={{ textAlign: 'left' }}>
                            <p className="lead">{accommodationType}</p>
                        </Col>
                    </Row>

                    <Row style={{ paddingTop: '30px' }} className='formSpace'>
                        <Col md={3} style={{ textAlign: 'right' }}>

                        </Col>
                        <Col md={3} >
                            <Button id="create-button" onClick={open} disabled={isLoading}>
                                {isLoading ? 'Uploading...' : 'Upload File'}
                            </Button>
                        </Col>
                    </Row>

                </Form>
            </div>
        </>
    )
}

export default UploadPropertyData;
