import { message, Select } from 'antd';
import { useStoreActions, useStoreState } from 'easy-peasy';


import React, { useCallback, useEffect, useState } from 'react';
import { Button, Col, Form, Row, Spinner } from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';
import 'react-phone-input-2/lib/style.css';
import { toast } from 'react-toastify';
import { formButton } from '../../../../common/components-style';
import { BookerPermissions, TravelerPermissions } from '../../../../common/constants';
import { ErrorMessage } from '@hookform/error-message';
import { CompanyPermissionForm } from '../../../../common/interfaces';

const { Option } = Select;
const CompanyPermission: React.FC<any> = ({ programmeId, selectedClient, }): JSX.Element => {

	const [loading, setLoading] = useState<boolean>(false);
	const [programmeTypeList, setProgrammeTypeList] = useState<any>();
	const { register, handleSubmit, formState: { errors }, control } = useForm<CompanyPermissionForm>();
	const [editEnable, setEditEnable] = useState<boolean>(false);
	const [companyPermissions, setCompanyPermissions] = useState<any>();
	const [bookerPermissions, setBookerPermissions] = useState<any>();
	const [travelerPermissions, setTravelerPermissions] = useState<any>();
	const [selectedClientName, setSelectedClientName] = useState<string>("");
	const [selectedProgrammeName, setSelectedProgrammeName] = useState<string>("");
	// const [isProcessDeleted, setIsProcessDeleted] = useState<boolean>(false);
	// const [selectedProgrammeId, setSelectedProgrammeId] = useState<string>("");
	const [clientId, setClientId] = useState<string>("");
	const [programmeType, setProgrammeType] = useState<any>();
	const [companyPermissionId, setCompanyPermissionId] = useState<string>("");

	////////////////////////////////////////////////////////////////////////////////////////////////////
	const { getClientList, resetClient, getProgrammeType, getCorporateProgrammeById, createCompanyPermission, updateCompanyPermission, getCompanyPermissionByProgrammeId } = useStoreActions<any>((actions) => ({
		getProgrammeType: actions.client.getProgrammeType,
		getClientList: actions.client.getClientList,
		getCorporateProgrammeById: actions.client.getCorporateProgrammeById,
		resetClient: actions.client.resetClient,
		createCompanyPermission: actions.client.createCompanyPermission,
		updateCompanyPermission: actions.client.updateCompanyPermission,
		getCompanyPermissionByProgrammeId: actions.client.getCompanyPermissionByProgrammeId
	}));

	////////////////////////////////////////////////////////////////////////////////////////////////////
	const {

		getProgrammeTypeSuccess,
		createCompanyPermissionSuccess,
		createCompanyPermissionError,
		updateCompanyPermissionSuccess,
		updateCompanyPermissionError,
		getCompanyPermissionByProgrammeIdSuccess,
		getCompanyPermissionByProgrammeIdError,
		getCorporateProgrammeByIdSuccess,
		getCorporateProgrammeByIdError
	} = useStoreState<any>((state) => ({

		getProgrammeTypeSuccess: state.client.getProgrammeTypeSuccess,
		createCompanyPermissionSuccess: state.client.createCompanyPermissionSuccess,
		createCompanyPermissionError: state.client.createCompanyPermissionError,
		updateCompanyPermissionSuccess: state.client.updateCompanyPermissionSuccess,
		updateCompanyPermissionError: state.client.updateCompanyPermissionError,
		getCompanyPermissionByProgrammeIdSuccess: state.client.getCompanyPermissionByProgrammeIdSuccess,
		getCompanyPermissionByProgrammeIdError: state.client.getCompanyPermissionByProgrammeIdError,

		getCorporateProgrammeByIdSuccess: state.client.getCorporateProgrammeByIdSuccess,
		getCorporateProgrammeByIdError: state.client.getCorporateProgrammeByIdError,

	}));


	////////////////////////////////////////////////////////////////////////////////////////////////////
	useEffect(() => {
		// getClientList();
		getProgrammeType();

	}, [getClientList, getProgrammeType, programmeId, selectedClient]);

	// ////////////////////////////////////////////////////////////////////////////////////////////////////
	useEffect(() => {
		if (programmeId) {
			getCorporateProgrammeById(programmeId);
		}
	}, [getCorporateProgrammeById, programmeId]);


	useEffect(() => {
		if (selectedClient?.key) {
			const params: any = {};
			params.clientId = selectedClient?.key;
			params.programmeId = programmeId
			getCompanyPermissionByProgrammeId(params);
		}
	}, [getCompanyPermissionByProgrammeId, getCorporateProgrammeById, programmeId, selectedClient?.key]);


	/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	const callList = useCallback(() => {

		const params: any = {};
		params.clientId = selectedClient?.key;
		params.programmeId = programmeId;
		if (programmeId) {
			getCompanyPermissionByProgrammeId(params);
		}
	}, [getCompanyPermissionByProgrammeId, programmeId, selectedClient?.key])


	////////////////////////////////////////////////////////////////////////////////////////////////////
	useEffect(() => {

		if (getProgrammeTypeSuccess) {
			setProgrammeTypeList(getProgrammeTypeSuccess.data);
		}
	}, [getProgrammeTypeSuccess]);

	useEffect(() => {
		if (getCorporateProgrammeByIdSuccess?.data) {
			const { clientId, programmeName, programmeType } = getCorporateProgrammeByIdSuccess.data;
			// setClientDetails(clientId);
			setClientId(clientId._id);
			setSelectedClientName(clientId.companyName);
			// setSelectedProgrammeId(_id);
			setSelectedProgrammeName(programmeName);
			setProgrammeType(programmeType);
		}
		if (getCorporateProgrammeByIdError) {
			toast.error(getCorporateProgrammeByIdError?.message, {
				position: toast.POSITION.BOTTOM_RIGHT,
				className: 'foo-bar'
			});

		}
	}, [getCorporateProgrammeByIdSuccess, getCorporateProgrammeByIdError]);

	useEffect(() => {
		if (getCompanyPermissionByProgrammeIdSuccess) {
			setCompanyPermissions(getCompanyPermissionByProgrammeIdSuccess.data);
			setCompanyPermissionId(getCompanyPermissionByProgrammeIdSuccess?.data?._id);
		}
		if (getCompanyPermissionByProgrammeIdError) {
			toast.error(getCompanyPermissionByProgrammeIdError?.message, {
				position: toast.POSITION.BOTTOM_RIGHT,
				className: 'foo-bar'
			});

		}
	}, [getCompanyPermissionByProgrammeIdSuccess, getCompanyPermissionByProgrammeIdError]);

	////////////////////////////////////////////////////////////////////////////////////////////////////
	useEffect(() => {
		if (createCompanyPermissionSuccess) {
			setLoading(false);
			toast.success("Company permission created successfully", {
				position: toast.POSITION.BOTTOM_RIGHT,
				className: 'foo-bar'
			});
			resetClient();
			callList();
			setEditEnable(false);

		}
		if (createCompanyPermissionError) {
			setLoading(false);
			toast.error(createCompanyPermissionError?.message, {
				position: toast.POSITION.BOTTOM_RIGHT,
				className: 'foo-bar'
			});
			resetClient();
			callList();
			setEditEnable(false);
		}
		if (updateCompanyPermissionSuccess) {
			setLoading(false);
			toast.success("Company permission updated successfully", {
				position: toast.POSITION.BOTTOM_RIGHT,
				className: 'foo-bar'
			});
			resetClient();
			callList();
			setEditEnable(false);
		}
		if (updateCompanyPermissionError) {
			setLoading(false);
			message.error(updateCompanyPermissionError.message);
			resetClient();
			callList();
			setEditEnable(false);
		}

	}, [callList, createCompanyPermissionError, createCompanyPermissionSuccess, resetClient, updateCompanyPermissionError, updateCompanyPermissionSuccess]);

	////////////////////////////////////////////////////////////////////////////////////////////////////
	const onSubmit = useCallback((values) => {
		setLoading(true);
		delete values.programmeType;
		if (companyPermissionId) {
			delete values.clientId;
			delete values.programmeId;
			// values.clientId = clientId;
			values.id = companyPermissionId;
			updateCompanyPermission(values);
		} else {
			values.clientId = clientId;
			values.programmeId = programmeId;
			values.bookerPermissions = bookerPermissions;
			values.travelerPermissions = travelerPermissions;

			createCompanyPermission(values);
		}

	}, [bookerPermissions, clientId, companyPermissionId, createCompanyPermission, programmeId, travelerPermissions, updateCompanyPermission]);

	//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	const changeFormEditable = useCallback(() => {
		if (editEnable) {
			setEditEnable(false);
		} else {
			setEditEnable(true);
		}
	}, [editEnable]);

	//////////// extra seasonal form enable part //////////
	const onChangeBookerPermission = useCallback((value) => {
		setBookerPermissions(value);
	}, []);


	//////////// extra seasonal form enable part //////////
	const onChangeTravelerPermission = useCallback((value) => {
		setTravelerPermissions(value);
	}, []);

	////////////////////////////////////////////////////////////////////////////////////////////////////
	const renderProgrammeTypeList = useCallback(() => {
		if (programmeTypeList) {
			return programmeTypeList.map((prog: any, key: number) => {
				return (
					<Option key={key} prog={prog} value={`${prog._id}`} >{prog.name}</Option>
				)
			});
		}
	}, [programmeTypeList]);


	//////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	return (
		<>
			{/* <Row>
			<Col xs={12}><div className="content-title">{title}</div></Col>
		</Row> */}
			<div>
				<div className="ml-auto edit_btn_wpr">
					{
						!loading && !editEnable && <Button id='edit-button' style={formButton} onClick={changeFormEditable}>Edit</Button>
					}
				</div>
				<div className="jumbotron" style={{ marginTop: "70px" }}>
					<Form onSubmit={handleSubmit(onSubmit)}>
						<Row>
							<Col md={1}></Col>
							<Col md={10}>
								<Row className='formSpace'>
									<Col md={3} style={{ textAlign: 'right' }}>
										<Form.Label>{editEnable && <span className='requiredMark'>*</span>} Company name</Form.Label>
									</Col>
									<Col md={9} style={{ textAlign: 'left' }}>
										{!editEnable && <p className="lead">{selectedClientName}</p>}
										{editEnable && <Form.Control className={`${errors.clientId ? 'is-invalid' : ''}`} defaultValue={selectedClientName} {...register("clientId", { required: true })} readOnly={true} />}
										{editEnable && <div className="invalid-feedback">Please input company name!</div>}
									</Col>
								</Row>
								<Row className='formSpace'>
									<Col md={3} style={{ textAlign: 'right' }}>
										<Form.Label>{editEnable && <span className='requiredMark'>*</span>} Name of programme</Form.Label>
									</Col>
									<Col md={9} style={{ textAlign: 'left' }}>
										{!editEnable && <p className="lead">{selectedProgrammeName}</p>}
										{editEnable && <Form.Control className={`${errors.programmeId ? 'is-invalid' : ''}`} defaultValue={selectedProgrammeName} {...register("programmeId", { required: true })} readOnly={true} />}
										{editEnable && <div className="invalid-feedback">Please input programme name!</div>}
									</Col>
								</Row>
								<Row className='formSpace'>
									<Col md={3} style={{ textAlign: 'right' }}>
										<Form.Label>{editEnable && <span className='requiredMark'>*</span>} Booking permissions</Form.Label>
									</Col>
									<Col md={9} style={{ textAlign: 'left' }}>
										{!editEnable && <p className="lead">{programmeType?.name}</p>}
										{editEnable &&
											<Controller
												name="programmeType"
												defaultValue={programmeType?._id}
												control={control}
												rules={{
													required: true,
												}}
												render={({ field }) =>
													<Select defaultValue={programmeType?._id} className={`${errors.programmeType ? 'is-invalid' : ''}`} style={{ width: '100%' }} onChange={(value) => field.onChange(value)} disabled={true}>
														{renderProgrammeTypeList()}
													</Select>
												}
											/>}
										{editEnable && <div className="invalid-feedback">Please select a programmeType!</div>}
									</Col>
								</Row>

								<Row className='formSpace'>
									<Col md={3} style={{ textAlign: 'right' }}>
										<Form.Label>{editEnable && <span className='requiredMark'>*</span>} Booker permissions</Form.Label>
									</Col>
									<Col md={9} style={{ textAlign: 'left' }}>
										{!editEnable && <p className="lead">{companyPermissions?.bookerPermissions}</p>}

										{editEnable &&
											<Controller
												name="bookerPermissions"
												defaultValue={companyPermissions?.bookerPermissions}
												control={control}
												rules={{
													required: true,
												}}
												render={({ field }) =>
													<>
														{
															companyPermissions?.bookerPermissions === BookerPermissions.CAN_BOOK_FOR_EVERYONE
																?
																<Form.Check
																	inline
																	value={BookerPermissions.CAN_BOOK_FOR_EVERYONE}
																	name="bookerPermissions"
																	label={'Can book for everyone'}
																	type="radio"
																	defaultChecked
																	onChange={(value) => { field.onChange(value.currentTarget.value); onChangeBookerPermission(value.currentTarget.value); }}
																/>
																:
																<Form.Check
																	inline
																	value={BookerPermissions.CAN_BOOK_FOR_EVERYONE}
																	name="bookerPermissions"
																	label={'Can book for everyone'}
																	type="radio"
																	onChange={(value) => { field.onChange(value.currentTarget.value); onChangeBookerPermission(value.currentTarget.value); }}
																/>
														}

														{
															companyPermissions?.bookerPermissions === BookerPermissions.TRAVELER_SPECIFIED
																?
																<Form.Check
																	inline
																	value={BookerPermissions.TRAVELER_SPECIFIED}
																	name="bookerPermissions"
																	label={'Traveler Specified'}
																	type="radio"
																	defaultChecked
																	onChange={(value) => { field.onChange(value.currentTarget.value); onChangeBookerPermission(value.currentTarget.value); }}
																/>
																:
																<Form.Check
																	inline
																	value={BookerPermissions.TRAVELER_SPECIFIED}
																	name="bookerPermissions"
																	label={'Traveler specified'}
																	type="radio"
																	onChange={(value) => { field.onChange(value.currentTarget.value); onChangeBookerPermission(value.currentTarget.value); }}
																/>
														}

														{
															companyPermissions?.bookerPermissions === BookerPermissions.COMPANY_SPECIFIED
																?
																<Form.Check
																	inline
																	value={BookerPermissions.COMPANY_SPECIFIED}
																	name="bookerPermissions"
																	label={'Company specified'}
																	type="radio"
																	defaultChecked
																	onChange={(value) => { field.onChange(value.currentTarget.value); onChangeBookerPermission(value.currentTarget.value); }}
																/>
																:
																<Form.Check
																	inline
																	value={BookerPermissions.COMPANY_SPECIFIED}
																	name="bookerPermissions"
																	label={'Company specified'}
																	type="radio"
																	onChange={(value) => { field.onChange(value.currentTarget.value); onChangeBookerPermission(value.currentTarget.value); }}
																/>
														}

													</>
												}
											/>}
										{editEnable &&
											<ErrorMessage
												errors={errors}
												name="bookerPermissions"
												render={({ message }) => <div className="invalid-feedback-custom">Please select booker permissions!</div>} />}
									</Col>
								</Row>
								<Row className='formSpace'>
									<Col md={3} style={{ textAlign: 'right' }}>
										<Form.Label>{editEnable && <span className='requiredMark'>*</span>} Traveler permissions</Form.Label>
									</Col>
									<Col md={9} style={{ textAlign: 'left' }}>
										{!editEnable && <p className="lead">{companyPermissions?.travelerPermissions}</p>}

										{editEnable &&
											<Controller
												name="travelerPermissions"
												defaultValue={companyPermissions?.travelerPermissions}
												control={control}
												rules={{
													required: true,
												}}
												render={({ field }) =>
													<>
														{
															companyPermissions?.travelerPermissions === TravelerPermissions.CAN_BOOK_FOR_SELF
																?
																<Form.Check
																	inline
																	value={TravelerPermissions.CAN_BOOK_FOR_SELF}
																	name='travelerPermissions'
																	label={'Can book for self'}
																	type="radio"
																	defaultChecked
																	onChange={(value) => { field.onChange(value.currentTarget.value); onChangeTravelerPermission(value.currentTarget.value); }}
																/>
																:
																<Form.Check
																	inline
																	value={TravelerPermissions.CAN_BOOK_FOR_SELF}
																	name='travelerPermissions'
																	label={'Can book for self'}
																	type="radio"
																	onChange={(value) => { field.onChange(value.currentTarget.value); onChangeTravelerPermission(value.currentTarget.value); }}
																/>
														}

														{
															companyPermissions?.travelerPermissions === TravelerPermissions.CAN_VIEW_BOOKING
																?
																<Form.Check
																	inline
																	value={TravelerPermissions.CAN_VIEW_BOOKING}
																	name='travelerPermissions'
																	label={'Can only manage/view bookings'}
																	type="radio"
																	defaultChecked
																	onChange={(value) => { field.onChange(value.currentTarget.value); onChangeTravelerPermission(value.currentTarget.value); }}
																/>
																:
																<Form.Check
																	inline
																	value={TravelerPermissions.CAN_VIEW_BOOKING}
																	name='travelerPermissions'
																	label={'Can only manage/view bookings'}
																	type="radio"
																	onChange={(value) => { field.onChange(value.currentTarget.value); onChangeTravelerPermission(value.currentTarget.value); }}
																/>
														}



													</>
												}
											/>}
										{editEnable &&
											<ErrorMessage
												errors={errors}
												name="travelerPermissions"
												render={({ message }) => <div className="invalid-feedback-custom">Please select traveler permissions!</div>} />}
									</Col>
								</Row>
								<Row className='formSpace'>
									<Col md={3} style={{ textAlign: 'right' }}>
									</Col>
									<Col md={9} style={{ textAlign: 'left' }}>

										{!loading && editEnable &&
											<Button type="submit" id='approve-button' style={formButton} >Update</Button>
										}


										{
											loading && <div style={{ textAlign: 'center' }}><Spinner animation="border" variant="warning" /></div>
										}
									</Col>
								</Row>
							</Col>
							<Col md={1}></Col>
						</Row>
					</Form>
				</div>
			</div>
		</>
	)
};

export default CompanyPermission;