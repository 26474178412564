import React from 'react';
import { Route, Switch } from 'react-router-dom';

import Test from '../Test';
import Home from '../components/admin/home/Home';
import NotFound from '../components/NotFound';
import ForgotPassword from '../components/Public/ForgotPassword';
import ConfirmForgotPassword from '../components/Public/ConfirmForgotPassword';
import ResetPassword from '../components/Public/ResetPassword';
import BrandSignUp from '../components/Public/brandSignup/BrandSignUp';
import SignUpFormHome from '../components/Public/brandSignup/form/SignUpFormHome';

import Login from '../components/Public/Login';
import ProtectedRoutes from './ProtectedRoutes';
import PublicRoutes from './PublicRoutes';
import ChangePassword from '../components/Public/ChangePassword';

// import BrandHome from '../components/admin/brand-management/BrandHome';
import BrandHomePage from '../components/admin/brand-management/NewBrandHomePage';
import UserHome from '../components/admin/user-management/UserHome';
import PropertyHome from '../components/admin/property-management/PropertyHome';
import PartnerHome from '../components/admin/partner-management/PartnerHome';
import HotelHome from '../components/admin/hotel-management/HotelHome'
import SettingHome from '../components/admin/system-setup-management/SettingHome';
import ProgrammeSettingHome from '../components/admin/programme-setup-management/HomePage';
// import CorporatePropertyManagementHome from '../components/admin/corporate-property-management/CorporatePropertyManagementHome';
import CorporateProgrammeManagementHome from '../components/admin/corporate-programme-management/new-component/HomePage';


import SignupList from '../components/admin/home/brandSignup/SignupList';
import SignUpFormHomeDashboard from '../components/admin/home/brandSignup/SignUpFormHome';
// import ClientHome from '../components/admin/client-management/ClientHome';
import ClientHomePage from '../components/admin/client-management/NewClient/NewClientHomePage';
import InventoryHome from '../components/admin/inventory-management/InventoryHome';
import AvailabilityHome from '../components/admin/availability-management/AvailabilityHome';
import RateHome from '../components/admin/rate-management/RateHome';
import BookingHome from '../components/admin/booking-management/BookingHome';
import PropertyPage from '../components/admin/property-page/PropertyPage';
import RfpProcessHome from '../components/admin/rfp-management/RfpProcessHome';
import ProgrammeHome from '../components/admin/programme-management/ProgrammeHome';
import LocationHome from '../components/admin/location-management/LocationHome';
import PaymentPage from '../components/admin/booking-payment/TravellerProfilePayment';
import ConfirmationPage from '../components/admin/confirmation-page/ConfirmationPage';
import BrandRFPAdminHomePage from '../components/admin/brandRfp-management/BrandRFPAdminHomePage';
import BrandHelpHomePage from '../components/admin/brandHelp-management/BrandHelpPage';
import BrandEvaluationPage from '../components/admin/brandRfp-management/MyRfpDetails/BrandMyRfpDetails';
import BrandSubmittedRfpEvaluationPage from '../components/admin/brandRfp-management/SubmittedRfpDetails/BrandSubmittedRfpDetails';
// import BrandAdminHomePage from '../components/admin/corpaorateRfp-managemnet/BrandAdminHomePage';
import TravelerPage from '../components/admin/booking-travelerPage/TravellerProfileSearch';
import MYOSignUpFormHome from '../components/admin/brandRfp-management/myo-questions/form/MYOSignUpFormHome';
import SubmittedRfpMYOSignUpFormHome from '../components/admin/brandRfp-management/myo-questions/form/SubmittedRfpMYOSignUpFormHome';

// client rfp 
import ClientPropertyDetailsToAccess from '../components/admin/client-proposal-management/common-views/property-sub-component/ClientPropertyDetailsToAccess';
import ClientDeclinedPropertyDetailsToAccess from '../components/admin/client-proposal-management/common-views/property-sub-component/ClientDeclinedPropertyDetailsToAccess';
import ClientNegotiationPropertyDetailsToAccess from '../components/admin/client-proposal-management/common-views/property-sub-component/ClientNegotiationPropertyDetailsToAccess';

import ClientOperatorHome from '../components/admin/client-proposal-management/operator/operator/ClientOperatorHome';
import ClientRfpManagementHome from '../components/admin/client-proposal-management/ClientRfpManagementHome';

////////////// booking Request ////////

import ProgrammePropertyDetailsToAccess from '../components/admin/corporate-programme-management/new-component/common-views/ProgrammePropertyDetails';
// import ProgrammeOperatorHome from '../components/admin/corporate-programme-management/operator/ProgrammeOperatorHome';
// import requestBookingHomeView from '../components/admin/external-booker-management/request-booking/booker/RequestBookingBookerHomeView';

/**
 * booking report management
 */
import CorporateBookingReports from '../components/admin/booking-report/booking-reports/corporateBookingReports';
import BookingCorporateView from '../components/admin/booking-report/bookings/corporate/single-view/BookingCorporateView';
import BookerBookingReports from '../components/admin/booking-report/booking-reports/bookerBookingReports';
import BookingBookerView from '../components/admin/booking-report/bookings/booker/single-view/BookingBookerView';
import TravelerBookingReports from '../components/admin/booking-report/booking-reports/travelerBookingReports';
import BookingTravelerView from '../components/admin/booking-report/bookings/traveler/single-view/BookingTravelerView';
import BrandAndPropertyReport from '../components/admin/booking-report/booking-reports/brandAndPropertyReport';
import BookingBrandPropertyView from '../components/admin/booking-report/bookings/brand-and-property/single-view/BookingBrandPropertyView';
import SuperAdminBookingReports from '../components/admin/booking-report/booking-reports/superAdminBookingReports';
import BookingSuperAdminView from '../components/admin/booking-report/bookings/super-admin/single-view/BookingSuperAdminView';
import BrandPropertyManagement from '../components/admin/property-management/brand-property-management/BrandPropertyManagement';
import BrandHotelManagement from '../components/admin/hotel-management/brand-hotel-management/BrandHotelManagement';
import AdminUserManagementHome from '../components/admin/admin-user-management/AdminUserManagementHome';
import CorporateUserManagementHome from '../components/admin/corporate-user-management/HomePage';
import TravelerOptionsHome from '../components/admin/booking-report/traveler-options/TravelerOptionsTraveler';
import TravelerOptionsPropertyPage from '../components/admin/booking-report/property-page/PropertyPage';
import BookingPropertyPage from '../components/admin/booking-report/booking-travelerPage/TravellerProfileSearch';
import BookingPaymentPage from '../components/admin/booking-report/booking-payment/TravellerProfilePayment';
import BookingConfirmationPage from '../components/admin/booking-report/confirmation-page/ConfirmationPage';
/**
 * hotel management
 */
import HotelInventoryHome from '../components/admin/hotel-inventory-management/HotelInventoryHome';

/**
 * Report data
 */

import ProgrammeSpendDataHome from '../components/admin/programme-spend-data/ProgrammeSpendDataHome';
import InviteSelectionForm from '../components/admin/invite-selection-management/InviteSelectionForm';
import LocationOverviewDataHome from '../components/admin/location-overview-data/LocationOverviewDataHome';
import ContractedPropertyDataHome from '../components/admin/contracted-property-data/ContractedPropertyDataHome';
import ChainDataHome from '../components/admin/chain-overview-data/ChainOverviewDataHome';
const Router: React.FC = (): JSX.Element => (

  <>
    <Switch>
      {/* public routes */}
      <PublicRoutes exact path="/" component={Login} />
      <PublicRoutes exact path="/forgot-password" component={ForgotPassword} />
      <PublicRoutes
        exact
        path="/confirm-forgot-password"
        component={ConfirmForgotPassword}
      />
      <PublicRoutes exact path="/reset-password" component={ResetPassword} />
      <PublicRoutes exact path="/change-password" component={ChangePassword} />
      <PublicRoutes exact path="/brand-signup" component={BrandSignUp} />
      <PublicRoutes
        exact
        path="/brand-signup/public-form"
        component={SignUpFormHome}
      />
      <PublicRoutes exact path="/test" component={Test} />

      {/* protected routes */}
      <ProtectedRoutes exact path="/admin" component={Home} />
      <ProtectedRoutes
        path="/admin/messages"
        component={() => "/admin/messages"}
      />
      {/* <ProtectedRoutes path="/admin/client-management" component={ClientHome} /> */}
      <ProtectedRoutes path="/admin/client-management" component={ClientHomePage} />



      {/* corporate booking route */}
      <ProtectedRoutes
        path="/admin/corporate-booking-reports"
        component={CorporateBookingReports}
      />
      <ProtectedRoutes
        path="/admin/corporate-booking-report/:id"
        component={BookingCorporateView}
      />
      {/* booker booking route */}
      <ProtectedRoutes
        path="/admin/booker-booking-reports"
        component={BookerBookingReports}
      />
      <ProtectedRoutes
        path="/admin/booker-booking-report/:id"
        component={BookingBookerView}
      />
      {/* traveler booking route */}
      <ProtectedRoutes
        path="/admin/traveler-booking-reports"
        component={TravelerBookingReports}
      />
      <ProtectedRoutes
        path="/admin/traveler-booking-report/:id"
        component={BookingTravelerView}
      />
      {/* brand and property booking route */}
      <ProtectedRoutes
        path="/admin/brand-property-booking-reports"
        component={BrandAndPropertyReport}
      />
      <ProtectedRoutes
        path="/admin/brand-property-booking-report/:id"
        component={BookingBrandPropertyView}
      />

      {/* super admin booking route */}
      <ProtectedRoutes
        path="/admin/super-admin-booking-reports"
        component={SuperAdminBookingReports}
      />
      <ProtectedRoutes
        path="/admin/super-admin-booking-report/:id"
        component={BookingSuperAdminView}
      />

      <ProtectedRoutes path="/admin/brand-management" component={BrandHomePage} />
      <ProtectedRoutes
        path="/admin/brand-management/new-sign-up/form"
        component={SignUpFormHomeDashboard}
      />
      <ProtectedRoutes path="/admin/user-management" component={UserHome} />
      <ProtectedRoutes
        path="/admin/property-management"
        component={PropertyHome}
      />
      <ProtectedRoutes
        path="/admin/partner-management"
        component={PartnerHome}
      />
      <ProtectedRoutes
        path="/admin/brand-property-management"
        component={BrandPropertyManagement}
      />
      <ProtectedRoutes
        path="/admin/hotel-management"
        component={HotelHome}
      />
      <ProtectedRoutes
        path="/admin/brand-hotel-management"
        component={BrandHotelManagement}
      />
      {/* programme management start*/}
      {/* <ProtectedRoutes
        path="/admin/corporate-property-management"
        component={CorporatePropertyManagementHome}
      /> */}

      {/* programme management stop*/}

      <ProtectedRoutes path="/admin/rate-management" component={RateHome} />
      <ProtectedRoutes
        path="/admin/booking-management"
        component={BookingHome}
      />
      <ProtectedRoutes
        path="/admin/management-information"
        component={() => "/admin/management-information"}
      />
      {/* <ProtectedRoutes path="/admin/new-sign-up" component={SignUpHome} /> */}
      <ProtectedRoutes path="/admin/system-set-up" component={SettingHome} />
      <ProtectedRoutes path="/admin/programme-system-set-up" component={ProgrammeSettingHome} />
      <ProtectedRoutes
        exact
        path="/admin/dashboard/new-sign-up"
        component={SignupList}
      />
      <ProtectedRoutes
        path="/admin/dashboard/new-sign-up/form"
        component={SignUpFormHomeDashboard}
      />
      <ProtectedRoutes
        path="/admin/inventory-management"
        component={InventoryHome}
      />
      <ProtectedRoutes
        path="/admin/availability-management"
        component={AvailabilityHome}
      />
      <ProtectedRoutes path="/admin/property-page" component={PropertyPage} />
      <ProtectedRoutes
        path="/admin/rfp-management"
        component={RfpProcessHome}
      />
      <ProtectedRoutes
        path="/admin/programme-management"
        component={ProgrammeHome}
      />
      <ProtectedRoutes
        path="/admin/location-management"
        component={LocationHome}
      />
      {/* <ProtectedRoutes
        path="/admin/brand-rfp-management"
        component={BrandAdminHomePage}
      /> */}
      <ProtectedRoutes
        path="/admin/brand-rfp-management"
        component={BrandRFPAdminHomePage}
      />
      <ProtectedRoutes path="/admin/booking-payment" component={PaymentPage} />
      <ProtectedRoutes
        path="/admin/confirmation-page"
        component={ConfirmationPage}
      />
      <ProtectedRoutes
        path="/admin/traveler-page"
        component={TravelerPage}
      />
      <ProtectedRoutes
        exact
        path="/admin/brand-rfp/:name"
        component={BrandEvaluationPage}
      />
      <ProtectedRoutes
        exact
        path="/admin/brand-submittedRfp/:path"
        component={BrandSubmittedRfpEvaluationPage}
      />
      <ProtectedRoutes
        path="/admin/myo-questions-form"
        component={MYOSignUpFormHome}
      />
      <ProtectedRoutes
        path="/admin/submitted-myo-questions-form"
        component={SubmittedRfpMYOSignUpFormHome}
      />



      {/* client rfp process start*/}
      <ProtectedRoutes path="/admin/client-proposal-management/operator/:propertyId/:rfpId" component={ClientOperatorHome} />
      <ProtectedRoutes path="/admin/client-proposal-management/property/:propertyId/:rfpId" component={ClientPropertyDetailsToAccess} />

      <ProtectedRoutes path="/admin/client-proposal-management/declinedProperty/:propertyId/:rfpId" component={ClientDeclinedPropertyDetailsToAccess} />
      <ProtectedRoutes path="/admin/client-proposal-management/negotiationProperty/:propertyId/:rfpId" component={ClientNegotiationPropertyDetailsToAccess} />
      <ProtectedRoutes path="/admin/client-proposal-management" component={ClientRfpManagementHome} />
      {/* <ProtectedRoutes path="/admin/corporate-programme-management/operator/:propertyId/:rfpId" component={ProgrammeOperatorHome} /> */}
      <ProtectedRoutes path="/admin/corporate-programme-management/property/:propertyId/:rfpId" component={ProgrammePropertyDetailsToAccess} />
      {/* <ProtectedRoutes path="/admin/client-proposal-management/property/:propertyId/:rfpId" component={ClientPropertyDetailsToAccess} /> */}
      <ProtectedRoutes
        path="/admin/corporate-programme-management" component={CorporateProgrammeManagementHome} />


      {/* client rfp process end*/}

      <ProtectedRoutes path="/admin/admin-user-management" component={AdminUserManagementHome} />
      <ProtectedRoutes path="/admin/corporate-user-management" component={CorporateUserManagementHome} />
      <ProtectedRoutes path="/admin/traveler-options" component={TravelerOptionsHome} />
      <ProtectedRoutes path="/admin/traveler-property-page" component={TravelerOptionsPropertyPage} />
      <ProtectedRoutes path="/admin/booking-property-page" component={BookingPropertyPage} />
      <ProtectedRoutes path="/admin/booker-booking-payment" component={BookingPaymentPage} />
      <ProtectedRoutes path="/admin/booker-confirmation-page" component={BookingConfirmationPage} />
      <ProtectedRoutes
        path="/admin/brand-help-management"
        component={BrandHelpHomePage}
      />

      {/* hotel start*/}
      <ProtectedRoutes
        path="/admin/hotel-inventory-management"
        component={HotelInventoryHome}
      />
      {/* hotel end*/}

      {/* report start*/}
      <ProtectedRoutes
        path="/admin/programme-spend-data"
        component={ProgrammeSpendDataHome}
      />

      <ProtectedRoutes
        path="/admin/invite-selection"
        component={InviteSelectionForm}
      />

      <ProtectedRoutes
        path="/admin/location-overview-data"
        component={LocationOverviewDataHome}
      />

      <ProtectedRoutes
        path="/admin/contracted-property-data"
        component={ContractedPropertyDataHome}
      />
      <ProtectedRoutes
        path="/admin/chain-overview-data"
        component={ChainDataHome}
      />

      {/* report end*/}

      {/* protected routes */}

      {/* error route */}
      <Route path="*" component={NotFound} />
    </Switch>
  </>
);

export default Router;
