import { BrowserRouter } from 'react-router-dom';
import Router from './routes/Router';
import { useEffect } from 'react';

const App: React.FC = (): JSX.Element => {


  useEffect(() => {

    const ignoreScroll = [
      '/admin/contracted-property-data',
      '/admin/location-overview-data',
      '/admin/chain-overview-data'
    ];

    if (ignoreScroll.includes(window.location.pathname)) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, []);

  return (
    <BrowserRouter>
      <Router />
    </BrowserRouter>
  );
}

export default App;
