import { Select } from 'antd';
import { useStoreActions, useStoreState } from 'easy-peasy';
import React, { useCallback, useEffect, useState } from 'react';
import { Form, Row, Col, Button, Spinner } from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { ProgrammeStatus, UserGroups } from '../../../../common/constants';
import { formatPhoneNumber, getLoggedUserType } from '../../../../common/functions';
import { ProgramCreateForm } from '../../../../common/interfaces';
import { toast } from 'react-toastify';
import { formButton } from '../../../../common/components-style';
import { PhoneNumberUtil } from 'google-libphonenumber';
import { setStorage } from '../../../../common/storage';
import _ from 'lodash';
import domain from '../../../../common/genericDomain.json';
const { Option } = Select;

const ProgrammeDetailsForm: React.FC<any> = ({ programmeId, selectedClient, loadData, setSelectedProgramme }): JSX.Element => {
	const { register, handleSubmit, formState: { errors }, control, setValue } = useForm<ProgramCreateForm>();
	const [programData, setProgramData] = useState<any>();
	const [editEnable, setEditEnable] = useState<boolean>(false);
	const [phone, setPhone] = useState<string>();
	const [mysaPhone, setMysaPhone] = useState<string>();
	const [loading, setLoading] = useState<boolean>(false);
	const [programmeTypeList, setProgrammeTypeList] = useState<any>();
	const [validPhoneNumber, setValidPhoneNumber] = useState<boolean>(false);
	const [ownerList, setOwnerList] = useState<any>();
	const [accommodationTypeList, setAccommodationTypeList] = useState<any>([]);
	const [accommodationName, setAccommodationName] = useState<string>('');
	const [accommodationTypeId, setAccommodationTypeId] = useState<string>('');
	const [programmeLevelList, setProgrammeLevelList] = useState<any>([]);
	const [optimizeProductList, setOptimizeProductList] = useState<any>([]);

	////////////////////////////////////////////////////////////////////////////////////////////////////
	const { onGetPartnerList, getClientList, programmeApproval, resetClient, createCorporateProgramme, getProgrammeType, getCorporateProgrammeById, updateCorporateProgramme, } = useStoreActions<any>((actions) => ({
		getProgrammeType: actions.client.getProgrammeType,
		getClientList: actions.client.getClientList,
		createCorporateProgramme: actions.client.createCorporateProgramme,
		getCorporateProgrammeById: actions.client.getCorporateProgrammeById,
		updateCorporateProgramme: actions.client.updateCorporateProgramme,
		resetClient: actions.client.resetClient,
		programmeApproval: actions.client.programmeApproval,
		onGetPartnerList: actions.partner.onGetPartnerList,

	}));

	////////////////////////////////////////////////////////////////////////////////////////////////////
	const {

		getProgrammeTypeSuccess,
		createCorporateProgrammeSuccess,
		createCorporateProgrammeError,
		getCorporateProgrammeByIdSuccess,
		updateCorporateProgrammeSuccess,
		updateCorporateProgrammeError,
		programmeApprovalSuccess,
		programmeApprovalError,
		partnerListSuccess,


	} = useStoreState<any>((state) => ({

		getProgrammeTypeSuccess: state.client.getProgrammeTypeSuccess,
		createCorporateProgrammeSuccess: state.client.createCorporateProgrammeSuccess,
		createCorporateProgrammeError: state.client.createCorporateProgrammeError,
		getCorporateProgrammeByIdSuccess: state.client.getCorporateProgrammeByIdSuccess,
		updateCorporateProgrammeSuccess: state.client.updateCorporateProgrammeSuccess,
		updateCorporateProgrammeError: state.client.updateCorporateProgrammeError,
		programmeApprovalSuccess: state.client.programmeApprovalSuccess,
		programmeApprovalError: state.client.programmeApprovalError,
		partnerListSuccess: state.partner.partnerListSuccess,

	}));


	////////////////////////////////////////////////////////////////////////////////////////////////////
	useEffect(() => {
		getClientList();
		getProgrammeType();
		onGetPartnerList();
		if (selectedClient) {
			setValue('clientId', selectedClient?.key);
			setValue('companyName', selectedClient?.companyName);
			// setValue('programmeType', '');
			// setValue('bookerDomains', '');
			// setValue('companyDomains', '');
		}
	}, [getClientList, getProgrammeType, onGetPartnerList, selectedClient, setValue]);

	////////////////////////////////////////////////////////////////////////////////////////////////////
	useEffect(() => {
		if (programmeId) {
			getCorporateProgrammeById(programmeId);
		}
	}, [getCorporateProgrammeById, programmeId]);

	////////////////////////////////////////////////////////////////////////////////////////////////////
	useEffect(() => {

		if (getProgrammeTypeSuccess) {
			setProgrammeTypeList(getProgrammeTypeSuccess.data);
		}
	}, [getProgrammeTypeSuccess]);

	////////////////////////////////////////////////////////////////////////////////////////////////////
	useEffect(() => {
		if (getCorporateProgrammeByIdSuccess) {
			const {
				programmeName,
				programmeType,
				clientContact,
				email,
				telephone,
				companyDomains,
				mysaOwner,
				mysaOwnerEmail,
				mysaOwnerTelephone,
				clientId,
				bookerDomains,
				programmeOwner,
				abbreviation
			} = getCorporateProgrammeByIdSuccess.data;

			setProgramData(getCorporateProgrammeByIdSuccess?.data);
			setValue('programmeName', programmeName);
			setValue('programmeType', programmeType?._id);
			setValue('clientContact', clientContact);
			setValue('email', email);
			setValue('telephone', telephone);
			setValue('companyDomains', companyDomains);
			setValue('mysaOwner', mysaOwner);
			setValue('mysaOwnerEmail', mysaOwnerEmail);
			setValue('mysaOwnerTelephone', mysaOwnerTelephone);
			setValue('clientId', clientId?._id);
			setValue('companyName', clientId?.companyName);
			setValue('bookerDomains', bookerDomains);
			setValue('programmeOwner', programmeOwner);
			setValue('abbreviation', abbreviation);
			setStorage('myo_selected_programme', getCorporateProgrammeByIdSuccess?.data);
			setAccommodationTypeId(getCorporateProgrammeByIdSuccess?.data?.accommodationType);
		}

	}, [getCorporateProgrammeByIdSuccess, setValue]);


	const scrollToTop = () => {
		// messagesRef?.current?.scrollIntoView(({ behavior: "smooth", block: "start", inline: "start" }));
		window.scrollTo({ top: 0, behavior: 'smooth' });
	};
	////////////////////////////////////////////////////////////////////////////////////////////////////
	useEffect(() => {

		if (programmeApprovalError) {
			setLoading(false);
			toast.error(programmeApprovalError?.message, {
				position: toast.POSITION.BOTTOM_RIGHT,
				className: 'foo-bar'
			});
			resetClient();
			getCorporateProgrammeById(programmeId);
			setEditEnable(false);

		}


		if (createCorporateProgrammeSuccess) {
			loadData(createCorporateProgrammeSuccess.data._id);
			setSelectedProgramme(createCorporateProgrammeSuccess.data);
			setLoading(false);
			toast.success("Corporate programme created successfully", {
				position: toast.POSITION.BOTTOM_RIGHT,
				className: 'foo-bar'
			});
			resetClient();
			setEditEnable(false);
			scrollToTop();
			getCorporateProgrammeById(createCorporateProgrammeSuccess?.data?._id);


		}
		if (createCorporateProgrammeError) {
			setLoading(false);
			toast.error(createCorporateProgrammeError.message, {
				position: toast.POSITION.BOTTOM_RIGHT,
				className: 'foo-bar'
			});
			resetClient();
			setEditEnable(false);
			getCorporateProgrammeById(programmeId);

		}
		if (updateCorporateProgrammeSuccess) {
			setSelectedProgramme(updateCorporateProgrammeSuccess.data);
			setLoading(false);
			toast.success("Corporate programme updated successfully", {
				position: toast.POSITION.BOTTOM_RIGHT,
				className: 'foo-bar'
			});
			resetClient();
			setEditEnable(false);
			scrollToTop();
			getCorporateProgrammeById(updateCorporateProgrammeSuccess?.data?._id);
		}
		if (updateCorporateProgrammeError) {
			setLoading(false);
			toast.error(updateCorporateProgrammeError.message, {
				position: toast.POSITION.BOTTOM_RIGHT,
				className: 'foo-bar'
			});
			resetClient();
			setEditEnable(false);
			getCorporateProgrammeById(programmeId);
		}

		if (programmeApprovalSuccess) {
			setLoading(false);
			getCorporateProgrammeById(programmeId);
			toast.success("Programme approved successfully", {
				position: toast.POSITION.BOTTOM_RIGHT,
				className: 'foo-bar'
			});
			resetClient();
			setEditEnable(false);
			if (programmeId) {
				const value = {
					id: programmeId,
					isActivated: false
				}
				updateCorporateProgramme(value);
			}
			getCorporateProgrammeById(programmeId);
		}

	}, [createCorporateProgrammeError, createCorporateProgrammeSuccess, getCorporateProgrammeById, loadData, programmeApprovalError, programmeApprovalSuccess, programmeId, resetClient, setSelectedProgramme, updateCorporateProgramme, updateCorporateProgrammeError, updateCorporateProgrammeSuccess]);

	////////////////////////////////////////////////////////////////////////////////////////////////////
	// const renderClientList = useCallback(() => {

	// 	if (getClientListSuccess) {
	// 		return getClientListSuccess.data.map((client: any, key: number) => {
	// 			return (
	// 				<option key={key} value={`${client._id}`} >{client.companyName}</option>
	// 			)
	// 		});
	// 	}

	// }, [getClientListSuccess]);

	////////////////////////////////////////////////////////////////////////////////////////////////////
	const renderProgrammeType = useCallback(() => {
		if (programmeTypeList) {
			return programmeTypeList.map((val: any, key: number) => {
				return (
					<Option key={key} data={val} value={`${val._id}`} >{val.name}</Option>
				)
			});
		}

	}, [programmeTypeList]);


	//////////////////////////////////////////////////////////////////////////////////////////////////
	useEffect(() => {
		// if (partnerListSuccess) {
		const locationListTemp: any = [{
			_id: 'primary',
			name: "Primary",
		}, {
			_id: 'secondary',
			name: "Secondary",
		}];



		const typeOfPartner = locationListTemp.map((val: any, key: number) => {
			return (
				<Option key={key} data={val} value={`${val._id}`} >{val.name}</Option>
			)
		});

		setProgrammeLevelList(typeOfPartner);
		// }

	}, []);



	useEffect(() => {
		// if (partnerListSuccess) {
		const locationListTemp: any = [{
			_id: 'Full Programme Management',
			name: "Full Programme Management",
		},
		{
			_id: 'Data Analysis Management',
			name: "Data Analysis Management",
		},
		{
			_id: 'Rfp Management',
			name: "Rfp Management",
		}
		];

		const typeOfPartner = locationListTemp.map((val: any, key: number) => {
			return (
				<Option key={key} data={val} value={`${val._id}`} >{val.name}</Option>
			)
		});

		setOptimizeProductList(typeOfPartner);
		// }

	}, []);






	//////////////// approval part ////////
	const onApprovedProgramme = () => {
		setLoading(true);
		const payload = {
			programmeId: programmeId
		}
		programmeApproval(payload);
	};

	// const validatePhoneNumber = (
	// 	inputNumber: string,
	// 	country: any,
	// 	isDirty: boolean,
	// 	phoneLength: number
	// ) => {
	// 	if (isDirty) {
	// 		if (inputNumber && inputNumber?.replace(country.dialCode, '')?.trim() === '') {
	// 			setValidPhoneNumber(false);
	// 			return false;

	// 		} else if (inputNumber.length < phoneLength) {
	// 			setValidPhoneNumber(false);
	// 			return false;
	// 		}
	// 		setValidPhoneNumber(true);
	// 		return true;
	// 	}
	// 	setValidPhoneNumber(false);
	// 	return false;

	// };

	const validatePhoneNumber = useCallback((inputNumber: string) => {
		let number = formatPhoneNumber(inputNumber)

		let valid = false;
		let isPossible = false;
		try {
			const phoneUtil = PhoneNumberUtil.getInstance();
			isPossible = phoneUtil.isPossibleNumber(phoneUtil.parse(number))
			valid = phoneUtil.isValidNumber(phoneUtil.parse(number));
		} catch (e) {
			valid = false;
		}

		if (isPossible && valid) {
			setValidPhoneNumber(true);
			// validPhoneNumber = true;
			return true;
		} else {
			setValidPhoneNumber(false);
			return false;
		}
	}, [])

	////////////////////////////////////////////////////////////////////////////////////////////////////
	const onFinish = useCallback((values) => {
		setLoading(true);
		const domainList = domain;
		const isAllowed = values?.companyDomains.some(domain => domainList.includes(domain));

		if (isAllowed) {
			setLoading(false);
			toast.error("Generic domains like Gmail, Hotmail, Yahoo, etc., are not allowed.", {
				position: toast.POSITION.BOTTOM_RIGHT,
				className: "foo-bar",
			});
			return;
		}

		const isAllowedBooker = values?.bookerDomains.some(domain => domainList.includes(domain));

		if (isAllowedBooker) {
			setLoading(false);
			toast.error("Generic domains like Gmail, Hotmail, Yahoo, etc., are not allowed.", {
				position: toast.POSITION.BOTTOM_RIGHT,
				className: "foo-bar",
			});
			return;
		}
		if (!validPhoneNumber) {
			setLoading(false);
			toast.error('Please enter valid phone number!', {
				position: toast.POSITION.BOTTOM_RIGHT,
				className: 'foo-bar'
			});
			return;
		}
		delete values.companyName;
		if (values.accommodationType === 'hotel') {
			values.programmeType = null
		}
		if (programmeId) {

			if (!validatePhoneNumber(values?.telephone)) {
				setLoading(false);
				toast.error('Please enter valid phone number!', {
					position: toast.POSITION.BOTTOM_RIGHT,
					className: 'foo-bar'
				});
				return;
			}

			if (!validatePhoneNumber(values?.mysaOwnerTelephone)) {
				setLoading(false);
				toast.error('Please enter valid phone number!', {
					position: toast.POSITION.BOTTOM_RIGHT,
					className: 'foo-bar'
				});
				return;
			}
			delete values.clientId;
			values.id = programmeId;
			if (phone) {
				values.telephone = phone;
			}

			if (mysaPhone) {
				values.mysaOwnerTelephone = mysaPhone;
			}

			updateCorporateProgramme(values);
		} else {
			values.telephone = phone;
			values.mysaOwnerTelephone = mysaPhone;
			createCorporateProgramme(values);
		}

	}, [createCorporateProgramme, mysaPhone, phone, programmeId, updateCorporateProgramme, validPhoneNumber, validatePhoneNumber]);


	//////////////////////////////////////////////////////////////////////////////////////////////////
	useEffect(() => {
		if (partnerListSuccess) {

			const list = partnerListSuccess?.data.filter((user) => user.partnerType.name !== 'Parent Accommodation Company');
			const locationListTemp: any = [];
			const data = {
				_id: 1,
				name: "Mysa",
			}
			locationListTemp.push(data);
			_.map(list, (element) => locationListTemp.push({ _id: element._id, name: element.name }));
			const typeOfPartner = locationListTemp.map((val: any, key: number) => {
				return (
					<Option key={key} data={val} value={`${val.name}`} >{val.name}</Option>
				)
			});

			setOwnerList(typeOfPartner);
		}

	}, [partnerListSuccess]);

	//////////////////////////////////////////////////////////////////////////////////////////////////
	useEffect(() => {
		// if (partnerListSuccess) {
		const locationListTemp: any = [{
			_id: 'hotel',
			name: "Hotel",
		},
		{
			_id: 'serviced_apartments',
			name: "Serviced Accommodation",
		},
		{
			_id: 'both',
			name: "Both",
		},
		];

		const typeOfPartner = locationListTemp.map((val: any, key: number) => {
			return (
				<Option key={key} data={val} value={`${val._id}`} >{val.name}</Option>
			)
		});

		setAccommodationTypeList(typeOfPartner);
		// }

	}, []);

	//////////////////////////////////////////////////////////////////////////////////////////////////
	useEffect(() => {
		if (programData?.accommodationType === 'serviced_apartments') {
			setAccommodationName('Serviced Accommodation');
		} else if (programData?.accommodationType === 'hotel') {
			setAccommodationName('Hotel');
		} else if (programData?.accommodationType === 'both') {
			setAccommodationName('Both');
		}
	}, [programData?.accommodationType]);

	//////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	return (
		<>
			<div className="ml-auto edit_btn_wpr" >
				{
					!loading && !editEnable && getLoggedUserType() === UserGroups.super_admin && <Button id='edit-button' style={formButton} onClick={() => { setEditEnable(true) }} >Edit</Button>
				}
			</div>
			<div>
				<div className="jumbotron" >
					<Form onSubmit={handleSubmit(onFinish)}>
						<Row>
							<Col md={1}></Col>
							<Col md={10}>
								<Row className='formSpace'>
									<Col md={3} style={{ textAlign: 'right' }}>
										<Form.Label>{editEnable && <span className='requiredMark'>*</span>} Company name</Form.Label>
									</Col>
									<Col md={9} style={{ textAlign: 'left' }}>
										{!editEnable && <p className="lead">{selectedClient?.companyName}</p>}
										{editEnable &&
											// <Controller
											// 	name="clientId"
											// 	control={control}
											// 	rules={{
											// 		required: true
											// 	}}
											// 	render={({ field }) =>
											// 		<Form.Select value={String(programData?.clientId?._id)} onChange={(value) => field.onChange(value)} disabled={selectedClient}>
											// 			{renderClientList()}
											// 		</Form.Select>
											// 	}
											// />
											<Form.Control className={`${errors.clientId ? 'is-invalid' : ''}`} {...register("companyName", { required: true })} disabled={true} />
										}
										{editEnable && errors.clientId && <div className="invalid-feedback-custom">Please select a companyName!</div>}
									</Col>
								</Row>
								<Row className='formSpace'>
									<Col md={3} style={{ textAlign: 'right' }}>
										<Form.Label>{editEnable && <span className='requiredMark'>*</span>} Name of programme</Form.Label>
									</Col>
									<Col md={9} style={{ textAlign: 'left' }}>
										{!editEnable && <p className="lead">{programData?.programmeName}</p>}
										{editEnable && <Form.Control className={`${errors.programmeName ? 'is-invalid' : ''}`} {...register("programmeName", { required: true })} />}
										{editEnable && <div className="invalid-feedback">Please input programme name!</div>}
									</Col>
								</Row>

								<Row className='formSpace'>
									<Col md={3} style={{ textAlign: 'right' }}>
										<Form.Label>{editEnable && <span className='requiredMark'>*</span>}Programme abbreviation</Form.Label>
									</Col>
									<Col md={9} style={{ textAlign: 'left' }}>
										{!editEnable && <p className="lead">{programData?.abbreviation}</p>}
										{editEnable && <Form.Control className={`${errors.abbreviation ? 'is-invalid' : ''}`} {...register("abbreviation", { required: true })} />}
										{editEnable && <div className="invalid-feedback">Please input abbreviation name!</div>}
									</Col>
								</Row>

								<Row className='formSpace'>
									<Col md={3} style={{ textAlign: 'right' }}>
										<Form.Label>{editEnable && <span className='requiredMark'>*</span>} Optimise product requirement</Form.Label>
									</Col>
									<Col md={9} style={{ textAlign: 'left' }}>
										{!editEnable && <p className="lead">{programData?.optimiseProductRequirement}</p>}
										{editEnable &&
											<Controller
												name="optimiseProductRequirement"
												defaultValue={programData?.optimiseProductRequirement}
												control={control}
												rules={{
													required: true,
												}}
												render={({ field }) =>
													<Select id="optimiseProductRequirement" defaultValue={programData?.optimiseProductRequirement} className={`${errors.optimiseProductRequirement ? 'is-invalid' : ''}`} style={{ width: '100%' }}
														onChange={(value, option) => {
															field.onChange(value)
														}}
														// onSelect={onTypeSelect}
														showSearch>
														{optimizeProductList}
													</Select>
												}
											/>}
										{editEnable && <div className="invalid-feedback">Please select a programme Level!</div>}
									</Col>
								</Row>

								<Row className='formSpace'>
									<Col md={3} style={{ textAlign: 'right' }}>
										<Form.Label>{editEnable && <span className='requiredMark'>*</span>} Accommodation type</Form.Label>
									</Col>
									<Col md={9} style={{ textAlign: 'left' }}>
										{!editEnable && <p className="lead">{accommodationName}</p>}
										{(editEnable) &&
											<Controller
												name="accommodationType"
												defaultValue={programData?.accommodationType}
												control={control}
												rules={{
													required: true,
												}}

												render={({ field }) =>
													<Select id="programmeOfAccommodationType" disabled={

														getLoggedUserType() !== UserGroups.super_admin
															? true
															: false
													}
														defaultValue={programData?.accommodationType} className={`${errors.accommodationType ? 'is-invalid' : ''}`} style={{ width: '100%' }}
														onChange={(value, option) => {
															field.onChange(value)
															setAccommodationTypeId(value);
														}}
														// onSelect={onTypeSelect}
														showSearch>
														{accommodationTypeList}
													</Select>
												}

											/>}
										{editEnable && <div className="invalid-feedback">Please select a accommodation type!</div>}
									</Col>
								</Row>
								<Row className='formSpace'>
									<Col md={3} style={{ textAlign: 'right' }}>
										<Form.Label>{editEnable && <span className='requiredMark'>*</span>} Programme level</Form.Label>
									</Col>
									<Col md={9} style={{ textAlign: 'left' }}>
										{!editEnable && <p className="lead">{programData?.programmeLevel}</p>}
										{editEnable &&
											<Controller
												name="programmeLevel"
												defaultValue={programData?.programmeLevel}
												control={control}
												rules={{
													required: true,
												}}
												render={({ field }) =>
													<Select id="programmeOfProgrammeLevel" defaultValue={programData?.programmeLevel} className={`${errors.programmeLevel ? 'is-invalid' : ''}`} style={{ width: '100%' }}
														onChange={(value, option) => {
															field.onChange(value)
														}}
														// onSelect={onTypeSelect}
														showSearch>
														{programmeLevelList}
													</Select>
												}
											/>}
										{editEnable && <div className="invalid-feedback">Please select a programmeLevel!</div>}
									</Col>
								</Row>
								{((accommodationTypeId === 'both') || (accommodationTypeId === 'serviced_apartments')) && <Row className='formSpace'>
									<Col md={3} style={{ textAlign: 'right' }}>
										<Form.Label>{editEnable && <span className='requiredMark'>*</span>} Programme type</Form.Label>
									</Col>
									<Col md={9} style={{ textAlign: 'left' }}>
										{!editEnable && <p className="lead">{programData?.programmeType?.name}</p>}
										{editEnable &&
											<Controller
												name="programmeType"
												control={control}
												rules={{
													required: true
												}}
												render={({ field }) =>

													<Select id="programmeOfProgrammeType" defaultValue={String(field.value)} onChange={(value) => field.onChange(value)} style={{ width: '100%' }}>
														{renderProgrammeType()}
													</Select>
												}
											/>}
										{editEnable && errors.clientId && <div className="invalid-feedback-custom">Please select a programme type!</div>}
									</Col>
								</Row>}
								<Row className='formSpace'>
									<Col md={3} style={{ textAlign: 'right' }}>
										<Form.Label>{editEnable && <span className='requiredMark'>*</span>}Chief Programme Admin </Form.Label>
									</Col>
									<Col md={9} style={{ textAlign: 'left' }}>
										{!editEnable && <p className="lead">{programData?.clientContact}</p>}
										{editEnable && <Form.Control className={`${errors.clientContact ? 'is-invalid' : ''}`} {...register("clientContact", { required: true })} />}
										{editEnable && <div className="invalid-feedback">Please input name!</div>}
									</Col>
								</Row>
								<Row className='formSpace'>
									<Col md={3} style={{ textAlign: 'right' }}>
										<Form.Label>{editEnable && <span className='requiredMark'>*</span>} Email address</Form.Label>
									</Col>
									<Col md={9} style={{ textAlign: 'left' }}>
										{!editEnable && <p className="lead">{programData?.email}</p>}
										{editEnable && <Form.Control type='email' className={`${errors.email ? 'is-invalid' : ''}`} {...register("email", { required: true })} />}
										{editEnable && <div className="invalid-feedback">Please input valid email!</div>}
									</Col>
								</Row>
								<Row className='formSpace'>
									<Col md={3} style={{ textAlign: 'right' }}>
										<Form.Label>{editEnable && <span className='requiredMark'>*</span>} Telephone no</Form.Label>
									</Col>
									<Col md={9} style={{ textAlign: 'left' }}>
										{!editEnable && programData?.telephone && <p className="lead">{formatPhoneNumber(programData?.telephone)}</p>}
										{editEnable &&
											<Controller
												name="telephone"
												control={control}
												defaultValue={programData?.telephone}
												rules={{
													required: true,
													// minLength: {
													// 	value: length,
													// 	message: "Minimum length is " + length + "."
													// },
													// validate: () => {
													// 	if (validPhoneNumber) {
													// 		return true;
													// 	}
													// 	toast.error('Please enter valid phone number!', {
													// 		position: toast.POSITION.BOTTOM_RIGHT,
													// 		className: 'foo-bar'
													// 	});
													// 	return;
													// }
												}}
												render={({ field }) =>
													<PhoneInput
														inputProps={{ id: "programmeOfTelephone" }}
														placeholder=''
														isValid={(inputNumber: string) => {

															return validatePhoneNumber(
																inputNumber
															);
														}}

														containerClass={`${errors.telephone ? 'is-invalid' : ''}`} value={String(field.value)} country='gb' onChange={(phone) => { field.onChange(phone); setPhone(phone); }} />}
											/>
										}
										{editEnable && <div className="invalid-feedback">Please input telephone no!</div>}
									</Col>
								</Row>
								<Row className='formSpace'>
									<Col md={3} style={{ textAlign: 'right' }}>
										<Form.Label>{editEnable && <span className='requiredMark'>*</span>} Company domains</Form.Label>
									</Col>
									<Col md={9} style={{ textAlign: 'left' }}>
										{!editEnable && <p className="lead">
											{
												programData?.companyDomains?.map((value, key: number) => {
													return (
														<span key={key}>{value}, </span>
													);
												})
											}
										</p>}
										{editEnable &&
											<Controller
												name="companyDomains"
												defaultValue={programData?.companyDomains}
												control={control}
												rules={{
													required: true,
													pattern: {
														value: /((ftp|http|https):\/\/)?([a-z0-9]+\.)?([a-z0-9][a-z0-9-]*)?((\.[a-z]{2,6})|(\.[a-z]{2,6})(\.[a-z]{2,6}))$/,
														message: "Please enter valid domain name"
													}
												}}
												render={({ field }) =>
													<Select id="programmeOfCompanyDomains" defaultValue={programData?.companyDomains} onChange={(value) => field.onChange(value)} mode="tags" className={`${errors.companyDomains ? 'is-invalid' : ''}`} options={[]} style={{ width: '100%' }}  >
													</Select>
												}
											/>}
										{editEnable && <div className="invalid-feedback">Please enter company domain!</div>}
									</Col>
								</Row>

								{(accommodationTypeId === 'both' || accommodationTypeId === 'serviced_apartments') && <Row className='formSpace'>
									<Col md={3} style={{ textAlign: 'right' }}>
										<Form.Label>{editEnable && <span className='requiredMark'>*</span>}  Booker domains</Form.Label>
									</Col>
									<Col md={9} style={{ textAlign: 'left' }}>
										{!editEnable && <p className="lead">
											{
												programData?.bookerDomains?.map((value, key: number) => {
													return (
														<span>{value}, </span>
													);
												})
											}
										</p>}
										{editEnable &&
											<Controller
												name="bookerDomains"
												defaultValue={programData?.bookerDomains}
												control={control}
												rules={{
													required: true,
													pattern: {
														value: /((ftp|http|https):\/\/)?([a-z0-9]+\.)?([a-z0-9][a-z0-9-]*)?((\.[a-z]{2,6})|(\.[a-z]{2,6})(\.[a-z]{2,6}))$/,
														message: "Please enter valid domain name"
													}
												}}
												render={({ field }) =>
													<Select id="programmeOfBookerDomains" defaultValue={programData?.bookerDomains} onChange={(value) => field.onChange(value)} mode="tags" className={`${errors.bookerDomains ? 'is-invalid' : ''}`} options={[]} style={{ width: '100%' }}  >
													</Select>
												}
											/>}
										{editEnable && <div className="invalid-feedback">Please enter booker domain!</div>}
									</Col>
								</Row>}
								<Row className='formSpace'>
									<Col md={3} style={{ textAlign: 'right' }}>
										<Form.Label>{editEnable && <span className='requiredMark'>*</span>} Programme owner</Form.Label>
									</Col>
									<Col md={9} style={{ textAlign: 'left' }}>
										{!editEnable && <p className="lead">{programData?.programmeOwner}</p>}
										{editEnable &&
											<Controller
												name="programmeOwner"
												defaultValue={programData?.programmeOwner}
												control={control}
												rules={{
													required: true,
												}}
												render={({ field }) =>
													<Select id="programmeOfProgrammeOwner" defaultValue={programData?.programmeOwner} className={`${errors.programmeOwner ? 'is-invalid' : ''}`} style={{ width: '100%' }}
														onChange={(value, option) => {
															field.onChange(value)
														}}
														// onSelect={onTypeSelect}
														showSearch>
														{ownerList}
													</Select>
												}
											/>}
										{editEnable && <div className="invalid-feedback">Please select a partner type!</div>}
									</Col>
								</Row>
								<Row className='formSpace'>
									<Col md={3} style={{ textAlign: 'right' }}>
										<Form.Label>{editEnable && <span className='requiredMark'>*</span>} Individual name</Form.Label>
									</Col>
									<Col md={9} style={{ textAlign: 'left' }}>
										{!editEnable && <p className="lead">{programData?.mysaOwner}</p>}
										{editEnable && <Form.Control className={`${errors.mysaOwner ? 'is-invalid' : ''}`} {...register("mysaOwner", { required: true })} />}
										{editEnable && <div className="invalid-feedback">Please input owner name!</div>}
									</Col>
								</Row>
								<Row className='formSpace'>
									<Col md={3} style={{ textAlign: 'right' }}>
										<Form.Label>{editEnable && <span className='requiredMark'>*</span>} Email address</Form.Label>
									</Col>
									<Col md={9} style={{ textAlign: 'left' }}>
										{!editEnable && <p className="lead">{programData?.mysaOwnerEmail}</p>}
										{editEnable && <Form.Control type='email' className={`${errors.mysaOwnerEmail ? 'is-invalid' : ''}`} {...register("mysaOwnerEmail", { required: true })} />}
										{editEnable && <div className="invalid-feedback">Please input valid email!</div>}
									</Col>
								</Row>
								<Row className='formSpace'>
									<Col md={3} style={{ textAlign: 'right' }}>
										<Form.Label>{editEnable && <span className='requiredMark'>*</span>} Telephone no</Form.Label>
									</Col>
									<Col md={9} style={{ textAlign: 'left' }}>
										{!editEnable && programData?.mysaOwnerTelephone && <p className="lead">{formatPhoneNumber(programData?.mysaOwnerTelephone)}</p>}
										{editEnable &&
											<Controller
												name="mysaOwnerTelephone"
												control={control}
												defaultValue={programData?.mysaOwnerTelephone}
												rules={{
													required: true,
													// minLength: {
													// 	value: length,
													// 	message: "Minimum length is " + length + "."
													// },
													// validate: () => {
													// 	if (validPhoneNumber) {
													// 		return true;
													// 	}
													// 	toast.error('Please enter valid phone number!', {
													// 		position: toast.POSITION.BOTTOM_RIGHT,
													// 		className: 'foo-bar'
													// 	});
													// 	return;
													// }
												}}
												render={({ field }) =>
													<PhoneInput
														inputProps={{ id: "programmeOfMysaOwnerTelephone" }}
														placeholder=''
														isValid={(inputNumber: string) => {

															return validatePhoneNumber(
																inputNumber
															);
														}}

														containerClass={`${errors.telephone ? 'is-invalid' : ''}`} value={String(field.value)} country='gb' onChange={(phone) => { field.onChange(phone); setMysaPhone(phone); }} />}
											/>
										}
										{editEnable && <div className="invalid-feedback">Please input telephone no!</div>}
									</Col>
								</Row>
								<Row className='formSpace'>
									<Col md={3} style={{ textAlign: 'right' }}>
									</Col>
									<Col md={9} style={{ textAlign: 'left' }}>

										{!loading && !editEnable && programmeId && getLoggedUserType() === UserGroups.super_admin && programData?.programmeStatus === ProgrammeStatus.PENDING &&
											<Button id='approve-button' style={formButton} onClick={() => { onApprovedProgramme() }}>Approve</Button>
										}
										{!loading && editEnable && getLoggedUserType() === UserGroups.super_admin &&
											<Button type="submit" id='approve-button' style={formButton} > {programmeId ? 'Update' : 'Create'}</Button>
										}

										{
											loading && <div style={{ textAlign: 'center' }}><Spinner animation="border" variant="warning" /></div>
										}
									</Col>
								</Row>
							</Col>
							<Col md={1}></Col>
						</Row>
					</Form>
				</div>
			</div>
		</>
	)
};

export default ProgrammeDetailsForm;