import { FC, useState, useEffect } from "react";
import { useStoreActions, useStoreState } from 'easy-peasy';
import DataTable from "react-data-table-component";
import { overlayTriggerDelay, selectStylesLittleRect, tableStyles } from "../../../../common/components-style";
import RFPBreadCrumbPage from "../../../common/RFPBredcrumPage";
import { Row, Col, Spinner, OverlayTrigger, Tooltip } from 'react-bootstrap';
import Select from 'react-select';
import StatusIcon from '../../../../images/icons/svg/correct.svg';

interface ProgrammeOption {
    value: string;
    label: string;
}

const ChainOverviewData: FC<any> = ({ reportId, programmeId, setFormOpen, reload, selectedClient, selectedProgramme, accommodationType, reportName, currency, associateProgrammes, location }): JSX.Element => {
    const [data, setData] = useState<Array<any>>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [programmeList, setProgrammeList] = useState<any>([]);
    const [selectedTier, setSelectedTier] = useState<string | null>(null);
    const [filteredData, setFilteredData] = useState<any[]>([]);
    const [currencyName, setCurrencyName] = useState<any>(null);
    const [selectedAssociateProgramme, setSelectedAssociateProgramme] = useState<ProgrammeOption | null>(null);
    const [chain, setChain] = useState<any>(null);
    const [summaryData, setSummaryData] = useState<any>({});
    const [selectedBrand, setSelectedBrand] = useState<string | null>(null);

    const { getReportDataChainOverview, getReportById, getReportDataBrandOverviewForParticularBrand } = useStoreActions<any>((actions) => ({
        getReportById: actions.booking.getReportById,
        getReportDataChainOverview: actions.hotel.getReportDataChainOverview,
        getReportDataBrandOverviewForParticularBrand: actions.hotel.getReportDataBrandOverviewForParticularBrand
    }));

    /**
     * global state
     */
    const { getReportByIdError, getReportByIdSuccess, getReportDataChainOverviewSuccess, getReportDataBrandOverviewForParticularBrandSuccess } = useStoreState<any>((state) => ({
        getReportByIdError: state.booking.getReportByIdError,
        getReportByIdSuccess: state.booking.getReportByIdSuccess,
        getReportDataChainOverviewSuccess: state.hotel.getReportDataChainOverviewSuccess,
        getReportDataBrandOverviewForParticularBrandSuccess: state.hotel.getReportDataBrandOverviewForParticularBrandSuccess,

    }));
    useEffect(() => {
        if (location?.state) {
            setChain(location?.state?.selectedChain);
            setSelectedBrand(location?.state?.selectedBrand);
        }
    }, [location?.state]);

    useEffect(() => {
        if (getReportByIdSuccess?.data) {
            setCurrencyName(getReportByIdSuccess?.data?.currency);
        }
    }, [getReportByIdError, getReportByIdSuccess?.data]);

    useEffect(() => {
        if (reportId && chain) {
            setLoading(true);
            const reportPayload = { _id: reportId };
            getReportById(reportPayload);
            const payload = {
                reportId: reportId,
                programmeId: selectedAssociateProgramme ? selectedAssociateProgramme?.value : '',
                parentAccommodationCompany: chain,
                brandId: selectedBrand
            };
            if (selectedBrand) {
                getReportDataBrandOverviewForParticularBrand(payload)
            }
            else {
                getReportDataChainOverview(payload)

            }
        }
    }, [chain, getReportById, getReportDataBrandOverviewForParticularBrand, getReportDataChainOverview, reportId, selectedAssociateProgramme, selectedBrand]);


    useEffect(() => {
        if (getReportDataChainOverviewSuccess?.data) {
            setLoading(false);
            setData(getReportDataChainOverviewSuccess?.data?.data);
            setSummaryData(getReportDataChainOverviewSuccess?.data?.summary);
            setFilteredData(getReportDataChainOverviewSuccess?.data?.data);
        }
    }, [getReportDataChainOverviewSuccess]);


    useEffect(() => {
        if (getReportDataBrandOverviewForParticularBrandSuccess?.data) {
            setLoading(false);
            setData(getReportDataBrandOverviewForParticularBrandSuccess?.data?.data);
            setSummaryData(getReportDataBrandOverviewForParticularBrandSuccess?.data?.summary);
            setFilteredData(getReportDataBrandOverviewForParticularBrandSuccess?.data?.data);
        }
    }, [getReportDataBrandOverviewForParticularBrandSuccess]);

    const formatPercentageValue = (value: number | undefined): string => {
        if (value !== undefined) {
            if (value === 0 || value === 0.0) {
                return '0';
            }
            const roundedValue = Math.round(value * 10) / 10;
            if (roundedValue === 0 || roundedValue === 0.0) {
                return '0';
            }
            return roundedValue.toLocaleString(undefined, {
                minimumFractionDigits: 1,
                maximumFractionDigits: 1,
            });
        }
        return '0';
    };

    const formatDataValue = (value: number | undefined): string => {
        if (value !== undefined) {
            return Math.round(value).toLocaleString();
        }
        return '0';
    };

    const formatPercentageTwoPointsValue = (value: number | undefined): string => {
        if (value !== undefined) {
            if (value === 0) {
                return '0';
            }
            const roundedValue = Math.round(value * 100) / 100;
            if (roundedValue === 0) {
                return '0';
            }
            return roundedValue.toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            });
        }
        return '0';
    };

    const getCurrencyDetails = (currencyCode) => {
        const currencyData = require('../../../../common/currencyReport.json');
        const currency = currencyData.find(c => c.cc === currencyCode);
        return currency ? { symbol: currency.symbol, name: currency.name } : null;
    };

    const currencyDetails = getCurrencyDetails(currencyName);
    const currencySymbol = currencyDetails ? currencyDetails.symbol : '';

    const columns = [
        {
            name: "City",
            cell: (row) => <span>{row?.city}</span>,
        },
        {
            name: "Country",
            cell: (row) => <span>{row?.country}</span>,
        },
        {
            name: "Brand",
            cell: (row) => <span>{row?.brandName}</span>,
            width: '170px'
        },
        {
            name: "Property",
            cell: (row) => <span>{row?.hotelName}</span>,
            width: '300px'
        },
        {
            name: "Tier",
            cell: (row) => <span>{row?.marketTierType}</span>,
        },
        {
            name: "Nights",
            sortable: true,
            selector: (row) => row?.totalNights,
            cell: (row) => {
                const fieldValue = row?.totalNights || 0;
                return `${formatDataValue(fieldValue)}`;
            },
        },
        {
            name: "Chain %",
            sortable: true,
            selector: (row) => row?.nightPercentage,
            cell: (row) => {
                const fieldValue = row?.nightPercentage || 0
                return `${formatPercentageValue(fieldValue)}%`;
            },
            width: '115px'
        },
        {
            name: "Spend",
            sortable: true,
            selector: (row) => row?.totalSpend,
            cell: (row) => {
                const fieldValue = row?.totalSpend || 0
                return `${currencySymbol}${formatDataValue(fieldValue)}`;
            },
        },
        {
            name: "ABR",
            sortable: true,
            selector: (row) => row?.ABR,
            cell: (row) => {
                const fieldValue = row?.ABR || 0
                const absoluteValue = Math.abs(fieldValue);
                const formattedValue = `${currencySymbol}${formatPercentageTwoPointsValue(absoluteValue)}`;

                return (
                    <span style={{ color: fieldValue > 0 ? 'red' : '#52d1c2', fontWeight: 'bold' }}>
                        {formattedValue}
                    </span>
                );
            },
        },
        {
            name: "Loc Av.",
            sortable: true,
            selector: (row) => row?.locAve,
            cell: (row) => {
                const fieldValue = row?.locAve || 0
                const absoluteValue = Math.abs(fieldValue);
                const formattedValue = `${currencySymbol}${formatPercentageTwoPointsValue(absoluteValue)}`;

                return (
                    <span style={{ color: fieldValue > 0 ? 'red' : '#52d1c2', fontWeight: 'bold' }}>
                        {formattedValue}
                    </span>
                );
            },
        },
        {
            name: "Contract",
            selector: (row) => row?.contractProgramme || 0,
            cell: (row) => {
                const fieldValue = row?.contractProgramme;

                return (
                    <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                        {fieldValue && (
                            <OverlayTrigger
                                placement="top"
                                delay={overlayTriggerDelay}
                                overlay={<Tooltip id='tooltip-table-top'>{'Activate'}</Tooltip>}
                            >
                                <img className='iconSizes4' src={StatusIcon} alt="Icon" />
                            </OverlayTrigger>
                        )}
                        <span>{fieldValue}</span>
                    </div>
                );
            },
        }
    ];

    useEffect(() => {
        if ((associateProgrammes && associateProgrammes.length > 0) || selectedProgramme?.programmeName) {
            const options = [
                ...(associateProgrammes || [])
                    .filter((item: string) => item !== 'None')
                    .map((item: string) => ({
                        value: item,
                        label: item,
                    })),
                selectedProgramme?.programmeName && {
                    value: selectedProgramme.programmeName,
                    label: selectedProgramme.programmeName,
                },
            ].filter(Boolean);

            setProgrammeList(options);
        } else {
            setProgrammeList([]);
        }
    }, [associateProgrammes, selectedProgramme]);

    const uniqueTier = Array.from(
        new Set(data?.map((item) => item.marketTierType))
    ).filter(marketTierType => marketTierType && marketTierType !== "N/A");

    const tierOptions = uniqueTier.map(marketTierType => ({
        label: marketTierType,
        value: marketTierType
    }));

    const uniqueBrandMap = new Map();

    data?.forEach((item) => {
        if (item.brandId && item.brandName) {
            uniqueBrandMap.set(item.brandId, item.brandName);
        }
    });

    const brandOptions = Array.from(uniqueBrandMap, ([brandId, brandName]) => ({
        label: brandName,
        value: brandId
    }));

    const handleProgrammeChange = (selectedOption) => {
        setSelectedAssociateProgramme(selectedOption);
    };

    useEffect(() => {
        let filtered = data;

        if (selectedTier) {
            filtered = filtered.filter((hotel) => hotel.marketTierType === selectedTier);
        }
        if (selectedBrand) {
            filtered = filtered.filter((hotel) => hotel.brandId === selectedBrand);
        }
        setFilteredData(filtered);
    }, [data, selectedTier, selectedBrand]);


    return (
        <>
            <div className="col-md-12" style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <div>
                    {reportId && (
                        <RFPBreadCrumbPage
                            isPageName="chainOverviewReport"
                            selectedClient={selectedClient}
                            selectedProgramme={selectedProgramme}
                            selectedClientName={selectedClient?.companyName}
                            selectedProgrammeName={selectedProgramme?.programmeName}
                            reportId={reportId}
                            programmeId={programmeId}
                            startDate={reportName}
                            tab="Chain Overview"
                        />
                    )}
                </div>
            </div>
            <Row className='btn_wrapper' style={{ marginTop: '65px' }}>
                <h3 className="chain-title">{chain}</h3>
                <Row>
                    <Col xs={5}>
                        <div className="chain-sec">
                            <div className="chain-list">
                                <ul>
                                    <li></li>
                                    <li>Spend</li>
                                    <li>Nights</li>
                                </ul>
                            </div>
                            <div className="chain-list">
                                <ul>
                                    <li>Cont</li>
                                    <li>{currencySymbol}{formatDataValue(summaryData?.totalContractedSpend)}</li>
                                    <li>{currencySymbol}{formatDataValue(summaryData?.totalContractedNights)}</li>
                                </ul>
                            </div>
                            <div className="chain-list">
                                <ul>
                                    <li>Non Cont</li>
                                    <li>{currencySymbol}{formatDataValue(summaryData?.totalNonContractedSpend)}</li>
                                    <li>{formatDataValue(summaryData?.totalNonContractedNights)}</li>
                                </ul>
                            </div>
                            <div className="chain-list">
                                <ul>
                                    <li>Total</li>
                                    <li>{currencySymbol}{formatDataValue(summaryData?.totalAllSpend)}</li>
                                    <li>{formatDataValue(summaryData?.totalRoomNights)}</li>
                                </ul>
                            </div>
                        </div>
                    </Col>
                    <Col xs={3}>
                        {programmeList.length > 0 ? (
                            <Select
                                className="reportTableButton"
                                isClearable
                                placeholder="Search Programme"
                                styles={selectStylesLittleRect}
                                options={programmeList}
                                value={selectedAssociateProgramme}
                                onChange={handleProgrammeChange}
                            />
                        ) : null}
                        <Row>
                            <Col xs={2} style={{ display: "flex", alignItems: "center", justifyContent: 'left', marginTop: '10px' }}>
                                {loading && (<Spinner animation="border" variant="warning" />)}
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={2}>
                        <Select
                            className='reportTableButton'
                            isClearable
                            placeholder={<div>Search Tier</div>}
                            options={tierOptions}
                            styles={selectStylesLittleRect}
                            isSearchable={true}
                            onChange={(option) => setSelectedTier(option ? option.value : null)}
                        />
                    </Col>
                    <Col xs={2}>
                        <Select
                            className='reportTableButton'
                            isClearable
                            placeholder={<div>Search Brand</div>}
                            options={brandOptions}
                            styles={selectStylesLittleRect}
                            isSearchable={true}
                            value={brandOptions.find(option => option.value === selectedBrand) || null}
                            onChange={(option) => setSelectedBrand(option ? option.value : null)}
                        />
                    </Col>
                </Row>

            </Row>
            {
                !loading && <div className='brnd_tbl' style={{ marginTop: '0px' }}>
                    <DataTable className="reports_table reports_table_chain"
                        columns={columns}
                        data={filteredData}
                        pagination
                        striped={true}
                        customStyles={tableStyles}
                        theme="mysaTable"
                        fixedHeader={true}
                        paginationPerPage={25}
                        paginationRowsPerPageOptions={[25, 50, 100]}
                        noDataComponent="Updating records"
                    />
                </div>
            }
        </>
    )
}

export default ChainOverviewData;