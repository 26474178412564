import { Image } from 'antd';
import _ from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { setStorage } from '../../../common/storage';
import { getLoggedUserType, getUserAccess } from '../../../common/functions';
import { UserGroups } from '../../../common/constants';
import { $mysaMegenta, $mysaTeal, overlayTriggerDelay, routingButton, selectStyles, selectStylesLittleRect, tableStyles } from '../../../common/components-style';
import { Button, Col, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import Archive from '../../../images/icons/svg/outside/Icon-Function-Archive.svg';
import ViewEdit from '../../../images/icons/svg/outside/Icon-Function-View-Edit.svg';
import MenuSvg from '../../../images/icons/svg/menu.svg';
import DataTable from 'react-data-table-component';
import Select from 'react-select';
import { useStoreActions, useStoreState } from 'easy-peasy';
import SweetAlert from 'react-bootstrap-sweetalert';

import CircleIcon from '../../../images/icons/svg/circle.svg';


const fallbackUrl = 'https://res.cloudinary.com/mysa/image/upload/v1613451891/common/no-image-1771002-1505134_sqlz99.webp';

const ClientList: React.FC<any> = ({ loadForm, onClientSelect, onArchive, activateProgramme, setClientId, formOpen, setFormOpen, setSelectedClients }): JSX.Element => {
  const history = useHistory();
  const [options, setOptions] = useState<any>([]);
  const [clientIds, setClientIds] = useState<String>("");
  const [archivePopup, setArchivePopup] = useState<boolean>(false);
  const [archiveData, setArchiveData] = useState<any>();
  const [clientStatus, setClientStatus] = useState<String>("");
  const [activatePopup, setActivatePopup] = useState<boolean>(false);
  const [activeData, setActiveData] = useState<any>();

  /////////////////////////////////////////////////////////////////////////////////////////////////////
  const {
    getClientData,
    getClientList,
    getClientDataByStatus,

  } = useStoreActions<any>((actions) => ({
    getClientData: actions.client.getClientData,
    getClientList: actions.client.getClientList,
    getClientDataByStatus: actions.client.getClientDataByStatus,

  }));

  ////////////////////////////////////////////////////////////////////////////////////////////////////
  const {

    getClientDataSuccess,
    getClientListSuccess,
    getClientDataByStatusSuccess,

  } = useStoreState<any>((state) => ({
    getClientDataSuccess: state.client.getClientDataSuccess,
    getClientListSuccess: state.client.getClientListSuccess,
    getClientDataByStatusSuccess: state.client.getClientDataByStatusSuccess,

  }));


  ////////////////////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    getClientList();
  }, [getClientList]);

  const callClientList = useCallback(() => {
    if (clientIds) {
      getClientData(clientIds);

    }else{
      getClientList();
    }
  }, [clientIds, getClientData, getClientList]);

  ////////////////////////////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    callClientList();
  }, [callClientList, clientIds]);

  ////////////////////////////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    if (clientIds) {
      getClientData(clientIds);
    }
  }, [getClientData, clientIds]);



  //////////////////////////////////////////// get all brand sign up //////////////////////////////////
  useEffect(() => {
    if (clientStatus) {
      const payload = {
        status: clientStatus,

      }
      getClientDataByStatus(payload);
    }

  }, [clientStatus, getClientDataByStatus]);

  //////////////////////////////////////////////////////////////////////////////////////////////////////////////
  const columns = [
    {
      name: 'Company logo',
      cell: (row) => (
        <Image width={40} height={40} src={row.companyLogo} onError={(e: any) => { e.target.src = fallbackUrl; }} />
      ),
    },
    {
      name: 'Company name',
      selector: row => row.companyName,
    },
    {
      name: 'City',
      selector: row => row.city,
    },

    {
      name: 'Country',
      selector: row => row.country,
    },
    {
      name: 'Status',
      cell: (row) => {
        let color = $mysaMegenta;
        if (row.status === 'active') color = $mysaTeal;
        if (row.status === 'archive') color = $mysaMegenta;

        return (
          <span style={{ color: color, fontWeight: 'bold' }} className='text-capitalize'>{row.status}</span>
        )
      }
    },
    {
      name: 'Action',
      cell: (row: any) => {
        return (<>
          <OverlayTrigger
            placement="top"
            delay={overlayTriggerDelay}
            overlay={<Tooltip id='tooltip-table-top'>View / Edit</Tooltip>}
          >
            <img className='iconSizes' style={{ marginRight: '5%' }} src={ViewEdit} alt="" onClick={() => { loadForm(row); setStorage('myo_selected_client', row); onClientSelect(row) }} />
          </OverlayTrigger>

          <OverlayTrigger
            placement="top"
            delay={overlayTriggerDelay}
            overlay={<Tooltip id='tooltip-table-top'>Programme</Tooltip>}
          >
            <img className='iconSizes' style={{ marginRight: '5%', width: 25 }} src={MenuSvg} alt="" onClick={() => { history.push('/admin/programme-management', { clientId: row.key }); setStorage('myo_selected_client', row) }} />
          </OverlayTrigger>
          {
            getLoggedUserType() === UserGroups.super_admin && <OverlayTrigger
              placement="top"
              delay={overlayTriggerDelay}
              overlay={<Tooltip id='tooltip-table-top'>Acrhive</Tooltip>}
            >
              <img className='iconSizes' src={Archive} alt="" onClick={() => {
                setArchivePopup(true);
                setArchiveData(row);
                setClientId(row?.key);
              }} />
            </OverlayTrigger>
          }
          {
            ((row.status === 'archive')) &&
            <OverlayTrigger
              placement="top"
              delay={overlayTriggerDelay}
              overlay={<Tooltip id='tooltip-table-top'>{'Re-Activate'}</Tooltip>}
            >
              <img className='iconSizes2' src={CircleIcon} alt="" onClick={() => { setActivatePopup(true); setActiveData(row); setClientId(row?.key); }} />
            </OverlayTrigger>}

          {/* {
            ((row.status === 'inactive')) &&
            <OverlayTrigger
              placement="top"
              delay={overlayTriggerDelay}
              overlay={<Tooltip id='tooltip-table-top'>{'Activate'}</Tooltip>}
            >
              <img className='iconSizes2' src={CircleIcon} alt="" onClick={() => { setActivatePopup(true); setActiveData(row) }} />
            </OverlayTrigger>}

          {
            ((row.status === 'active')) &&
            <OverlayTrigger
              placement="top"
              delay={overlayTriggerDelay}
              overlay={<Tooltip id='tooltip-table-top'>{'Activated'}</Tooltip>}
            >
              <img className='iconSizes' style={{ marginLeft: '23px' }} src={StatusIcon} alt="" />
            </OverlayTrigger>} */}

        </>)
      },
    },
  ];

  //////////////////////////////////////////////////////////////////////////////////////
  const onChangeClient = useCallback((data: any) => {
    if (data?.value) {
      setClientIds(data.value);
    } else {
      setClientIds('');

    }

  }, [])

  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  const onChangeStatus = useCallback((data: any) => {
    if (data?.value) {
      setClientStatus(data.value);
    } else {
      setClientStatus('');
      callClientList();
    }

  }, [callClientList]);

  /////////////////////////////////////////////////////////////////////////////////////////////////////
  const data: any = useMemo(() => {
    const userClient = getUserAccess('client');
    const userClientIds = _.map(userClient, (a) => a.clientId);
    if (getClientListSuccess) {
      const data = getClientListSuccess?.data.filter((list) => list?.clientStatus !== 'archive')
      const mappedData = _.map(data, (client) => {

        return {
          key: client._id,
          companyLogo: client.companyLogo,
          companyName: client.companyName,
          city: client.city,
          country: client.country,
          status: client.clientStatus,
          clientAdmin: client.clientAdmin,
        }
      });
      let filteredData = _.filter(mappedData, (a) => userClientIds.includes(a.key));

      if (getLoggedUserType() === UserGroups.super_admin) {
        const clientListTemp: any = [];
        _.map(mappedData, (element) =>
          clientListTemp.push(
            {
              value: element.key,
              label: element.companyName,
            }
          )
        );
        setOptions(clientListTemp);
      }
      return getLoggedUserType() === UserGroups.super_admin ? mappedData : filteredData;
    } else if (getClientDataByStatusSuccess) {
      const dataList = _.map(getClientDataByStatusSuccess.data, (client) => {
        return {
          key: client._id,
          companyLogo: client.companyLogo,
          companyName: client.companyName,
          city: client.city,
          country: client.country,
          status: client.clientStatus,
          clientAdmin: client.clientAdmin,

        }
      });
      return dataList;
    }
    else {
      if (getClientDataSuccess) {
       
        // const data = getClientDataSuccess?.data.filter((list) => list?.clientStatus !== 'archive')
        let clientList: any = [];
        clientList.push(getClientDataSuccess?.data);
        const dataList = _.map(clientList, (client) => {
          return {
            key: client._id,
            companyLogo: client.companyLogo,
            companyName: client.companyName,
            city: client.city,
            country: client.country,
            status: client.clientStatus,
            clientAdmin: client.clientAdmin,
          }
        });
        return dataList;

      }
    }
    return

  }, [getClientDataByStatusSuccess, getClientDataSuccess, getClientListSuccess]);

  ////////////////////////////////////////////////////////////////////////////////////////////////////
  return (
    <>
      {getLoggedUserType() === UserGroups.super_admin && <>
        <Row className='btn_wrapper' style={{ marginTop: '-30px' }}>
          <Col xs={2}>
            <Select
              inputId='searchClientId'
              className='search_bar select_btn '
              isClearable
              placeholder={<div>Search Client</div>}
              options={options}
              styles={selectStylesLittleRect}
              isSearchable={true}
              onChange={onChangeClient}
            />
          </Col>
          <Col xs={2}>
         
         </Col>
          <Col xs={2}>
            <Select
              inputId='searchClientStatusId'
              isClearable
              className='search_bar select_btn'
              placeholder={<div className="text-left">Select Status</div>}
              options={[
                {
                  value: 'active',
                  label: 'Active',
                },
                {
                  value: 'archive',
                  label: 'Archive',
                },

              ]}
              styles={selectStyles}
              isSearchable={false}
              onChange={onChangeStatus}
            />
          </Col>
          <Col xs={2}>
         
          </Col>
          <Col xs={2}>
            {getLoggedUserType() === UserGroups.super_admin && <Button style={routingButton} id='routing-button-blue-brand'  hidden={formOpen} onClick={() => { loadForm(null); setFormOpen(true); setSelectedClients(null); setClientId(null) }} >Create New Client</Button>}
          </Col>
        </Row>

      </>}

      <div className="property-list mt-5">
        <div className='mt-3'>
          <DataTable
            columns={columns}
            data={data}
            pagination
            striped={true}
            customStyles={tableStyles}
            theme='mysaTable'
            fixedHeader={true}
          // noDataComponent={'When you receive bookings, you will see them listed here'}
          // noDataComponent={'This is where you will be able to view any client detail when you have been accepted on a programme'}
          />
        </div>
      </div>



      <SweetAlert
        show={archivePopup}
        warning
        showCancel={true}
        focusCancelBtn={true}
        confirmBtnText="Yes"
        cancelBtnText="No"
        confirmBtnBsStyle="danger"
        title="Are you sure you want to archive this client?"
        onConfirm={() => {
          onArchive(archiveData);
          setArchivePopup(false);
        }}
        onCancel={() => {
          setArchivePopup(false);
        }}
      >
      </SweetAlert>

      <SweetAlert
        show={activatePopup}
        warning
        showCancel={true}
        focusCancelBtn={true}
        confirmBtnText="Yes"
        cancelBtnText="No"
        confirmBtnBsStyle="danger"
        title="Are you sure you want to activate this client?"
        onConfirm={() => {
          activateProgramme(activeData)
          setActivatePopup(false);
        }}
        onCancel={() => {
          setActivatePopup(false);
        }}
      >
      </SweetAlert>
    </>
  )
};

export default ClientList;