import _ from 'lodash';
import React, { useEffect } from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import { UserGroups } from '../../../common/constants';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { iconDivSelectedStyle, iconDivStyle, iconImageSelectedStyle, overlayTriggerDelay, toolTipStyle } from '../../../common/components-style';
import { getLoggedUserType, hasBrand, isBookerHome, isProgramAdmin, isProgrammeHome, isSuperAdmin, isTravelerHome } from '../../../common/functions';
import homeIcon from '../../../images/icons/svg/navigate/Icon-Navigation-Home.svg';
import homeIconSelected from '../../../images/icons/svg/navigate/Icon-Navigation-Home-selected.svg';
import messageIcon from '../../../images/icons/svg/navigate/Icon-Navigation-Message-Centre.svg';
import messageIconSelected from '../../../images/icons/svg/navigate/Icon-Navigation-Message-Centre-selected.svg';
import clientIcon from '../../../images/icons/svg/navigate/Icon-Navigation-Client-Management.svg';
import clientIconSelected from '../../../images/icons/svg/navigate/Icon-Navigation-Client-Management-selected.svg';
import partnerIcon from '../../../images/icons/svg/navigate/partner-icon.svg';
import partnerIconSelected from '../../../images/icons/svg/navigate/partner-selected-icon.svg';
import programIcon from '../../../images/icons/svg/navigate/Icon-Navigation-Programme-Management.svg';
import programIconSelected from '../../../images/icons/svg/navigate/Icon-Navigation-Programme-Management-selected.svg';
import brandIcon from '../../../images/icons/svg/navigate/Icon-Navigation-Brand-Management.svg';
import brandIconSelected from '../../../images/icons/svg/navigate/Icon-Navigation-Brand-Management-selected.svg';
import managementIcon from '../../../images/icons/svg/navigate/Icon-Navigation-Management-Information.svg';
import managementIconSelected from '../../../images/icons/svg/navigate/Icon-Navigation-Management-Information-selected.svg';
import invoiceIcon from '../../../images/icons/svg/navigate/Icon-Navigation-Invoice-Management.svg';
import invoiceIconSelected from '../../../images/icons/svg/navigate/Icon-Navigation-Invoice-Management-selected.svg';
import createIcon from '../../../images/icons/svg/navigate/Icon-Navigation-Create-A-Booking.svg';
import createIconSelected from '../../../images/icons/svg/navigate/Icon-Navigation-Create-A-Booking-selected.svg';
import rfpIcon from '../../../images/icons/svg/navigate/Icon-Navigation-RFP-Management.svg';
import rfpIconSelected from '../../../images/icons/svg/navigate/Icon-Navigation-RFP-Management-selected.svg';
import setting from '../../../images/icons/svg/navigate/setting.svg';
import settingSelected from '../../../images/icons/svg/navigate/setting-selected.svg';
import programmeIcon from '../../../images/icons/svg/navigate/Icon-Navigate-Programme-Management.svg';
import programmeSelected from '../../../images/icons/svg/navigate/Icon-Navigate-Programme-Management-selected.svg';
import BookerReportsNav from './components/booker-reports-nav';
import userIcon from '../../../images/icons/svg/navigate/Group.svg';
import userIconSelected from '../../../images/icons/svg/navigate/Group-selected.svg';

import helpIcon from '../../../images/icons/Help-2.png';

interface Iprops {
  setIsExpand: React.MouseEventHandler,
}

const IconMenu: React.FC<Iprops> = ({ setIsExpand }): JSX.Element => {
  const { location: { pathname } } = useHistory();
  /////////////////////////////////////////////// navigate management ///////////////////////////

  const hiddenClientManagement = getLoggedUserType() !== UserGroups.super_admin;
  const super_admin = isSuperAdmin();
  const hiddenBrandManagement = !hasBrand() && _.includes([
    UserGroups.corporate_admin,
    UserGroups.corporate_sub_admin,
    UserGroups.chief_property_admin,
    UserGroups.booker,
    UserGroups.property_admin,
    UserGroups.department_admin,
    UserGroups.user,
    UserGroups.programme_admin,
  ], getLoggedUserType());

  // const hiddenBookingManagement = getLoggedUserType() === UserGroups.user && !getStorage('moreThanOneProgramme');

  const hiddenSystemSetup = _.includes([
    UserGroups.user,
  ], getLoggedUserType())

  const hiddenRFPManagement = !hasBrand() && getLoggedUserType() !== UserGroups.brand_admin;

  const showOnlyCorporateAdmin = !isProgramAdmin();

  const showProgramme = isProgrammeHome();
  const showTraveler = isTravelerHome();
  const showBooker = isBookerHome();



  return (
    <>
      <NavLink
        onClick={setIsExpand}
        exact
        activeClassName=""
        to="/admin">
        {
          pathname === '/admin' || pathname === '/admin/property-page' || pathname === '/admin/confirmation-page' || pathname === '/admin/booking-payment' || pathname === '/admin/traveler-page' ?
            <OverlayTrigger
              placement="right"
              delay={overlayTriggerDelay}
              overlay={<Tooltip id='tooltip-right' style={toolTipStyle}>Home</Tooltip>}
            >
              <div style={iconDivStyle}><img id='homeIconSelected' src={homeIconSelected} alt="" /></div>
            </OverlayTrigger>
            :
            <OverlayTrigger
              placement="right"
              delay={overlayTriggerDelay}
              overlay={<Tooltip id='tooltip-right' style={toolTipStyle}>Home</Tooltip>}
            >
              <div style={iconDivStyle}><img id='homeIcon' src={homeIcon} alt="" /></div>
            </OverlayTrigger>

        }
      </NavLink>
      <NavLink
        onClick={setIsExpand}
        hidden={hiddenSystemSetup}
        exact
        activeClassName=""
        to="/admin/messages">
        {
          pathname === '/admin/messages' ?
            <OverlayTrigger
              placement="right"
              delay={overlayTriggerDelay}
              overlay={<Tooltip id='tooltip-right' style={toolTipStyle}>Message Center</Tooltip>}
            >
              <div style={iconDivSelectedStyle} ><img id='messageIconSelected' style={iconImageSelectedStyle} src={messageIconSelected} alt="" /></div>
            </OverlayTrigger>
            :
            <OverlayTrigger
              placement="right"
              delay={overlayTriggerDelay}
              overlay={<Tooltip id='tooltip-right' style={toolTipStyle}>Message Center</Tooltip>}
            >
              <div style={iconDivStyle}><img id='messageIcon' src={messageIcon} alt="" /></div>
            </OverlayTrigger>

        }
      </NavLink>
      <NavLink
        hidden={hiddenClientManagement}
        onClick={setIsExpand}
        exact
        activeClassName=""
        to="/admin/client-management">
        {
          pathname === '/admin/client-management' || pathname === '/admin/programme-management' || pathname === '/admin/location-management' || pathname === '/admin/rfp-management' || pathname === '/admin/programme-spend-data' || pathname === '/admin/location-overview-data' || pathname === '/admin/contracted-property-data' || pathname === '/admin/invite-selection' || pathname === '/admin/chain-overview-data' ?
            <OverlayTrigger
              placement="right"
              delay={overlayTriggerDelay}
              overlay={<Tooltip id='tooltip-right' style={toolTipStyle}>Client Management</Tooltip>}
            >
              <div style={iconDivSelectedStyle}><img id='clientIconSelected' style={iconImageSelectedStyle} src={clientIconSelected} alt="" /></div>
            </OverlayTrigger>
            :
            <OverlayTrigger
              placement="right"
              delay={overlayTriggerDelay}
              overlay={<Tooltip id='tooltip-right' style={toolTipStyle}>Client Management</Tooltip>}
            >
              <div style={iconDivStyle}><img id='clientIcon' src={clientIcon} alt="" /></div>
            </OverlayTrigger>

        }
      </NavLink>

      <NavLink
        hidden={hiddenSystemSetup}
        // hidden={hiddenClientManagement}
        onClick={setIsExpand}
        exact
        activeClassName=""
        to="/admin/partner-management">
        {
          pathname === '/admin/partner-management' ?
            <OverlayTrigger
              placement="right"
              delay={overlayTriggerDelay}
              overlay={<Tooltip id='tooltip-right' style={toolTipStyle}>Partner Management</Tooltip>}
            >
              <div style={iconDivSelectedStyle}><img id='partnerIconSelected' style={iconImageSelectedStyle} src={partnerIconSelected} alt="" /></div>
            </OverlayTrigger>
            :
            <OverlayTrigger
              placement="right"
              delay={overlayTriggerDelay}
              overlay={<Tooltip id='tooltip-right' style={toolTipStyle}>Partner Management</Tooltip>}
            >
              <div style={iconDivStyle}><img id='partnerIcon' src={partnerIcon} alt="" /></div>
            </OverlayTrigger>

        }
      </NavLink>


      <NavLink
        hidden={(!showProgramme && !showTraveler && !showBooker)}
        onClick={setIsExpand}
        exact
        activeClassName=""
        to={{
          pathname: '/admin/corporate-programme-management',
          state: {
            filterProgramme: false
          }
        }}>
        {
          pathname === '/admin/corporate-programme-management' ?
            <OverlayTrigger
              placement="right"
              delay={overlayTriggerDelay}
              overlay={<Tooltip id='tooltip-right' style={toolTipStyle}>Programme Management</Tooltip>}
            >
              <div style={iconDivSelectedStyle}><img id='programmeIconSelectedCorporate' style={iconImageSelectedStyle} src={programmeSelected} alt="" /></div>
            </OverlayTrigger>
            :
            <OverlayTrigger
              placement="right"
              delay={overlayTriggerDelay}
              overlay={<Tooltip id='tooltip-right' style={toolTipStyle}>Programme Management</Tooltip>}
            >
              <div style={iconDivStyle}><img id='programmeIconCorporate' src={programmeIcon} alt="" /></div>
            </OverlayTrigger>

        }
      </NavLink>
      <NavLink
        hidden={!super_admin && (!showProgramme)}
        onClick={setIsExpand}
        exact
        activeClassName=""
        to={{
          pathname: "/admin/client-proposal-management",
          state: {
            filterProgramManage: false
          }
        }}>
        {
          pathname === '/admin/client-proposal-management' ?
            <OverlayTrigger
              placement="right"
              delay={overlayTriggerDelay}
              overlay={<Tooltip id='tooltip-right' style={toolTipStyle}>Proposal Management</Tooltip>}
            >
              <div style={iconDivSelectedStyle}><img id='programmeIconSelectedClient' style={iconImageSelectedStyle} src={programIconSelected} alt="" /></div>
            </OverlayTrigger>
            :
            <OverlayTrigger
              placement="right"
              delay={overlayTriggerDelay}
              overlay={<Tooltip id='tooltip-right' style={toolTipStyle}>Proposal Management</Tooltip>}
            >
              <div style={iconDivStyle}><img id='programmeIconClient' src={programIcon} alt="" /></div>
            </OverlayTrigger>

        }
      </NavLink>
      <NavLink
        hidden={hiddenBrandManagement}
        onClick={setIsExpand}
        exact
        activeClassName=""
        to="/admin/brand-management">
        {
          pathname === '/admin/brand-property-management' || pathname === '/admin/brand-management' || pathname === '/admin/property-management' || pathname === '/admin/inventory-management' || pathname === '/admin/rate-management' ?
            <OverlayTrigger
              placement="right"
              delay={overlayTriggerDelay}
              overlay={<Tooltip id='tooltip-right' style={toolTipStyle}>Brand Management</Tooltip>}
            >
              <div style={iconDivSelectedStyle}><img id='brandIconSelected' style={iconImageSelectedStyle} src={brandIconSelected} alt="" /></div>
            </OverlayTrigger>
            :
            <OverlayTrigger
              placement="right"
              delay={overlayTriggerDelay}
              overlay={<Tooltip id='tooltip-right' style={toolTipStyle}>Brand Management</Tooltip>}
            >
              <div style={iconDivStyle}><img id='brandIcon' src={brandIcon} alt="" /></div>
            </OverlayTrigger>

        }
      </NavLink>
      <NavLink
        hidden={hiddenRFPManagement}
        onClick={setIsExpand}
        exact
        activeClassName=""
        to="/admin/brand-rfp-management">
        {
          pathname === '/admin/brand-rfp-management' ?
            <OverlayTrigger
              placement="right"
              delay={overlayTriggerDelay}
              overlay={<Tooltip id='tooltip-right' style={toolTipStyle}>RFP Management</Tooltip>}
            >
              <div style={iconDivSelectedStyle}><img id='rfpIconSelected' style={iconImageSelectedStyle} src={rfpIconSelected} alt="" /></div>
            </OverlayTrigger>
            :
            <OverlayTrigger
              placement="right"
              delay={overlayTriggerDelay}
              overlay={<Tooltip id='tooltip-right' style={toolTipStyle}>RFP Management</Tooltip>}
            >
              <div style={iconDivStyle}><img id='rfpIcon' src={rfpIcon} alt="" /></div>
            </OverlayTrigger>

        }
      </NavLink>

      {/* booking management client & booker */}
      <BookerReportsNav setIsExpand={setIsExpand} pathname={pathname} showProgramme={showProgramme} showTraveler={showTraveler} showBooker={showBooker} />

      <NavLink
        hidden={(!showProgramme)}
        onClick={setIsExpand}
        exact
        activeClassName=""
        to="/admin/corporate-user-management">
        {
          pathname === '/admin/corporate-user-management' ?
            <OverlayTrigger
              placement="right"
              delay={overlayTriggerDelay}
              overlay={<Tooltip id='tooltip-right' style={toolTipStyle}>User Management</Tooltip>}
            >
              <div style={iconDivSelectedStyle}><img id='userIconSelectedCorporate' style={iconImageSelectedStyle} src={userIconSelected} alt="" /></div>
            </OverlayTrigger>
            :
            <OverlayTrigger
              placement="right"
              delay={overlayTriggerDelay}
              overlay={<Tooltip id='tooltip-right' style={toolTipStyle}>User Management</Tooltip>}
            >
              <div style={iconDivStyle}><img id='userIconCorporate' src={userIcon} alt="" /></div>
            </OverlayTrigger>

        }
      </NavLink>
      <NavLink
        hidden={hiddenSystemSetup}
        onClick={setIsExpand}
        exact
        activeClassName=""
        to="/admin/admin-user-management">
        {
          pathname === '/admin/admin-user-management' ?
            <OverlayTrigger
              placement="right"
              delay={overlayTriggerDelay}
              overlay={<Tooltip id='tooltip-right' style={toolTipStyle}>User Management</Tooltip>}
            >
              <div style={iconDivSelectedStyle}><img id='userIconSelectedAdmin' style={iconImageSelectedStyle} src={userIconSelected} alt="" /></div>
            </OverlayTrigger>
            :
            <OverlayTrigger
              placement="right"
              delay={overlayTriggerDelay}
              overlay={<Tooltip id='tooltip-right' style={toolTipStyle}>User Management</Tooltip>}
            >
              <div style={iconDivStyle}><img id='userIconAdmin' src={userIcon} alt="" /></div>
            </OverlayTrigger>

        }
      </NavLink>
      <NavLink
        hidden={hiddenSystemSetup}
        onClick={setIsExpand}
        exact
        activeClassName=""
        to="/admin/management-information">
        {
          pathname === '/admin/management-information' ?
            <OverlayTrigger
              placement="right"
              delay={overlayTriggerDelay}
              overlay={<Tooltip id='tooltip-right' style={toolTipStyle}>Management Information</Tooltip>}
            >
              <div style={iconDivSelectedStyle}><img id='managementIconSelected' style={iconImageSelectedStyle} src={managementIconSelected} alt="" /></div>
            </OverlayTrigger>
            :
            <OverlayTrigger
              placement="right"
              delay={overlayTriggerDelay}
              overlay={<Tooltip id='tooltip-right' style={toolTipStyle}>Management Information</Tooltip>}
            >
              <div style={iconDivStyle}><img id='managementIcon' src={managementIcon} alt="" /></div>
            </OverlayTrigger>

        }
      </NavLink>

      <NavLink
        hidden={hiddenSystemSetup}
        onClick={setIsExpand}
        exact
        activeClassName=""
        to="/admin/invoice-management">
        {
          pathname === '/admin/invoice-management' ?
            <OverlayTrigger
              placement="right"
              delay={overlayTriggerDelay}
              overlay={<Tooltip id='tooltip-right' style={toolTipStyle}>Invoice Management</Tooltip>}
            >
              <div style={iconDivSelectedStyle}><img id='invoiceIconSelected' style={iconImageSelectedStyle} src={invoiceIconSelected} alt="" /></div>
            </OverlayTrigger>
            :
            <OverlayTrigger
              placement="right"
              delay={overlayTriggerDelay}
              overlay={<Tooltip id='tooltip-right' style={toolTipStyle}>Invoice Management</Tooltip>}
            >
              <div style={iconDivStyle}><img id='invoiceIcon' src={invoiceIcon} alt="" /></div>
            </OverlayTrigger>

        }
      </NavLink>
      <NavLink
        hidden={(!super_admin && (!showProgramme))}
        onClick={setIsExpand}
        exact
        activeClassName=""
        to="/admin/booking-management">
        {
          pathname === '/admin/booking-management' || pathname === '/admin/property-page' || pathname === '/admin/traveler-page' || pathname === '/admin/booking-payment' || pathname === '/admin/confirmation-page' ?
            <OverlayTrigger
              placement="right"
              delay={overlayTriggerDelay}
              overlay={<Tooltip id='tooltip-right' style={toolTipStyle}>Create a Booking</Tooltip>}
            >
              <div style={iconDivSelectedStyle}><img id='createIconSelected' style={iconImageSelectedStyle} src={createIconSelected} alt="" /></div>
            </OverlayTrigger>
            :
            <OverlayTrigger
              placement="right"
              delay={overlayTriggerDelay}
              overlay={<Tooltip id='tooltip-right' style={toolTipStyle}>Create a Booking</Tooltip>}
            >
              <div style={iconDivStyle}><img id='createIcon' src={createIcon} alt="" /></div>
            </OverlayTrigger>

        }
      </NavLink>
      {/* admin-user-management */}

      <NavLink
        hidden={hiddenSystemSetup}
        onClick={setIsExpand}
        exact
        activeClassName=""
        to="/admin/system-set-up">
        {
          pathname === '/admin/system-set-up' ?
            <OverlayTrigger
              placement="right"
              delay={overlayTriggerDelay}
              overlay={<Tooltip id='tooltip-right' style={toolTipStyle}>Settings</Tooltip>}
            >
              <div style={iconDivSelectedStyle}><img id='settingIconSelectedSystem' style={iconImageSelectedStyle} src={settingSelected} alt="" /></div>
            </OverlayTrigger>
            :
            <OverlayTrigger
              placement="right"
              delay={overlayTriggerDelay}
              overlay={<Tooltip id='tooltip-right' style={toolTipStyle}>Settings</Tooltip>}
            >
              <div style={iconDivStyle}><img id='settingIconSystem' src={setting} alt="" /></div>
            </OverlayTrigger>

        }
      </NavLink>

      <NavLink
        hidden={(!showProgramme)}
        onClick={setIsExpand}
        exact
        activeClassName=""
        to="/admin/programme-system-set-up">
        {
          pathname === '/admin/programme-system-set-up' ?
            <OverlayTrigger
              placement="right"
              delay={overlayTriggerDelay}
              overlay={<Tooltip id='tooltip-right' style={toolTipStyle}>Settings</Tooltip>}
            >
              <div style={iconDivSelectedStyle}><img id='settingIconSelectedProgram' style={iconImageSelectedStyle} src={settingSelected} alt="" /></div>
            </OverlayTrigger>
            :
            <OverlayTrigger
              placement="right"
              delay={overlayTriggerDelay}
              overlay={<Tooltip id='tooltip-right' style={toolTipStyle}>Settings</Tooltip>}
            >
              <div style={iconDivStyle}><img id='settingIconProgram' src={setting} alt="" /></div>
            </OverlayTrigger>

        }
      </NavLink>

      <NavLink
        hidden={hiddenRFPManagement}
        onClick={setIsExpand}
        exact
        activeClassName=""
        to="/admin/brand-help-management">
        {
          pathname === '/admin/brand-help-management' ?
            <OverlayTrigger
              placement="right"
              delay={overlayTriggerDelay}
              overlay={<Tooltip id='tooltip-right' style={toolTipStyle}>Help Management</Tooltip>}
            >
              <div style={iconDivSelectedStyle}><img id='helpIconSelected' style={iconImageSelectedStyle} src={helpIcon} alt="" /></div>
            </OverlayTrigger>
            :
            <OverlayTrigger
              placement="right"
              delay={overlayTriggerDelay}
              overlay={<Tooltip id='tooltip-right' style={toolTipStyle}>Help Management</Tooltip>}
            >
              <div style={iconDivStyle}><img id='helpIcon' src={helpIcon} alt="" /></div>
            </OverlayTrigger>

        }
      </NavLink>
    </>
  )
};

export default IconMenu;