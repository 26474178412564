import { Checkbox } from 'antd';
import { useStoreActions, useStoreState } from 'easy-peasy';
import _ from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { Col, Form, Row, Button, Spinner } from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';
import PhoneInput from 'react-phone-input-2';
import { formButton } from '../../../../common/components-style';
import { UserAccess } from '../../../../common/constants';
import { canBrandHotelModuleWrite, formatPhoneNumber, getMailDomain } from '../../../../common/functions';
import { HotelInvoiceFormInput } from '../../../../common/interfaces';
import { toast } from 'react-toastify';
import { ErrorMessage } from '@hookform/error-message';
import BreadCrumbPage from '../../../common/BreadCrumbPage';
import { PhoneNumberUtil } from 'google-libphonenumber';

const HotelInvoice: React.FC<any> = ({ allInOneHotelBreadCrumb, brandId, setFormOpen, hotelId, selectedBrand, tab }): JSX.Element => {
    ///////////////////// initialize form ////////////////////////////////////// 
    const { register, handleSubmit, formState: { errors }, control, setValue } = useForm<HotelInvoiceFormInput>();

    ///////////////////////// local state manage ////////////////////////////////////
    const [editEnable, setEditEnable] = useState<boolean>(false);
    const [hotelData, setHotelData] = useState<any>();
    const [invoiceData, setInvoiceData] = useState<any>({});
    const [loading, setLoading] = useState<boolean>(false);
    const [hotelInvoiceId, setHotelInvoiceId] = useState<string>();
    const [phone, setPhone] = useState<string>('');
    const [invoiceIssuing, setInvoiceIssuing] = useState<Array<string>>([]);
    const [isPropertyDirectly, setIsPropertyDirectly] = useState<boolean>(false);
    const [isHeadOffice, setIsHeadOffice] = useState<boolean>(false);
    const [isHotel, setIsHotel] = useState<boolean>(false);
    const [brandDomains, setBrandDomains] = useState<Array<string>>([]);

    const plainOptions = [{ label: 'Head Office', value: 'Head Office', disabled: isHeadOffice ? false : true },
    { label: 'Property Direct', value: 'Property Direct', disabled: isHotel ? false : true },
    ]

    const [validPhoneNumber, setValidPhoneNumber] = useState<boolean>(false);

    ////////////////////////////////////////////////////////////////////////////////////////////////////
    const { onloadBrand, createHotelInvoiceDetail, updateHotelInvoiceDetail, getHotelInvoiceDetailById, resetHotel, getHotel, getBrandFinanceDetailByBrandId } = useStoreActions<any>((actions) => ({
        createHotelInvoiceDetail: actions.hotel.createHotelInvoiceDetail,
        updateHotelInvoiceDetail: actions.hotel.updateHotelInvoiceDetail,
        getHotelInvoiceDetailById: actions.hotel.getHotelInvoiceDetailById,
        resetHotel: actions.hotel.resetHotel,
        getHotel: actions.hotel.getHotel,
        getBrandFinanceDetailByBrandId: actions.brand.getBrandFinanceDetailByBrandId,
        onloadBrand: actions.brand.onloadBrand,
    }));

    ////////////////////////////////////////////////////////////////////////////////////////////////////
    const {
        createHotelInvoiceSuccess,
        createHotelInvoiceError,
        updateHotelInvoiceSuccess,
        updateHotelInvoiceError,
        getHotelInvoiceDetailSuccess,
        getHotelInvoiceDetailError,
        getHotelSuccess,
        getBrandFinanceDetailSuccess,
        getBrandFinanceDetailError,
        loadBrandSuccess
    } = useStoreState<any>((state) => ({
        createHotelInvoiceSuccess: state.hotel.createHotelInvoiceSuccess,
        createHotelInvoiceError: state.hotel.createHotelInvoiceError,
        updateHotelInvoiceSuccess: state.hotel.updateHotelInvoiceSuccess,
        updateHotelInvoiceError: state.hotel.updateHotelInvoiceError,
        getHotelInvoiceDetailSuccess: state.hotel.getHotelInvoiceDetailSuccess,
        getHotelInvoiceDetailError: state.hotel.getHotelInvoiceDetailError,
        getHotelSuccess: state.hotel.getHotelSuccess,
        getBrandFinanceDetailSuccess: state.brand.getBrandFinanceDetailSuccess,
        getBrandFinanceDetailError: state.brand.getBrandFinanceDetailError,
        loadBrandSuccess: state.brand.loadBrandSuccess,
    }));

    ////////////////////////////////////////////////////////////////////////////////////////////////////
    useEffect(() => {
        if (hotelId) {
            getHotelInvoiceDetailById(hotelId);
            getHotel(hotelId);
        }
    }, [hotelId, getHotelInvoiceDetailById, getHotel]);

    ////////////////////////////////////////////////////////////////////////////////////////////////////
    useEffect(() => {
        if (brandId) {
            getBrandFinanceDetailByBrandId(brandId);
        }
    }, [brandId, getBrandFinanceDetailByBrandId]);

    useEffect(() => {
        if (loadBrandSuccess) {
            setBrandDomains(loadBrandSuccess.data?.allowedDomains);
        }
    }, [loadBrandSuccess]);

    //////////////////////////////////////////////////////////////////////////////////////////////
    useEffect(() => {
        if (getHotelInvoiceDetailSuccess) {
            const hotelInvoice = getHotelInvoiceDetailSuccess.data;
            if (hotelInvoice) {

                const params: any = {};
                params.emailAddressForSubmission = hotelInvoice.emailAddressForSubmission || '';
                params.nameOfContact = hotelInvoice.nameOfContact || '';
                params.phone = hotelInvoice.phone || '';
                params.invoiceIssuing = hotelInvoice.invoiceIssuing;
                setInvoiceData(params);
                setHotelInvoiceId(hotelInvoice._id);
                setInvoiceIssuing(hotelInvoice.invoiceIssuing);
                let includeList: Array<string> = ['Property Direct'];
                const isFilter = _.first(_.filter(hotelInvoice.invoiceIssuing, value => _.includes(includeList, value)));
                if (isFilter) {
                    setIsPropertyDirectly(true);
                } else {
                    setIsPropertyDirectly(false);
                }
            }
        }
        if (getHotelInvoiceDetailError) {
            toast.error(getHotelInvoiceDetailError.message, {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'foo-bar'
            });
        }

        if (getHotelSuccess) {
            setHotelData(getHotelSuccess.data);
            onloadBrand(getHotelSuccess.data?.brandId?._id);
        }

    }, [getHotelInvoiceDetailError, getHotelInvoiceDetailSuccess, getHotelSuccess, onloadBrand]);


    //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    useEffect(() => {
        if (getBrandFinanceDetailSuccess) {
            const brandFinance = getBrandFinanceDetailSuccess.data;
            if (brandFinance) {
                let includeList: Array<string> = ['Head Office'];
                let includeData: Array<string> = ['Individual Properties'];
                const isFilter = _.first(_.filter(brandFinance.invoiceIssuing, value => _.includes(includeList, value)));
                if (isFilter) {
                    setIsHeadOffice(true);
                    setInvoiceIssuing(includeList);
                } else {
                    setIsHeadOffice(false);
                }
                const isData = _.first(_.filter(brandFinance.invoiceIssuing, value => _.includes(includeData, value)));
                if (isData && !isHeadOffice) {
                    setIsHotel(true);
                    setInvoiceIssuing([]);
                } else if (isData && isHeadOffice) {
                    setIsHotel(true);
                } else {
                    setIsHotel(false);
                }
            }
        }
        if (getBrandFinanceDetailError) {
            toast.error(getBrandFinanceDetailError.message, {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'foo-bar'
            });
        }
    }, [brandId, getBrandFinanceDetailError, getBrandFinanceDetailSuccess, isHeadOffice, invoiceIssuing]);


    useEffect(() => {
        let includeList: Array<string> = ['Head Office'];
        const isFilter = _.first(_.filter(invoiceIssuing, value => _.includes(includeList, value)));
        if (isFilter) {
            if (invoiceData.emailAddressForSubmission) {
                const data = {
                    id: hotelInvoiceId,
                    invoiceIssuing: invoiceIssuing,
                    emailAddressForSubmission: '',
                    nameOfContact: '',
                    phone: ''
                }
                updateHotelInvoiceDetail(data);
            }
        }
    }, [updateHotelInvoiceDetail, invoiceData.emailAddressForSubmission, hotelInvoiceId, invoiceIssuing]);

    ///////////////////////////////////////////////////////////////////////////////////////////////
    useEffect(() => {
        if (createHotelInvoiceSuccess) {
            toast.success("Hotel invoice created successfully", {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'foo-bar'
            });
            resetHotel();
            setLoading(false);
            setEditEnable(false);
            getHotelInvoiceDetailById(hotelId);
        }

        if (updateHotelInvoiceSuccess) {
            toast.success("Hotel invoice updated successfully", {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'foo-bar'
            });
            resetHotel();
            setLoading(false);
            setEditEnable(false);
            getHotelInvoiceDetailById(hotelId);
        }

        if (createHotelInvoiceError) {
            toast.error(createHotelInvoiceError.message, {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'foo-bar'
            });
            resetHotel();
            setLoading(false);
        }

        if (updateHotelInvoiceError) {
            toast.error(updateHotelInvoiceError.message, {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'foo-bar'
            });
            resetHotel();
            setLoading(false);
        }

    }, [createHotelInvoiceError, createHotelInvoiceSuccess, getHotelInvoiceDetailById, hotelId, resetHotel, updateHotelInvoiceError, updateHotelInvoiceSuccess]);

    ////////////////////////////////////////////////////////////////////////////////////////////////////
    const onCheckChange = useCallback((list) => {
        let includeList: Array<string> = ['Property Direct'];
        const isFilter = _.first(_.filter(list, value => _.includes(includeList, value)));
        if (isFilter) {
            setIsPropertyDirectly(true);
            setValue("emailAddressForSubmission", invoiceData ? invoiceData.emailAddressForSubmission : '');
            setValue("nameOfContact", invoiceData ? invoiceData.nameOfContact : '');
            setValue("phone", invoiceData?.phone ? invoiceData.phone : '');
        } else {
            setIsPropertyDirectly(false);
            setValue("emailAddressForSubmission", '');
            setValue("nameOfContact", '');
            setValue("phone", '');
            setPhone('');
        }
        setInvoiceIssuing(list);
    }, [invoiceData, setValue]);

    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    const onFinish = (values) => {
        if (isPropertyDirectly) {
            if (!validPhoneNumber) {
                toast.error('Please enter valid phone number!', {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    className: 'foo-bar'
                });
                return;
            }
        }
        if (values.emailAddressForSubmission) {
            // check domain
            const getDomainValidation = getMailDomain(values.emailAddressForSubmission, brandDomains);
            if (!getDomainValidation) {
                toast.error('Invalid brand domain. please check your e-mail address domain with brand domains', {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    className: 'foo-bar'
                });
                return;
            }
        }
        setLoading(true);
        if (hotelInvoiceId) {
            values.id = hotelInvoiceId;
            if (isPropertyDirectly) {
                if (!validatePhoneNumber(values?.phone)) {
                    toast.error('Please enter valid phone number!', {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        className: 'foo-bar'
                    });
                    return;
                }
            }
            const data = {
                id: hotelInvoiceId,
                invoiceIssuing: values.invoiceIssuing,
                emailAddressForSubmission: values.emailAddressForSubmission,
                nameOfContact: values.nameOfContact,
                phone: values.phone

            }
            updateHotelInvoiceDetail(data);
        } else {
            const data = {
                hotelId: hotelId,
                invoiceIssuing: invoiceIssuing,
                emailAddressForSubmission: values.emailAddressForSubmission,
                nameOfContact: values.nameOfContact,
                phone: phone
            }
            createHotelInvoiceDetail(data);
        }
    };

    const changeFormEditable = useCallback(() => {
        if (editEnable) {
            setEditEnable(false);
        } else {
            setEditEnable(true);
        }
    }, [editEnable]);


    const validatePhoneNumber = (inputNumber: string) => {
        let number = formatPhoneNumber(inputNumber)

        let valid = false;
        let isPossible = false;
        try {
            const phoneUtil = PhoneNumberUtil.getInstance();
            isPossible = phoneUtil.isPossibleNumber(phoneUtil.parse(number))
            valid = phoneUtil.isValidNumber(phoneUtil.parse(number));
        } catch (e) {
            valid = false;
        }

        if (isPossible && valid) {

            setValidPhoneNumber(true);
            return true;
        } else {
            setValidPhoneNumber(false);
            return false;
        }
    };

    ////////////////////////////////////////////////////////////////////////////////////////////////////
    return (
        <>
            <div className="content-title fixed_title">
                {
                    <BreadCrumbPage allInOneHotelBreadCrumb={allInOneHotelBreadCrumb} tab={tab} isPageName="Hotel" selectedBrand={selectedBrand} hotelData={hotelData} setFormOpen={setFormOpen} ></BreadCrumbPage>
                }
                <div className='edit_btn_wpr'>
                    <div className='d-flex align-items-center justify-content-end gx-2'>
                        {
                            !loading && !editEnable && hotelId && canBrandHotelModuleWrite(brandId, hotelId, UserAccess.hotel_access.details.code) && <Button id='edit-button' className='mb-0' hidden={isHeadOffice && !isHotel} style={formButton} onClick={changeFormEditable} >Edit</Button>
                        }
                    </div>
                </div>
            </div>
            <div>
                <div className="jumbotron">
                    <Form onSubmit={handleSubmit(onFinish)}>
                        <Row>
                            <Col md={1}></Col>
                            <Col md={10}>
                                <Row className='formSpace'>
                                    <Col md={6} style={{ textAlign: 'right' }}>
                                        <Form.Label>{editEnable && <span className='requiredMark'>*</span>} Invoice issuing (Payment of booking fees)</Form.Label>
                                    </Col>
                                    <Col md={6} style={{ textAlign: 'left' }}>
                                        {!editEnable && <p className="lead">
                                            <Checkbox.Group value={invoiceIssuing} options={plainOptions}>
                                            </Checkbox.Group>
                                        </p>}
                                        {editEnable &&
                                            <Controller
                                                name="invoiceIssuing"
                                                defaultValue={invoiceData?.invoiceIssuing}
                                                control={control}
                                                rules={{
                                                    required: true,
                                                }}
                                                render={({ field }) =>
                                                    <Checkbox.Group onChange={(value) => {
                                                        onCheckChange(value);
                                                        field.onChange(value);
                                                    }} value={invoiceIssuing} options={plainOptions}>
                                                    </Checkbox.Group>
                                                }
                                            />}
                                        {editEnable &&
                                            <ErrorMessage
                                                errors={errors}
                                                name="invoiceIssuing"
                                                render={({ message }) => <div className="invalid-feedback-custom">Please select invoice issuing!</div>} />}
                                    </Col>
                                </Row>
                                <Row className='formSpace'>
                                    <Col md={6} style={{ textAlign: 'right' }}>
                                        <Form.Label>{editEnable && isPropertyDirectly && <span className='requiredMark'>*</span>} Email address for submission</Form.Label>
                                    </Col>
                                    <Col md={6} style={{ textAlign: 'left' }}>
                                        {!editEnable && <p className="lead">{invoiceData?.emailAddressForSubmission}</p>}
                                        {editEnable && <Form.Control type='email' className={`${errors.emailAddressForSubmission ? 'is-invalid' : ''}`} defaultValue={invoiceData?.emailAddressForSubmission} {...register("emailAddressForSubmission", {
                                            required: (isPropertyDirectly) ? true : false,
                                            pattern: {
                                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                                message: "Please input correct email address for submission!"
                                            }
                                        })} />}
                                        {editEnable && <div className="invalid-feedback">Please input email address for submission!</div>}
                                    </Col>
                                </Row>
                                <Row className='formSpace'>
                                    <Col md={6} style={{ textAlign: 'right' }}>
                                        <Form.Label>{editEnable && isPropertyDirectly && <span className='requiredMark'>*</span>} Name of contact</Form.Label>
                                    </Col>
                                    <Col md={6} style={{ textAlign: 'left' }}>
                                        {!editEnable && <p className="lead">{invoiceData?.nameOfContact}</p>}
                                        {editEnable && <Form.Control className={`${errors.nameOfContact ? 'is-invalid' : ''}`} defaultValue={invoiceData?.nameOfContact} {...register("nameOfContact", { required: (isPropertyDirectly) ? true : false })} />}
                                        {editEnable && <div className="invalid-feedback">Please input name of contact!</div>}
                                    </Col>
                                </Row>
                                <Row className='formSpace'>
                                    <Col md={6} style={{ textAlign: 'right' }}>
                                        <Form.Label>{editEnable && isPropertyDirectly && <span className='requiredMark'>*</span>} Phone</Form.Label>
                                    </Col>
                                    <Col md={6} style={{ textAlign: 'left' }}>
                                        {!editEnable && invoiceData?.phone && <p className="lead">{formatPhoneNumber(invoiceData?.phone)}</p>}
                                        {editEnable &&
                                            <Controller
                                                name="phone"
                                                control={control}
                                                defaultValue={invoiceData?.phone}
                                                rules={{
                                                    required: (isPropertyDirectly) ? true : false,
                                                }}
                                                render={({ field }) =>
                                                    <PhoneInput
                                                inputProps={{ id: "phone" }}
                                                        placeholder=''
                                                        isValid={(inputNumber: string) => {
                                                            if (isPropertyDirectly) {
                                                                return isPropertyDirectly ? validatePhoneNumber(inputNumber) : false;
                                                            }
                                                            return true;
                                                        }}
                                                        containerClass={`${errors.phone ? 'is-invalid' : ''}`} value={String(field.value)} country='gb' onChange={(phone) => { field.onChange(phone); setPhone(phone); }} />}
                                            />
                                        }
                                        {editEnable && <div className="invalid-feedback">Please input phone number!</div>}
                                    </Col>
                                </Row>
                                <Row className='formSpace'>
                                    <Col md={6} style={{ textAlign: 'right' }}>
                                    </Col>
                                    <Col md={6} style={{ textAlign: 'left' }}>
                                        {!loading && !hotelInvoiceId && editEnable && hotelId && canBrandHotelModuleWrite(brandId, hotelId, UserAccess.hotel_access.details.code) &&
                                            <Button type="submit" id='approve-button' style={formButton}>Create</Button>
                                        }
                                        {!loading && hotelInvoiceId && editEnable && hotelId && canBrandHotelModuleWrite(brandId, hotelId, UserAccess.hotel_access.details.code) &&
                                            <Button type="submit" id='approve-button' style={formButton}>Update</Button>
                                        }

                                        {
                                            !loading && isHeadOffice && !isHotel && !editEnable && hotelId && canBrandHotelModuleWrite(brandId, hotelId, UserAccess.hotel_access.details.code) && <Button type="submit" id='approve-button' style={formButton} hidden={hotelInvoiceId ? true : false} >Confirm</Button>
                                        }
                                        {
                                            !loading && isHeadOffice && !isHotel && hotelInvoiceId && !editEnable && hotelId && canBrandHotelModuleWrite(brandId, hotelId, UserAccess.hotel_access.details.code) && <Button type="submit" id='approve-button' style={formButton} >Confirmed</Button>
                                        }
                                        {
                                            loading && <div style={{ textAlign: 'center' }}><Spinner animation="border" variant="warning" /></div>
                                        }
                                    </Col>
                                </Row>
                            </Col>
                            <Col md={1}></Col>
                        </Row>
                    </Form>
                </div>
            </div>
        </>

    )
};

export default HotelInvoice;