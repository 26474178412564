import { FC, useState, useEffect } from "react";
import { useStoreActions, useStoreState } from 'easy-peasy';
import DataTable from "react-data-table-component";
import { selectStylesLittleRect, tableStyles } from "../../../../common/components-style";
import RFPBreadCrumbPage from "../../../common/RFPBredcrumPage";
import { Row, Col, Spinner } from 'react-bootstrap';
import Select from 'react-select';
import { useHistory } from "react-router-dom";

interface ProgrammeOption {
    value: string;
    label: string;
}

const BrandOverviewData: FC<any> = ({ reportId, programmeId, setFormOpen, reload, selectedClient, selectedProgramme, accommodationType, reportName, currency, associateProgrammes, location }): JSX.Element => {
    const [data, setData] = useState<Array<any>>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [programmeList, setProgrammeList] = useState<any>([]);
    const [filteredData, setFilteredData] = useState<any[]>([]);
    const [currencyName, setCurrencyName] = useState<any>(null);
    const [selectedAssociateProgramme, setSelectedAssociateProgramme] = useState<ProgrammeOption | null>(null);
    const [chain, setChain] = useState<any>(null);
    const [summaryData, setSummaryData] = useState<any>({});
    const history = useHistory();

    const { getReportDataBrandOverview, getReportById } = useStoreActions<any>((actions) => ({
        getReportById: actions.booking.getReportById,
        getReportDataBrandOverview: actions.hotel.getReportDataBrandOverview
    }));

    /**
     * global state
     */
    const { getReportByIdError, getReportByIdSuccess, getReportDataBrandOverviewSuccess } = useStoreState<any>((state) => ({
        getReportByIdError: state.booking.getReportByIdError,
        getReportByIdSuccess: state.booking.getReportByIdSuccess,
        getReportDataBrandOverviewSuccess: state.hotel.getReportDataBrandOverviewSuccess,

    }));


    useEffect(() => {
        if (location?.state?.selectedChain) {
            setChain(location?.state?.selectedChain);
        }
    }, [location?.state?.selectedChain]);

    useEffect(() => {
        if (getReportByIdSuccess?.data) {
            setCurrencyName(getReportByIdSuccess?.data?.currency);
        }
    }, [getReportByIdError, getReportByIdSuccess?.data]);


    useEffect(() => {
        if (reportId && chain) {
            setLoading(true);
            const reportPayload = { _id: reportId };
            getReportById(reportPayload);
            const payload = {
                reportId: reportId,
                programmeId: selectedAssociateProgramme ? selectedAssociateProgramme?.value : '',
                parentAccommodationCompany: chain,
            };
            getReportDataBrandOverview(payload)
        }
    }, [getReportById, getReportDataBrandOverview, reportId, selectedAssociateProgramme, chain]);


    useEffect(() => {
        if (getReportDataBrandOverviewSuccess?.data) {
            setLoading(false);
            setData(getReportDataBrandOverviewSuccess?.data?.data);
            setSummaryData(getReportDataBrandOverviewSuccess?.data?.summary);
            setFilteredData(getReportDataBrandOverviewSuccess?.data?.data);
        }
    }, [getReportDataBrandOverviewSuccess]);

    const formatPercentageValue = (value: number | undefined): string => {
        if (value !== undefined) {
            if (value === 0 || value === 0.0) {
                return '0';
            }
            const roundedValue = Math.round(value * 10) / 10;
            if (roundedValue === 0 || roundedValue === 0.0) {
                return '0';
            }
            return roundedValue.toLocaleString(undefined, {
                minimumFractionDigits: 1,
                maximumFractionDigits: 1,
            });
        }
        return '0';
    };

    const formatDataValue = (value: number | undefined): string => {
        if (value !== undefined) {
            return Math.round(value).toLocaleString();
        }
        return '0';
    };

    const getCurrencyDetails = (currencyCode) => {
        const currencyData = require('../../../../common/currencyReport.json');
        const currency = currencyData.find(c => c.cc === currencyCode);
        return currency ? { symbol: currency.symbol, name: currency.name } : null;
    };

    const currencyDetails = getCurrencyDetails(currencyName);
    const currencySymbol = currencyDetails ? currencyDetails.symbol : '';

    const redirectChainData = (row) => {
        history.push('/admin/chain-overview-data', {
            programmeId,
            selectedClient,
            reportId,
            reportName,
            currency,
            accommodationType,
            associateProgrammes,
            selectedBrand: row?.brandId,
            selectedChain: row?.chain
        });
    };


    const columns = [
        {
            name: "Brand",
            cell: (row) => (
                <span className="chain_hover"
                    style={{ cursor: "pointer" }}
                    onClick={() => redirectChainData(row)}
                >
                    {row?.brandName}
                </span>
            ),
        },
        {
            name: "Nights",
            sortable: true,
            selector: (row) => row?.totalNights,
            cell: (row) => {
                const fieldValue = row?.totalNights || 0;
                return `${formatDataValue(fieldValue)}`;
            },
        },
        {
            name: "% Chain Nights",
            sortable: true,
            selector: (row) => row?.chainNightsPercentage,
            cell: (row) => {
                const fieldValue = row?.chainNightsPercentage || 0
                return `${formatPercentageValue(fieldValue)}%`;
            },
        },
        {
            name: "Spend",
            sortable: true,
            selector: (row) => row?.totalSpend,
            cell: (row) => {
                const fieldValue = row?.totalSpend || 0
                return `${currencySymbol}${formatDataValue(fieldValue)}`;
            },
        },
        {
            name: "% Chain Spend",
            sortable: true,
            selector: (row) => row?.chainSpendPercentage,
            cell: (row) => {
                const fieldValue = row?.chainSpendPercentage || 0
                return `${formatPercentageValue(fieldValue)}%`;
            },
        },
    ];

    useEffect(() => {
        if ((associateProgrammes && associateProgrammes.length > 0) || selectedProgramme?.programmeName) {
            const options = [
                ...(associateProgrammes || [])
                    .filter((item: string) => item !== 'None')
                    .map((item: string) => ({
                        value: item,
                        label: item,
                    })),
                selectedProgramme?.programmeName && {
                    value: selectedProgramme.programmeName,
                    label: selectedProgramme.programmeName,
                },
            ].filter(Boolean);

            setProgrammeList(options);
        } else {
            setProgrammeList([]);
        }
    }, [associateProgrammes, selectedProgramme]);

    const handleProgrammeChange = (selectedOption) => {
        setSelectedAssociateProgramme(selectedOption);
    };

    useEffect(() => {
        let filtered = data;
        setFilteredData(filtered);
    }, [data]);


    return (
        <>
            <div className="col-md-12" style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <div>
                    {reportId && (
                        <RFPBreadCrumbPage
                            isPageName="chainOverviewReport"
                            selectedClient={selectedClient}
                            selectedProgramme={selectedProgramme}
                            selectedClientName={selectedClient?.companyName}
                            selectedProgrammeName={selectedProgramme?.programmeName}
                            reportId={reportId}
                            programmeId={programmeId}
                            startDate={reportName}
                            tab="Brand Overview"
                        />
                    )}
                </div>
            </div>
            <Row className='btn_wrapper' style={{ marginTop: '65px' }}>
                <h3 className="chain-title">{chain}</h3>
                <Row>
                    <Col xs={5}>
                        <div className="chain-sec">
                            <div className="chain-list">
                                <ul>
                                    <li></li>
                                    <li>Spend</li>
                                    <li>Nights</li>
                                </ul>
                            </div>
                            <div className="chain-list">
                                <ul>
                                    <li>Cont</li>
                                    <li>{currencySymbol}{formatDataValue(summaryData?.totalContractedSpend)}</li>
                                    <li>{currencySymbol}{formatDataValue(summaryData?.totalContractedNights)}</li>
                                </ul>
                            </div>
                            <div className="chain-list">
                                <ul>
                                    <li>Non Cont</li>
                                    <li>{currencySymbol}{formatDataValue(summaryData?.totalNonContractedSpend)}</li>
                                    <li>{formatDataValue(summaryData?.totalNonContractedNights)}</li>
                                </ul>
                            </div>
                            <div className="chain-list">
                                <ul>
                                    <li>Total</li>
                                    <li>{currencySymbol}{formatDataValue(summaryData?.totalAllSpend)}</li>
                                    <li>{formatDataValue(summaryData?.totalRoomNights)}</li>
                                </ul>
                            </div>
                        </div>
                    </Col>
                    <Col xs={3}>
                        {programmeList.length > 0 ? (
                            <Select
                                className="reportTableButton"
                                isClearable
                                placeholder="Search Programme"
                                styles={selectStylesLittleRect}
                                options={programmeList}
                                value={selectedAssociateProgramme}
                                onChange={handleProgrammeChange}
                            />
                        ) : null}
                        <Row>
                            <Col xs={2} style={{ display: "flex", alignItems: "center", justifyContent: 'left', marginTop: '10px' }}>
                                {loading && (<Spinner animation="border" variant="warning" />)}
                            </Col>
                        </Row>
                    </Col>

                </Row>
            </Row>
            {
                !loading && <div className='brnd_tbl' style={{ marginTop: '0px' }}>
                    <DataTable className="reports_table reports_table_chain"
                        columns={columns}
                        data={filteredData}
                        pagination
                        striped={true}
                        customStyles={tableStyles}
                        theme="mysaTable"
                        fixedHeader={true}
                        paginationPerPage={25}
                        paginationRowsPerPageOptions={[25, 50, 100]}
                        noDataComponent="Updating records"
                    />
                </div>
            }
        </>
    )
}

export default BrandOverviewData;