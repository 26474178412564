import { FC, useState, useEffect } from "react";
import { useStoreActions, useStoreState } from 'easy-peasy';
import DataTable from "react-data-table-component";
import { tableStyles, selectStylesLittleRect } from "../../../../common/components-style";
import RFPBreadCrumbPage from "../../../common/RFPBredcrumPage";
import { Row, Col, Spinner } from 'react-bootstrap';
import Select from 'react-select';

// interface ProgrammeOption {
//     value: string;
//     label: string;
// }

const NonContractedPropertyData: FC<any> = ({ reportId, programmeId, setFormOpen, reload, selectedClient, selectedProgramme, accommodationType, reportName, currency, associateProgrammes }): JSX.Element => {
    const [data, setData] = useState<Array<any>>([]);
    const [loading, setLoading] = useState<boolean>(true);
    // const [programmeList, setProgrammeList] = useState<any>([]);
    const [selectedCountry, setSelectedCountry] = useState<string | null>(null);
    const [selectedCity, setSelectedCity] = useState<string | null>(null);
    const [filteredData, setFilteredData] = useState<any[]>([]);
    const [currencyName, setCurrencyName] = useState<any>(null);
    // const [selectedAssociateProgramme, setSelectedAssociateProgramme] = useState<ProgrammeOption | null>(null);

    const { getReportDataNonContracted, getReportById } = useStoreActions<any>((actions) => ({
        getReportById: actions.booking.getReportById,
        getReportDataNonContracted: actions.hotel.getReportDataNonContracted
    }));

    /**
     * global state
     */
    const { getReportByIdError, getReportByIdSuccess, getReportDataNonContractedPropertiesSuccess } = useStoreState<any>((state) => ({
        getReportByIdError: state.booking.getReportByIdError,
        getReportByIdSuccess: state.booking.getReportByIdSuccess,
        getReportDataNonContractedPropertiesSuccess: state.hotel.getReportDataNonContractedPropertiesSuccess,

    }));

    useEffect(() => {
        if (getReportByIdSuccess?.data) {
            setCurrencyName(getReportByIdSuccess?.data?.currency);
        }
    }, [getReportByIdError, getReportByIdSuccess?.data]);


    useEffect(() => {
        if (reportId) {
            setLoading(true);
            const reportPayload = { _id: reportId };
            getReportById(reportPayload);
            const payload = {
                reportId: reportId,
                //  programmeId: selectedAssociateProgramme ? selectedAssociateProgramme?.value : '',
                programmeId: '',
            };
            // if (selectedAssociateProgramme) {
            //     getReportDataNonContracted(payload)
            // }
            // else {
            //     getReportDataNonContracted(payload)
            // }
            getReportDataNonContracted(payload)
        }
    }, [getReportById, reportId, getReportDataNonContracted]);


    useEffect(() => {
        if (getReportDataNonContractedPropertiesSuccess?.data) {
            setLoading(false);
            setData(getReportDataNonContractedPropertiesSuccess?.data);
            setFilteredData(getReportDataNonContractedPropertiesSuccess?.data);
        }
    }, [getReportDataNonContractedPropertiesSuccess]);

    const formatPercentageValue = (value: number | undefined): string => {
        if (value !== undefined) {
            if (value === 0 || value === 0.0) {
                return '0';
            }
            const roundedValue = Math.round(value * 10) / 10;
            if (roundedValue === 0 || roundedValue === 0.0) {
                return '0';
            }
            return roundedValue.toLocaleString(undefined, {
                minimumFractionDigits: 1,
                maximumFractionDigits: 1,
            });
        }
        return '0';
    };

    const formatPercentageTwoPointsValue = (value: number | undefined): string => {
        if (value !== undefined) {
            if (value === 0) {
                return '0';
            }
            const roundedValue = Math.round(value * 100) / 100;
            if (roundedValue === 0) {
                return '0';
            }
            return roundedValue.toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            });
        }
        return '0';
    };

    const formatDataValue = (value: number | undefined): string => {
        if (value !== undefined) {
            return Math.round(value).toLocaleString();
        }
        return '0';
    };

    const getCurrencyDetails = (currencyCode) => {
        const currencyData = require('../../../../common/currencyReport.json');
        const currency = currencyData.find(c => c.cc === currencyCode);
        return currency ? { symbol: currency.symbol, name: currency.name } : null;
    };

    const currencyDetails = getCurrencyDetails(currencyName);
    const currencySymbol = currencyDetails ? currencyDetails.symbol : '';

    const columns = [
        {
            name: "City",
            selector: (row) => row?.city,
            width: "120px"
        },
        {
            name: "Country",
            selector: (row) => row?.country,
            width: "120px"
        },
        {
            name: "Chain",
            cell: (row) => <span>{row?.hotelChain}</span>,
            width: "150px"
        },
        {
            name: "Brand",
            cell: (row) => <span>{row?.brandName}</span>,
            width: "150px"
        },
        {
            name: "Property",
            cell: (row) => <span>{row?.hotelName}</span>,
        },
        {
            name: `Nights`,
            sortable: true,
            selector: (row) => row?.nonContractedNights || 0,
            cell: (row) => {
                const fieldValue = row?.nonContractedNights || 0;
                return `${formatDataValue(fieldValue)}`;
            },
            width: "105px"
        },
        {
            name: "Loc %",
            sortable: true,
            selector: (row) => row?.locPercentage || 0,
            cell: (row) => {
                const fieldValue = row?.locPercentage || 0;
                return `${formatPercentageValue(fieldValue)}%`;
            },
            width: "100px"
        },
        {
            name: `Spend`,
            sortable: true,
            selector: (row) => row?.nonContractedSpend || 0,
            cell: (row) => {
                const fieldValue = row?.nonContractedSpend || 0;
                return `${currencySymbol}${formatDataValue(fieldValue)}`;
            },
            width: "110px"

        },
        {
            name: `ABR`,
            sortable: true,
            selector: (row) => row?.ABR || 0,
            cell: (row) => {
                const fieldValue = row?.ABR || 0;
                return <strong>{`${currencySymbol}${formatPercentageTwoPointsValue(fieldValue)}`}</strong>
            },
            width: "110px"
        },
        {
            name: "Loc Ave",
            sortable: true,
            selector: (row) => row?.locAve || 0,
            cell: (row) => {
                const fieldValue = row?.locAve || 0;
                return <strong>{`${currencySymbol}${formatPercentageTwoPointsValue(fieldValue)}`}</strong>
            },
            width: "120px"
        },
        {
            name: `Cost/Saving`,
            selector: (row) => row?.costSaving || 0,
            cell: (row) => {
                const fieldValue = row?.costSaving || 0;
                const absoluteValue = Math.abs(fieldValue);
                const formattedValue = `${currencySymbol}${formatDataValue(absoluteValue)}`;

                return (
                    <span style={{ color: fieldValue > 0 ? 'red' : '#52d1c2', fontWeight: 'bold' }}>
                        {formattedValue}
                    </span>
                );
            },
            sortable: true,
            width: "140px"
        }
    ];



    // useEffect(() => {
    //     if ((associateProgrammes && associateProgrammes.length > 0) || selectedProgramme?.programmeName) {
    //         const options = [
    //             ...(associateProgrammes || [])
    //                 .filter((item: string) => item !== 'None')
    //                 .map((item: string) => ({
    //                     value: item,
    //                     label: item,
    //                 })),
    //             selectedProgramme?.programmeName && {
    //                 value: selectedProgramme.programmeName,
    //                 label: selectedProgramme.programmeName,
    //             },
    //         ].filter(Boolean);

    //         setProgrammeList(options);
    //     } else {
    //         setProgrammeList([]);
    //     }
    // }, [associateProgrammes, selectedProgramme]);

    const uniqueCountries = Array.from(
        new Set(data.map((item) => item.country))
    ).filter(Boolean);

    const countryOptions = uniqueCountries.map(country => ({
        label: country,
        value: country
    }));

    const uniqueCity = Array.from(
        new Set(data.map((item) => item.city))
    ).filter(Boolean);

    const cityOptions = uniqueCity.map(city => ({
        label: city,
        value: city
    }));

    // const handleProgrammeChange = (selectedOption) => {
    //     setSelectedAssociateProgramme(selectedOption);
    // };

    useEffect(() => {
        let filtered = data;

        if (selectedCountry) {
            filtered = filtered.filter((hotel) => hotel.country === selectedCountry);
        }

        if (selectedCity) {
            filtered = filtered.filter((hotel) => hotel.city === selectedCity);
        }

        setFilteredData(filtered);
    }, [selectedCountry, selectedCity, data]);


    return (
        <>
            <div className="col-md-12" style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <div>
                    {reportId && (
                        <RFPBreadCrumbPage
                            isPageName="contractedProperties"
                            selectedClient={selectedClient}
                            selectedProgramme={selectedProgramme}
                            selectedClientName={selectedClient?.companyName}
                            selectedProgrammeName={selectedProgramme?.programmeName}
                            reportId={reportId}
                            programmeId={programmeId}
                            startDate={reportName}
                            tab="Non-Contracted Statistics"
                        />
                    )}
                </div>
            </div>

            <Row className='btn_wrapper fix_header' style={{ marginTop: '65px', paddingBottom: '40px' }}>
                <Col xs={2}>
                    <Select
                        className='reportTableButton'
                        isClearable
                        placeholder={<div>Search Country</div>}
                        options={countryOptions}
                        styles={selectStylesLittleRect}
                        isSearchable={true}
                        onChange={(option) => setSelectedCountry(option ? option.value : null)}
                    />
                </Col>

                <Col xs={2}>
                    <Select
                        className='reportTableButton'
                        isClearable
                        placeholder={<div>Search City</div>}
                        options={cityOptions}
                        styles={selectStylesLittleRect}
                        isSearchable={true}
                        onChange={(option) => setSelectedCity(option ? option.value : null)}
                    />
                </Col>
                {/* <Col xs={3}>
                    {programmeList.length > 0 ? (
                        <Select
                            className="reportTableButton"
                            isClearable
                            placeholder="Search Programme"
                            styles={selectStylesLittleRect}
                            options={programmeList}
                            value={selectedAssociateProgramme}
                            onChange={handleProgrammeChange}
                        />
                    ) : null}
                </Col> */}
                <Col xs={1} style={{ display: "flex", alignItems: "center" }}>
                    {loading && (<Spinner animation="border" variant="warning" />)}
                </Col>
            </Row>
            {
                !loading && <div className='brnd_tbl contractedProperty' style={{ marginTop: '160px' }}>
                    <DataTable className="reports_table"
                        columns={columns}
                        data={filteredData}
                        pagination
                        striped={true}
                        customStyles={tableStyles}
                        theme="mysaTable"
                        fixedHeader={true}
                        paginationPerPage={25}
                        paginationRowsPerPageOptions={[25, 50, 100]}
                        noDataComponent="Updating records"
                    />
                </div>
            }
        </>
    )
}

export default NonContractedPropertyData;