import { Checkbox } from "antd";
import { useStoreActions, useStoreState } from "easy-peasy";
import React, { useCallback, useEffect, useState } from "react";
import _ from "lodash";
import { AmenityType } from "../../../../common/constants";
import { canBrandHotelModuleWrite } from "../../../../common/functions";
import { UserAccess } from "../../../../common/constants";
import {
	Col,
	Form,
	Row,
	Button,
	Spinner,
	Image,
} from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { HotelAssignAmenityFormInput } from "../../../../common/interfaces";
import { formButton } from "../../../../common/components-style";
import { toast } from 'react-toastify';
import BreadCrumbPage from '../../../common/BreadCrumbPage';

const HotelAmenities: React.FC<any> = ({
	allInOneHotelBreadCrumb,
	setFormOpen,
	hotelId,
	brandId,
	selectedBrand,
	tab
}): JSX.Element => {
	const {
		handleSubmit,
		formState: { errors },
		control,
	} = useForm<HotelAssignAmenityFormInput>();
	const [hotelData, setHotelData] = useState<any>();
	const [editEnable, setEditEnable] = useState<boolean>(false);
	const [loading, setLoading] = useState<boolean>(false);
	const [amenityId, setAmenityId] = useState<Array<Object> | null>(null);
	const [amenitiesList, setAmenitiesList] = useState<any>();

	////////////////////////////////////////////////////////////////////////////////////////////////////
	const {
		getAmenityList,
		getHotelAmenities,
		assignHotelAmenity,
		resetHotel,
		getHotel,
	} = useStoreActions<any>((actions) => ({
		getAmenityList: actions.hotel.getAmenityList,
		getHotelAmenities: actions.hotel.getHotelAmenities,
		assignHotelAmenity: actions.hotel.assignHotelAmenity,
		resetHotel: actions.hotel.resetHotel,
		getHotel: actions.hotel.getHotel,
	}));

	////////////////////////////////////////////////////////////////////////////////////////////////////
	const {
		getAmenityListSuccess,
		getAmenityListError,
		getHotelAmenitiesSuccess,
		getHotelAmenitiesError,
		assignHotelAmenitySuccess,
		assignHotelAmenityError,
		getHotelSuccess,
	} = useStoreState<any>((state) => ({
		getAmenityListSuccess: state.hotel.getAmenityListSuccess,
		getAmenityListError: state.hotel.getAmenityListError,
		getHotelAmenitiesSuccess: state.hotel.getHotelAmenitiesSuccess,
		getHotelAmenitiesError: state.hotel.getHotelAmenitiesError,
		assignHotelAmenitySuccess: state.hotel.assignHotelAmenitySuccess,
		assignHotelAmenityError: state.hotel.assignHotelAmenityError,
		getHotelSuccess: state.hotel.getHotelSuccess,
	}));

	//////////////////////////////////////////////////////////////////////////////////////////////////////////
	useEffect(() => {
		getAmenityList();
	}, [getAmenityList]);

	///////////////////////////////////////////////////////////////////////////////////////////////
	useEffect(() => {
		if (hotelId) {
			getHotelAmenities(hotelId);
			getHotel(hotelId);
		}
	}, [getHotel, getHotelAmenities, hotelId]);

	//////////////////////////////////////////////////////////////////////////////////////////////
	useEffect(() => {
		if (assignHotelAmenitySuccess) {
			setLoading(false);
			resetHotel();
			toast.success("Hotel amenity assigned successfully", {
				position: toast.POSITION.BOTTOM_RIGHT,
				className: 'foo-bar'
			});
			setEditEnable(false);
			getHotelAmenities(hotelId);
		}
		if (assignHotelAmenityError) {
			setLoading(false);
			toast.error(assignHotelAmenityError.message, {
				position: toast.POSITION.BOTTOM_RIGHT,
				className: 'foo-bar'
			});
		}
	}, [resetHotel, assignHotelAmenitySuccess, assignHotelAmenityError, getHotelAmenities, hotelId]);

	//////////////////////////////////////////////////////////////////////////////////////////////
	useEffect(() => {
		if (getAmenityListSuccess) {
			if (getAmenityListSuccess.data === null) {
				toast.warning("No data found", {
					position: toast.POSITION.BOTTOM_RIGHT,
					className: 'foo-bar'
				});
			} else {
				setAmenitiesList(getAmenityListSuccess.data);
			}
		}
		if (getAmenityListError) {
			toast.error(getAmenityListError.message, {
				position: toast.POSITION.BOTTOM_RIGHT,
				className: 'foo-bar'
			});
		}

		if (getHotelAmenitiesSuccess) {
			if (getHotelAmenitiesSuccess.data === null) {
			} else {
				const amenity = getHotelAmenitiesSuccess.data.amenity;
				const hotelAmenity: Array<Object> = [];
				_.map(amenity, (element) => hotelAmenity.push(element._id));
				setAmenityId(hotelAmenity);
			}
		}
		if (getHotelAmenitiesError) {
			toast.error(getHotelAmenitiesError.message, {
				position: toast.POSITION.BOTTOM_RIGHT,
				className: 'foo-bar'
			});
		}

		if (getHotelSuccess) {
			setHotelData(getHotelSuccess.data);
		}
	}, [
		getAmenityListError,
		getAmenityListSuccess,
		getHotelAmenitiesSuccess,
		getHotelAmenitiesError,
		getHotelSuccess,
	]);

	/////////////////////////////////////////////////////////////////////////////////////////////////////
	const renderAmenities = useCallback(() => {
		if (amenitiesList) {
			const data = _.filter(
				amenitiesList,
				(user) =>
					user.type === AmenityType.all || user.type === AmenityType.building
			);
			return data.map((at: any, key: number) => {
				return (
					<Row className="mt-3" key={key}>
						<Col md={2}>
							<div style={{ textAlign: "right" }}>
								<Image src={at.iconLocation} alt="" width={40} />
							</div>
						</Col>
						<Col md={10}>
							<div style={{ marginTop: "2%", marginLeft: '-9%' }}>
								<Checkbox name='amenities' value={`${at._id}`}></Checkbox><span style={{ marginLeft: '3%' }}>{at.name}</span>
							</div>
						</Col> 
					</Row>
				);
			});
		}
	}, [amenitiesList]);

	//////////////////////////////////////////////////////////////////////////////
	const onFinish = (values) => {
		setLoading(true);
		values.hotelId = hotelId;
		assignHotelAmenity(values);
	};

	const changeFormEditable = useCallback(() => {
		if (editEnable) {
			setEditEnable(false);
		} else {
			setEditEnable(true);
		}
	}, [editEnable]);

	///////////////////////////////////////////////////////////////////////////////////////////////////////
	return (
		<>
			<div className="content-title fixed_title">
				{
					<BreadCrumbPage tab={tab} allInOneHotelBreadCrumb={allInOneHotelBreadCrumb} isPageName="Hotel" selectedBrand={selectedBrand} hotelData={hotelData} setFormOpen={setFormOpen} ></BreadCrumbPage>
				}
				<div className="edit_btn_wpr">
					<div className='d-flex align-items-center justify-content-end gx-2'>
						{
							!loading && !editEnable && hotelId && canBrandHotelModuleWrite(brandId, hotelId, UserAccess.hotel_access.details.code) && <Button id='edit-button' className="mb-0" style={formButton} onClick={changeFormEditable} >Edit</Button>
						}
					</div>
				</div>
			</div>
			<div>
				<div className="jumbotron">
					<Form onSubmit={handleSubmit(onFinish)}>
						<Row>
							<Col md={12}>
								<Row className="formSpace">
									<Col md={6} style={{ textAlign: "right" }}>
										<Form.Label>
											{editEnable && <span className="requiredMark">*</span>}
											Please indicate what amenities you offer within the building
										</Form.Label>
									</Col>
									<Col md={6} style={{ textAlign: "left" }}>
										{!editEnable && <>
											{
												amenityId ?
													<Checkbox.Group value={Object(amenityId)} style={{ width: '100%' }} >
														{renderAmenities()}
													</Checkbox.Group>
													:
													<Checkbox.Group value={[]} style={{ width: '100%' }} >
														{renderAmenities()}
													</Checkbox.Group>
											}
										</>

										}
										{editEnable && (
											<Controller
												name="amenity"
												defaultValue={Object(amenityId)}
												control={control}
												rules={{
													required: true,
												}}
												render={({ field }) => (
													<>
														{amenityId ?
															<Checkbox.Group name="amenity" defaultValue={Object(amenityId)} style={{ width: '100%' }} onChange={(value) => { field.onChange(value); }}>
																{renderAmenities()}
															</Checkbox.Group>
															:
															<Checkbox.Group name="amenity" style={{ width: '100%' }} onChange={(value) => { field.onChange(value); }}>
																{renderAmenities()}
															</Checkbox.Group>
														}

													</>
												)}
											/>
										)}
										{editEnable && errors.amenity && (
											<div className="invalid-feedback-custom">
												Please select a amenities!
											</div>
										)}
									</Col>
								</Row>
								<Row className='formSpace'>
									<Col md={6} style={{ textAlign: 'right' }}>
									</Col>
									<Col md={6} style={{ textAlign: 'left' }}>
										{!loading && editEnable && hotelId && canBrandHotelModuleWrite(brandId, hotelId, UserAccess.hotel_access.details.code) &&
											<Button type="submit" id='approve-button' style={formButton}>Update</Button>
										}

										{
											loading && <div style={{ textAlign: 'center' }}><Spinner animation="border" variant="warning" /></div>
										}
									</Col>
								</Row>
							</Col>
						</Row>
					</Form>
				</div>
			</div>
		</>
	);
};

export default HotelAmenities;
