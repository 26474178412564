import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Breadcrumb, Col, Nav, Row, Tab } from 'react-bootstrap';
import ProgrammeDetailsForm from './ProgrammeDetail';
import ProgrammeUsersList from './programme-users/ProgrammeUsersList'
import { UserAccess, UserGroups } from '../../../../common/constants';
import { isCorporateProgrammeModuleAvailable, getLoggedUserType } from '../../../../common/functions';
import AccessDenied from '../../../common/AccessDenied';
import { navContentStyle, navStyle, tabDivStyle } from '../../../../common/components-style';
import BookerUsersList from './booker-users/BookerUserList';
import TravelerUsersList from './traveler-users/TravelerUsersList';
import CompanyPermission from './CompanyPermission';
import PaymentPermission from './PaymentPermission';
import UserPage from './users-programme/UserPage';
import ProgrammeSpendDataHome from '../../programme-spend-data/ProgrammeSpendDataHome'
import { getStorage } from '../../../../common/storage';


const ProgrammeForm: React.FC<any> = ({ programmeId, selectedClient, clientId, selectedProgramme, setFormOpen, refreshProgrammes, setSelectedProgramme, loadData, location }): JSX.Element => {

	const [tabKey, setTabKey] = useState<any>('details');
	const history = useHistory();


	useEffect(() => {
		if (location?.state?.tabKey) {
			setTabKey(location?.state?.tabKey);
		}
	}, [location?.state?.tabKey]);

	const redirectLocation = () => {
		history.push('/admin/location-management', { programmeId: programmeId, selectedClient: selectedClient })
	}

	const redirectRfpProcess = () => {
		history.push('/admin/rfp-management', { programmeId: programmeId, selectedClient: selectedClient })
	}


	const redirectProgramme = () => {
		history.push('/admin/corporate-programme-management', { programmeId: programmeId, selectedClient: selectedClient, selectedProgramme: selectedProgramme })
	}


	return (
		<div style={tabDivStyle}>
			<Tab.Container defaultActiveKey="details" key={tabKey} activeKey={tabKey} onSelect={(tab) => setTabKey(tab)}>
				<Row >
					<Col sm={2}>
						<Nav variant="pills" className="flex-column secondry-menu" style={navStyle}>
							<div style={{ marginTop: '0%' }}>
								<Nav.Item>
									<Nav.Link eventKey="details">Details</Nav.Link>
								</Nav.Item>

								{(programmeId && ((selectedProgramme?.optimiseProductRequirement !== 'Data Analysis Management') && (selectedProgramme?.optimiseProductRequirement !== 'Rfp Management'))) &&
									<Nav.Item>
										<Nav.Link eventKey="companyPermissions">Company Permissions</Nav.Link>
									</Nav.Item>}
								{(programmeId) && <Nav.Item>
									<Nav.Link eventKey="corporateAdmins">Administrators</Nav.Link>
								</Nav.Item>}
								{(programmeId) && <Nav.Item>
									<Nav.Link eventKey="programmeUsers">{'Programme Users'}</Nav.Link>
								</Nav.Item>}
								{(programmeId && ((selectedProgramme?.optimiseProductRequirement !== 'Data Analysis Management') && (selectedProgramme?.optimiseProductRequirement !== 'Rfp Management'))) && <Nav.Item>
									<Nav.Link eventKey="bookerManagement">Booker Management</Nav.Link>
								</Nav.Item>}
								{(programmeId && ((selectedProgramme?.optimiseProductRequirement !== 'Data Analysis Management') && (selectedProgramme?.optimiseProductRequirement !== 'Rfp Management'))) && <Nav.Item>
									<Nav.Link eventKey="travelerManagement">Traveller Management</Nav.Link>
								</Nav.Item>}
								{/* <Nav.Item>
											<Nav.Link eventKey="pa">Programme Users</Nav.Link>
										</Nav.Item> */}
								{(programmeId) && (selectedProgramme?.optimiseProductRequirement !== 'Data Analysis Management' && (selectedProgramme?.optimiseProductRequirement !== 'Rfp Management')) && <Nav.Item>
									<Nav.Link eventKey="location" onClick={redirectLocation}>Location Management</Nav.Link>
								</Nav.Item>}
								{(programmeId && ((selectedProgramme?.optimiseProductRequirement !== 'Data Analysis Management') && (selectedProgramme?.optimiseProductRequirement !== 'Rfp Management'))) && <Nav.Item>
									<Nav.Link eventKey="paymentManagement">Payment Management</Nav.Link>
								</Nav.Item>}
								{(getLoggedUserType() === UserGroups.super_admin && programmeId) && <Nav.Item>
									<Nav.Link eventKey="programmeSpendData">Programme Spend Data</Nav.Link>
								</Nav.Item>}
								{(programmeId) && (selectedProgramme?.optimiseProductRequirement !== 'Data Analysis Management') && <Nav.Item>
									<Nav.Link eventKey="rfpProcess" onClick={redirectRfpProcess}>RFP Management</Nav.Link>
								</Nav.Item>}

								{(programmeId && (selectedProgramme?.optimiseProductRequirement !== 'Data Analysis Management')) && <Nav.Item>
									<Nav.Link eventKey="programmeDetails" onClick={redirectProgramme}>Programme Details</Nav.Link>
								</Nav.Item>}


							</div>
						</Nav>
					</Col>

					<Col sm={10}>
						<Tab.Content>
							<Tab.Pane eventKey="details">
								<div style={navContentStyle}>
									{selectedClient && <Breadcrumb className="mb-10">
										<Breadcrumb.Item>
											<Link to={{ pathname: "/admin/client-management", state: { selectedClient: selectedClient } }}>Client Management</Link>
										</Breadcrumb.Item>
										<Breadcrumb.Item>
											<Link to="" onClick={(e) => { e.preventDefault(); setFormOpen(false); refreshProgrammes(); setSelectedProgramme(null) }} >{selectedClient.companyName}</Link>
										</Breadcrumb.Item>
										{selectedProgramme && <Breadcrumb.Item active={selectedProgramme ? true : false}>{selectedProgramme?.programmeName}</Breadcrumb.Item>}
										{!selectedProgramme && <Breadcrumb.Item active>New Programme</Breadcrumb.Item>}
									</Breadcrumb>}
									{isCorporateProgrammeModuleAvailable(clientId, programmeId, UserAccess.programme_access.programmeAdmin.code) ? <ProgrammeDetailsForm programmeId={programmeId} selectedClient={selectedClient} loadData={loadData} setSelectedProgramme={setSelectedProgramme} /> : <AccessDenied />}
								</div>
							</Tab.Pane>
							<Tab.Pane eventKey="corporateAdmins">
								<div style={navContentStyle}>
									{selectedClient && <Breadcrumb className="mb-10">
										<Breadcrumb.Item>
											<Link to={{ pathname: "/admin/client-management", state: { selectedClient: selectedClient } }}>Client Management</Link>
										</Breadcrumb.Item>
										<Breadcrumb.Item>
											<Link to="" onClick={(e) => { e.preventDefault(); setFormOpen(false); refreshProgrammes(); setSelectedProgramme(null) }} >{selectedClient.companyName}</Link>
										</Breadcrumb.Item>
										{selectedProgramme && <Breadcrumb.Item active={selectedProgramme ? true : false}>{selectedProgramme.programmeName}</Breadcrumb.Item>}
									</Breadcrumb>}
									{isCorporateProgrammeModuleAvailable(clientId, programmeId, UserAccess.programme_access.programmeUsers.code) ? <ProgrammeUsersList programmeId={programmeId} clientId={clientId} selectedClient={selectedClient} selectedProgramme={selectedProgramme} setFormOpen={setFormOpen} refreshProgrammes={refreshProgrammes} setSelectedProgramme={setSelectedProgramme} /> : <AccessDenied />}
								</div>
							</Tab.Pane>

							<Tab.Pane eventKey="programmeUsers">
								<div style={navContentStyle}>
									{selectedClient && <Breadcrumb className="mb-10">
										<Breadcrumb.Item>
											<Link to={{ pathname: "/admin/client-management", state: { selectedClient: selectedClient } }}>Client Management</Link>
										</Breadcrumb.Item>
										<Breadcrumb.Item>
											<Link to="" onClick={(e) => { e.preventDefault(); setFormOpen(false); refreshProgrammes(); setSelectedProgramme(null) }} >{selectedClient.companyName}</Link>
										</Breadcrumb.Item>
										{selectedProgramme && <Breadcrumb.Item active={selectedProgramme ? true : false}>{selectedProgramme.programmeName}</Breadcrumb.Item>}
									</Breadcrumb>}
									{isCorporateProgrammeModuleAvailable(clientId, programmeId, UserAccess.programme_access.programmeUsers.code) ? <UserPage programme={getStorage('myo_selected_programme')} /> : <AccessDenied />}
								</div>
							</Tab.Pane>
							<Tab.Pane eventKey="companyPermissions">
								<div style={navContentStyle}>
									{selectedClient && <Breadcrumb className="mb-10">
										<Breadcrumb.Item>
											<Link to={{ pathname: "/admin/client-management", state: { selectedClient: selectedClient } }}>Client Management</Link>
										</Breadcrumb.Item>
										<Breadcrumb.Item>
											<Link to="" onClick={(e) => { e.preventDefault(); setFormOpen(false); refreshProgrammes(); setSelectedProgramme(null) }} >{selectedClient.companyName}</Link>
										</Breadcrumb.Item>
										{selectedProgramme && <Breadcrumb.Item active={selectedProgramme ? true : false}>{selectedProgramme.programmeName}</Breadcrumb.Item>}
									</Breadcrumb>}
									{programmeId && <CompanyPermission programmeId={programmeId} selectedClient={selectedClient} />}
								</div>
							</Tab.Pane>

							<Tab.Pane eventKey="bookerManagement">
								<div style={navContentStyle}>
									{selectedClient && <Breadcrumb className="mb-10">
										<Breadcrumb.Item>
											<Link to={{ pathname: "/admin/client-management", state: { selectedClient: selectedClient } }}>Client Management</Link>
										</Breadcrumb.Item>
										<Breadcrumb.Item>
											<Link to="" onClick={(e) => { e.preventDefault(); setFormOpen(false); refreshProgrammes(); setSelectedProgramme(null) }} >{selectedClient.companyName}</Link>
										</Breadcrumb.Item>
										{selectedProgramme && <Breadcrumb.Item active={selectedProgramme ? true : false}>{selectedProgramme.programmeName}</Breadcrumb.Item>}
									</Breadcrumb>}
									{programmeId && <BookerUsersList programmeId={programmeId} clientId={clientId} selectedClient={selectedClient} selectedProgramme={selectedProgramme} setFormOpen={setFormOpen} refreshProgrammes={refreshProgrammes} setSelectedProgramme={setSelectedProgramme} />}
								</div>
							</Tab.Pane>

							<Tab.Pane eventKey="travelerManagement">
								<div style={navContentStyle}>
									{selectedClient && <Breadcrumb className="mb-10">
										<Breadcrumb.Item>
											<Link to={{ pathname: "/admin/client-management", state: { selectedClient: selectedClient } }}>Client Management</Link>
										</Breadcrumb.Item>
										<Breadcrumb.Item>
											<Link to="" onClick={(e) => { e.preventDefault(); setFormOpen(false); refreshProgrammes(); setSelectedProgramme(null) }} >{selectedClient.companyName}</Link>
										</Breadcrumb.Item>
										{selectedProgramme && <Breadcrumb.Item active={selectedProgramme ? true : false}>{selectedProgramme.programmeName}</Breadcrumb.Item>}
									</Breadcrumb>}
									{programmeId && <TravelerUsersList programmeId={programmeId} clientId={clientId} selectedClient={selectedClient} selectedProgramme={selectedProgramme} setFormOpen={setFormOpen} refreshProgrammes={refreshProgrammes} setSelectedProgramme={setSelectedProgramme} />}
								</div>
							</Tab.Pane>

							<Tab.Pane eventKey="paymentManagement">
								<div style={navContentStyle}>
									{selectedClient && <Breadcrumb className="mb-10">
										<Breadcrumb.Item>
											<Link to={{ pathname: "/admin/client-management", state: { selectedClient: selectedClient } }}>Client Management</Link>
										</Breadcrumb.Item>
										<Breadcrumb.Item>
											<Link to="" onClick={(e) => { e.preventDefault(); setFormOpen(false); refreshProgrammes(); setSelectedProgramme(null) }} >{selectedClient.companyName}</Link>
										</Breadcrumb.Item>
										{selectedProgramme && <Breadcrumb.Item active={selectedProgramme ? true : false}>{selectedProgramme.programmeName}</Breadcrumb.Item>}
									</Breadcrumb>}
									{programmeId && <PaymentPermission programmeId={programmeId} selectedClient={selectedClient} />}
								</div>
							</Tab.Pane>

							<Tab.Pane eventKey="programmeSpendData">
								<div style={navContentStyle}>
									{selectedClient && <Breadcrumb className="mb-10">
										<Breadcrumb.Item>
											<Link to={{ pathname: "/admin/client-management", state: { selectedClient: selectedClient } }}>Client Management</Link>
										</Breadcrumb.Item>
										<Breadcrumb.Item>
											<Link to="" onClick={(e) => { e.preventDefault(); setFormOpen(false); refreshProgrammes(); setSelectedProgramme(null) }} >{selectedClient.companyName}</Link>
										</Breadcrumb.Item>
										{selectedProgramme && <Breadcrumb.Item active={selectedProgramme ? true : false}>{selectedProgramme.programmeName}</Breadcrumb.Item>}
									</Breadcrumb>}
									{programmeId && <ProgrammeSpendDataHome programmeId={programmeId} selectedClient={selectedClient} />}
								</div>
							</Tab.Pane>
						</Tab.Content>
					</Col>
				</Row>
			</Tab.Container>
		</div>
	)
};

export default ProgrammeForm;
