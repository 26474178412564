import React, { useEffect, useState } from 'react';

import file from "../../../images/icons/svg/outside/newFile.svg";
import calendar from "../../../images/icons/svg/outside/calendar.svg";
import search from "../../../images/icons/svg/outside/searchNew.svg";
import icon from "../../../images/icons/svg/outside/iconNew.svg";
import { getStorage } from '../../../common/storage';
import { useStoreActions, useStoreState } from 'easy-peasy';


const StatsCardClient: React.FC<any> = (): JSX.Element => {

    const [myRfpProcessesList, setMyRfpProcessesList] = useState<any>();
    const clientId = getStorage('programmeData')?.clientId?._id;
    const programmeId = getStorage('programmeData')?._id;

    /////////////////////////////////////////////////////////////////////////////////////////////////////
    const {
        getLocationOverViewList
    } = useStoreActions<any>((actions) => ({
        getLocationOverViewList: actions.rfpProcess.getLocationOverViewList

    }));

    ////////////////////////////////////////////////////////////////////////////////////////////////////
    const {
        getLocationOverViewListSuccess
    } = useStoreState<any>((state) => ({

        getLocationOverViewListSuccess: state.rfpProcess.getLocationOverViewListSuccess
    }));


    ////////////////////////////////////////////////////////////////////////////////////////////
    useEffect(() => {
        const params: any = {};
        params.clientId = clientId;
        params.programmeId = programmeId;

        getLocationOverViewList(params);
    }, [clientId, getLocationOverViewList, programmeId]);

    ////////////////////////////////////////////////////////////////////////////////////////////
    useEffect(() => {

        if (getLocationOverViewListSuccess?.data) {

            setMyRfpProcessesList(getLocationOverViewListSuccess?.data[0])
        }

    }, [getLocationOverViewListSuccess]);

    return (
        <>
            <div className='gtr-3 mt-4'>
                <div className="col-sm-6" style={{ width: "65%" }}>
                    <div className="stat_crdClient stat_prg">
                        <div className="stat_ctntClient">
                            <div className="nmbr">{myRfpProcessesList?.properties}</div>
                            <div className="text">Submitted</div>
                            <div className="text">Properties</div>
                        </div>
                        <img src={file} alt='icon' />

                    </div>
                </div>

                <div className="col-sm-6" style={{ width: "65%" }}>
                    <div className="stat_crdClient stat_prg">
                        <div className="stat_ctntClient">
                            <div className="nmbr">{myRfpProcessesList?.awaiting}</div>
                            <div className="text">Awaiting</div>
                            <div className="text">Assessment</div>
                        </div>
                        <img src={search} alt='icon' />

                    </div>
                </div>

                <div className="col-sm-6" style={{ width: "65%" }}>
                    <div className="stat_crdClient stat_prg">
                        <div className="stat_ctntClient">
                            <div className="nmbr">{myRfpProcessesList?.accepted}</div>
                            <div className="text">Accepted </div>
                            <div className="text">Properties</div>
                        </div>
                        <img src={calendar} alt='icon' />

                    </div>
                </div>


                <div className="col-sm-6" style={{ width: "65%" }}>
                    <div className="stat_crdClient stat_prg">
                        <div className="stat_ctntClient">
                            <div className="nmbr">{myRfpProcessesList?.negotiate}</div>
                            <div className="text">Under </div>
                            <div className="text">Negotiation</div>
                        </div>
                        <img src={icon} alt='icon' />
                    </div></div>
                <div className="col-sm-6" style={{ width: "65%" }}>
                    <div className="stat_crdClient stat_prg">
                        <div className="stat_ctntClient">
                            <div className="nmbr">{myRfpProcessesList?.decline}</div>
                            <div className="text">Declined </div>
                            <div className="text"> Properties</div>
                        </div>
                        <img src={icon} alt='icon' />
                    </div></div>
            </div>
        </>
    )
}

export default StatsCardClient;