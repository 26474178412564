import { FC, useState, useEffect } from "react";
import { useStoreActions, useStoreState } from 'easy-peasy';
import DataTable from "react-data-table-component";
import { tableStyles, selectStylesLittleRect } from "../../../../common/components-style";
import RFPBreadCrumbPage from "../../../common/RFPBredcrumPage";
import { Row, Col, Spinner } from 'react-bootstrap';
import Select from 'react-select';

interface ProgrammeOption {
    value: string;
    label: string;
}

const ReportData: FC<any> = ({ reportId, programmeId, setFormOpen, reload, selectedClient, selectedProgramme, accommodationType, reportName, currency, associateProgrammes }): JSX.Element => {
    const [data, setData] = useState<Array<any>>([]);
    const [programmeList, setProgrammeList] = useState<any>([]);
    const [selectedCountry, setSelectedCountry] = useState<string | null>(null);
    const [selectedCity, setSelectedCity] = useState<string | null>(null);
    const [filteredData, setFilteredData] = useState<any[]>([]);
    const [currencyName, setCurrencyName] = useState<any>(null);
    const [selectedAssociateProgramme, setSelectedAssociateProgramme] = useState<ProgrammeOption | null>(null);
    const [loading, setLoading] = useState<boolean>(false);

    const { getReportData, getReportDataFilterByProgram, getReportById } = useStoreActions<any>((actions) => ({
        getReportData: actions.hotel.getReportData,
        getReportById: actions.booking.getReportById,
        getReportDataFilterByProgram: actions.hotel.getReportDataFilterByProgram
    }));

    /**
     * global state
     */
    const { getReportDataSuccess, getReportByIdError,
        getReportByIdSuccess, getReportDataFilterByProgramSuccess } = useStoreState<any>((state) => ({
            getReportDataSuccess: state.hotel.getReportDataSuccess,
            getReportByIdError: state.booking.getReportByIdError,
            getReportByIdSuccess: state.booking.getReportByIdSuccess,
            getReportDataFilterByProgramSuccess: state.hotel.getReportDataFilterByProgramSuccess,


        }));

    useEffect(() => {
        if (getReportByIdSuccess?.data) {
            setCurrencyName(getReportByIdSuccess?.data?.currency);
        }
    }, [getReportByIdError, getReportByIdSuccess?.data]);


    useEffect(() => {
        if (reportId) {
            setLoading(true);
            const reportPayload = { _id: reportId };
            getReportById(reportPayload);
            const payload = {
                reportId: reportId,
                programmeId: selectedAssociateProgramme ? selectedAssociateProgramme?.value : '',
            };
            if (selectedAssociateProgramme) {
                getReportDataFilterByProgram(payload)
            }
            else {
                getReportData(payload)
            }
        }
    }, [getReportById, getReportData, reportId, selectedAssociateProgramme, getReportDataFilterByProgram]);


    useEffect(() => {
        if (getReportDataSuccess?.data) {
            setLoading(false);
            setData(getReportDataSuccess?.data);
            setFilteredData(getReportDataSuccess?.data);
        }
    }, [getReportDataSuccess]);

    useEffect(() => {
        if (getReportDataFilterByProgramSuccess?.data) {
            setLoading(false);
            setData(getReportDataFilterByProgramSuccess?.data);
            setFilteredData(getReportDataFilterByProgramSuccess?.data);
        }
    }, [getReportDataFilterByProgramSuccess?.data]);


    type Column = {
        name: React.ReactNode;
        sortable: boolean;
        selector: (row: any) => any;
        cell?: (row: any) => React.ReactNode;
    };

    const getCurrencyDetails = (currencyCode) => {
        const currencyData = require('../../../../common/currencyReport.json');
        const currency = currencyData.find(c => c.cc === currencyCode);
        return currency ? { symbol: currency.symbol, name: currency.name } : null;
    };

    const formatPercentageValue = (value: number | undefined): string => {
        if (value !== undefined) {
            if (value === 0 || value === 0.0) {
                return '0';
            }
            const roundedValue = Math.round(value * 10) / 10;
            if (roundedValue === 0 || roundedValue === 0.0) {
                return '0';
            }
            return roundedValue.toLocaleString(undefined, {
                minimumFractionDigits: 1,
                maximumFractionDigits: 1,
            });
        }
        return '0';
    };

    const formatPercentageTwoPointsValue = (value: number | undefined): string => {
        if (value !== undefined) {
            if (value === 0) {
                return '0';
            }
            const roundedValue = Math.round(value * 100) / 100;
            if (roundedValue === 0) {
                return '0';
            }
            return roundedValue.toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            });
        }
        return '0';
    };

    const formatDataValue = (value: number | undefined): string => {
        if (value !== undefined) {
            return Math.round(value).toLocaleString();
        }
        return '0';
    };

    const extractTopLevelFields = (row: any, currencyName: string): Column[] => {
        const topLevelColumns: Column[] = [];

        // Get currency details
        const currencyDetails = getCurrencyDetails(currencyName);
        const currencySymbol = currencyDetails ? currencyDetails.symbol : '';

        for (const key in row) {
            if (!key.endsWith("_UsedFields")) {
                const fieldTitle = selectedAssociateProgramme && selectedAssociateProgramme.value !== ''
                    ? `${key}`
                    : key;

                let columnTitle = fieldTitle;

                const isCityOrCountry = key.toLowerCase() === 'city' || key.toLowerCase() === 'country';

                topLevelColumns.push({
                    name: (
                        <span title={columnTitle}>
                            {columnTitle}
                        </span>
                    ),
                    selector: (row) => {
                        let fieldValue = row[key]?.value !== undefined ? row[key].value : row[key];
                        return fieldValue;
                    },
                    cell: (row) => {
                        let fieldValue = row[key]?.value !== undefined ? row[key].value : row[key];
                        let isBold = row[key]?.isFormData === true;
                        let isAverage = row[key]?.formulaOption?.trim()?.toLowerCase() === 'average';
                        let isCurrencyDisplay = row[key]?.isCurrencyDisplay === true;

                        if (typeof fieldValue === "number") {
                            if (key.includes('%')) {
                                fieldValue = `${formatPercentageValue(fieldValue)}%`;
                            } else if (key.trim().toLowerCase() === 'abr') {
                                fieldValue = `${formatPercentageTwoPointsValue(fieldValue)}`;
                                isBold = true;
                            } else if (isAverage) {
                                fieldValue = formatPercentageTwoPointsValue(fieldValue);
                            } else {
                                fieldValue = formatDataValue(fieldValue);
                            }

                            if (isCurrencyDisplay && currencySymbol) {
                                fieldValue = `${currencySymbol}${fieldValue}`;
                            } else if (row[key]?.dataType === 'spend' && currencySymbol) {
                                fieldValue = `${currencySymbol}${fieldValue}`;
                            } else if (
                                selectedAssociateProgramme &&
                                selectedAssociateProgramme.value?.trim() !== '' &&
                                currencySymbol &&
                                (key?.trim()?.toLowerCase() === 'spend' || key?.trim()?.toLowerCase() === 'abr')
                            ) {
                                fieldValue = `${currencySymbol}${fieldValue}`;
                            }
                        }
                        return isBold ? <strong>{fieldValue}</strong> : fieldValue;
                    },
                    sortable: !isCityOrCountry,
                });
            }
        }

        return topLevelColumns;
    };

    const createDynamicColumns = (data: any[], currencyName: string): Column[] => {
        const topLevelColumns = extractTopLevelFields(data[0], currencyName);

        return [...topLevelColumns];
    };

    const dynamicColumns = createDynamicColumns(data, currencyName);

    useEffect(() => {
        if ((associateProgrammes && associateProgrammes.length > 0) || selectedProgramme?.programmeName) {
            const options = [
                ...(associateProgrammes || [])
                    .filter((item: string) => item !== 'None')
                    .map((item: string) => ({
                        value: item,
                        label: item,
                    })),
                selectedProgramme?.programmeName && {
                    value: selectedProgramme.programmeName,
                    label: selectedProgramme.programmeName,
                },
            ].filter(Boolean);

            setProgrammeList(options);
        } else {
            setProgrammeList([]);
        }
    }, [associateProgrammes, selectedProgramme]);



    const uniqueCountries = Array.from(
        new Set(data.map((item) => item.Country))
    ).filter(Boolean);

    const countryOptions = uniqueCountries.map(country => ({
        label: country,
        value: country
    }));

    const uniqueCity = Array.from(
        new Set(data.map((item) => item.City))
    ).filter(Boolean);

    const cityOptions = uniqueCity.map(city => ({
        label: city,
        value: city
    }));

    const handleProgrammeChange = (selectedOption) => {
        setSelectedAssociateProgramme(selectedOption);
    };

    useEffect(() => {
        let filtered = data;

        if (selectedCountry) {
            filtered = filtered.filter((hotel) => hotel.Country === selectedCountry);
        }

        if (selectedCity) {
            filtered = filtered.filter((hotel) => hotel.City === selectedCity);
        }

        setFilteredData(filtered);
    }, [selectedCountry, selectedCity, data]);


    return (
        <>
            <div className="col-md-12" style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <div>
                    {reportId && (
                        <RFPBreadCrumbPage
                            isPageName="locationOverviewReport"
                            selectedClient={selectedClient}
                            selectedProgramme={selectedProgramme}
                            selectedClientName={selectedClient?.companyName}
                            selectedProgrammeName={selectedProgramme?.programmeName}
                            reportId={reportId}
                            programmeId={programmeId}
                            startDate={reportName}
                            tab="Location Overview"
                        />
                    )}
                </div>

                {/* <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                    <Button
                        className="customDeleteBtn" onClick={() => {
                            history.push({
                                pathname: '/admin/programme-spend-data',
                                state: {
                                    programmeId: programmeId,
                                    reportId: reportId,
                                    formOpen: true,
                                    selectedClient: selectedClient,
                                    selectedProgramme: selectedProgramme,
                                    tabKey: 'locationTableColumns',
                                    reportName: reportName
                                }
                            });
                        }}
                    >
                        Data
                    </Button>
                </div> */}


                <Row className='btn_wrapper fix_header' style={{ marginTop: '65px', paddingBottom: '40px' }}>
                    <Col xs={2}>
                        <Select
                            className='reportTableButton'
                            isClearable
                            placeholder={<div>Search Country</div>}
                            options={countryOptions}
                            styles={selectStylesLittleRect}
                            isSearchable={true}
                            onChange={(option) => setSelectedCountry(option ? option.value : null)}
                        />
                    </Col>

                    <Col xs={2}>
                        <Select
                            className='reportTableButton'
                            isClearable
                            placeholder={<div>Search City</div>}
                            options={cityOptions}
                            styles={selectStylesLittleRect}
                            isSearchable={true}
                            onChange={(option) => setSelectedCity(option ? option.value : null)}
                        />
                    </Col>
                    <Col xs={3}>
                        {programmeList.length > 0 ? (
                            <Select
                                className="reportTableButton"
                                isClearable
                                placeholder="Search Programme"
                                styles={selectStylesLittleRect}
                                options={programmeList}
                                value={selectedAssociateProgramme}
                                onChange={handleProgrammeChange}
                            />
                        ) : null}
                    </Col>
                    <Col xs={1} style={{ display: "flex", alignItems: "center" }}>
                        {loading && (<Spinner animation="border" variant="warning" />)}
                    </Col>
                </Row>
            </div>

            {
                !loading && <div className='brnd_tbl' style={{ marginTop: '160px' }}>
                    <DataTable className="reports_table reports_table_location_overview"
                        columns={dynamicColumns}
                        data={filteredData}
                        pagination
                        striped={true}
                        customStyles={tableStyles}
                        theme="mysaTable"
                        fixedHeader={true}
                        noDataComponent="Updating records"
                        paginationPerPage={25}
                        paginationRowsPerPageOptions={[25, 50, 100]}
                    />
                </div>
            }
        </>
    )
}

export default ReportData;