import { Form, Col, Row, Button, Modal } from "react-bootstrap";
import { FC, useCallback, useEffect, useState } from "react";
import { useStoreActions, useStoreState } from "easy-peasy";
import { toast } from 'react-toastify';
import RFPBreadCrumbPage from "../../../common/RFPBredcrumPage";
import { useDropzone } from 'react-dropzone';
import * as XLSX from 'xlsx';
import Papa from 'papaparse';
import DataTable from 'react-data-table-component';
import { routingButton, tableUserStyles } from '../../../../common/components-style';

const UploadData: FC<any> = ({ reportId, programmeId, setFormOpen, reload, selectedClient, selectedProgramme, accommodationType, reportName, hotelList }): JSX.Element => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [expectedRow, setExpectedRow] = useState<any>([]);
    const [finalModal, setFinalModal] = useState<boolean>(false);
    const [invalidData, setInvalidData] = useState([]);
    const [isUploadDisabled, setIsUploadDisabled] = useState<boolean>(false);

    const {

        uploadData,
        loadBrandList,
        getReportDataFields,
        getHotelUploadData
    } = useStoreActions<any>((actions) => ({
        uploadData: actions.hotel.uploadData,
        loadBrandList: actions.common.loadBrandList,
        getReportDataFields: actions.hotel.getReportDataFields,
        getHotelUploadData: actions.hotel.getHotelUploadData,
    }))

    const {
        brandList,
        getReportDataFieldsSuccess,
        getHotelUploadDataSuccess,
        uploadDataSuccess,
        uploadDataError

    } = useStoreState<any>((state) => ({
        brandList: state.common.brandList,
        getReportDataFieldsSuccess: state.hotel.getReportDataFieldsSuccess,
        getHotelUploadDataSuccess: state.hotel.getHotelUploadDataSuccess,
        uploadDataSuccess: state.hotel.uploadDataSuccess,
        uploadDataError: state.hotel.uploadDataError,

    }));

    ////////////////////////////////////////////////////////////////////////////////////////////////////
    useEffect(() => {
        if (reportId) {
            getReportDataFields(reportId);
            loadBrandList();
            getHotelUploadData(reportId);
        }
    }, [getReportDataFields, loadBrandList, reportId, getHotelUploadData]);

    useEffect(() => {
        if (getHotelUploadDataSuccess?.data) {
            setIsUploadDisabled(true);
        }
    }, [getHotelUploadDataSuccess]);


    useEffect(() => {
        if (getReportDataFieldsSuccess?.data) {
            const fetchedFields = getReportDataFieldsSuccess?.data?.fields || [];
            setExpectedRow(fetchedFields);
        }
    }, [getReportDataFieldsSuccess]);

    useEffect(() => {
        if (uploadDataSuccess?.data) {
            // if(isUploadDisabled){
            toast.success("File uploaded successfully!", {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'foo-bar',
            });
            // }

        }
        if (uploadDataError) {
            toast.error(uploadDataError?.message, {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'foo-bar',
            });
        }
    }, [uploadDataSuccess, uploadDataError]);



    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    const callBrandId = useCallback((brandName) => {
        const brand = brandList?.data?.find(b => b.name?.toLowerCase()?.trim() === brandName?.toLowerCase()?.trim());
        return brand ? brand._id : null;
    }, [brandList]);

    const callHotelId = useCallback(async (hotelName, city, country, state, address) => {
        if (hotelList?.length > 0) {
            const searchCriteria = [
                { field: 'hotelName', value: hotelName },
                { field: 'city', value: city },
                { field: 'country', value: country },
                { field: 'address1', value: address },
            ];

            if (state) {
                searchCriteria.push({ field: 'state', value: state });
            }

            const hotel = hotelList.find(h => {
                return searchCriteria.every(({ field, value }) =>
                    h[field]?.toLowerCase()?.trim() === value?.toLowerCase()?.trim()
                );
            });

            return hotel ? hotel._id : null;
        }
    }, [hotelList]);



    const validateHeadersAndContent = useCallback((headers): boolean => {
        const requiredHeaders = ["brand", "hotel", "country", "city", "state", "address"];
        const normalizedHeaders = headers.map((header) => header?.toLowerCase()?.trim());

        const missingRequiredHeaders = requiredHeaders.filter(header => !normalizedHeaders.includes(header));
        if (missingRequiredHeaders.length > 0) {
            alert(`Missing required headers in file: ${missingRequiredHeaders.join(', ')}`);
            return false;
        }

        const filteredHeaders = normalizedHeaders.filter(header => !requiredHeaders.includes(header));

        const expectedFields = expectedRow || [];
        const missingExpectedColumns = expectedFields
            .filter(field => !filteredHeaders.includes(field?.fieldName?.toLowerCase()?.trim()))
            .map(field => field.fieldName);

        if (missingExpectedColumns.length > 0) {
            alert(`Missing expected columns in the file: ${missingExpectedColumns.join(', ')}`);
            return false;
        }

        const extraHeaders = filteredHeaders
            .filter(header => !expectedFields.some(field => field?.fieldName?.toLowerCase()?.trim() === header))
            .map(header => header);

        if (extraHeaders.length > 0) {
            alert(`Extra columns in the file headers: ${extraHeaders.join(', ')}`);
            return false;
        }

        if (filteredHeaders.length !== expectedFields.length) {
            const extraOrMissingHeaders = expectedFields
                .filter(field => !filteredHeaders.includes(field?.fieldName?.toLowerCase()?.trim()))
                .map(field => field.fieldName);

            const headersMessage = `Headers mismatch. Missing fields: ${extraOrMissingHeaders.join(', ')}`;
            alert(headersMessage);
            return false;
        }

        return true;
    }, [expectedRow]);

    const callApi = useCallback(async (user) => {
        if (user) {
            setIsLoading(true);

            const newData = user.map((obj) => {
                let newObj: { [key: string]: any } = {};
                for (let key in obj) {
                    let newKey = key?.replace(/\s+/g, '').toLowerCase();
                    newObj[newKey] = obj[key];
                }
                return newObj;
            });

            const validData = newData.filter(
                (data) =>
                    data.brand?.trim() ||
                    data.hotel?.trim() ||
                    data.country?.trim() ||
                    data.city?.trim() ||
                    data.state?.trim() ||
                    data.address?.trim()
            );

            // const aggregatedPayload: { [key: string]: any } = {
            //     clientId: selectedClient?.key ? selectedClient?.key : selectedClient?._id,
            //     programmeId: programmeId,
            //     reportId: reportId,
            //     hotels: [],
            // };
            const invalidDataList: any = [];
            const processedHotels: any[] = [];
            for (const data of validData) {
                let hotelData: { [key: string]: any } = {};
                let isValid = true;
                const invalidReasonsSet: Set<string> = new Set();

                const brandId = data.brand?.trim() ? callBrandId(data.brand) : null;
                const hotelId = data.hotel?.trim() && data.city?.trim() && data.country?.trim()
                    ? await callHotelId(data.hotel, data.city, data.country, data.state, data.address)
                    : null;

                if (brandId) {
                    hotelData.brandId = brandId;
                } else if (data.brand?.trim()) {
                    isValid = false;
                    invalidReasonsSet.add("Invalid brand");
                }

                if (hotelId) {
                    hotelData.hotelId = hotelId;
                } else if (data.hotel?.trim()) {
                    isValid = false;
                    invalidReasonsSet.add("Invalid hotel");
                }

                let newFields: Array<{ key: string; value: any }> = [];
                expectedRow?.forEach((field) => {
                    const fieldName = field?.fieldName?.trim();

                    const fieldType = field?.dataType?.toLowerCase();
                    const normalizedFieldName = fieldName?.replace(/\s+/g, '').toLowerCase();
                    if (["hotel", "brand", "country", "city", "state", "address"].includes(normalizedFieldName)) {
                        return;
                    }

                    let value;
                    if (data[normalizedFieldName]) {
                        if (typeof data[normalizedFieldName] === "string") {
                            value = data[normalizedFieldName].trim();
                        } else {
                            value = data[normalizedFieldName];
                        }
                        if (fieldType === "nights" || fieldType === "spend") {
                            let parsedValue;

                            if (typeof value === "string") {
                                parsedValue = parseFloat(value.replace(/[^0-9.]/g, '').replace(/,/g, ''));
                            } else if (typeof value === "number") {
                                parsedValue = value;
                            }

                            if (!isNaN(parsedValue)) {
                                value = parsedValue;
                            } else {
                                isValid = false;
                                invalidReasonsSet.add(`Invalid value for ${fieldName}`);
                            }
                        } else {
                            value = fieldType === "nights" || fieldType === "spend" ? 0 : value ? value : "";
                        }
                    }
                    else {
                        value = fieldType === "nights" || fieldType === "spend" ? 0 : value ? value : "";
                    }


                    newFields.push({
                        key: fieldName,
                        value,
                    });
                });
                if (newFields.length > 0) {
                    hotelData.newFields = newFields;
                }
                if (isValid && Object.keys(hotelData).length > 0) {
                    // aggregatedPayload.hotels.push(hotelData);
                    processedHotels.push(hotelData);
                } else {
                    invalidDataList.push({
                        ...data,
                        newFields: newFields,
                        reason: Array.from(invalidReasonsSet).join(",\n "),
                    });
                }
            }

            if (invalidDataList.length > 0) {
                setInvalidData(invalidDataList);
                setFinalModal(true);
                return;
            }

            if (processedHotels.length > 0) {
                const chunkSize = 2000;
                const totalChunks = Math.ceil(processedHotels.length / chunkSize);

                for (let i = 0; i < totalChunks; i++) {
                    const start = i * chunkSize;
                    const end = Math.min(start + chunkSize, processedHotels.length);
                    const hotelChunk = processedHotels.slice(start, end);

                    const aggregatedPayload = {
                        clientId: selectedClient?.key ? selectedClient?.key : selectedClient?._id,
                        programmeId,
                        reportId,
                        hotels: hotelChunk,
                    };

                    try {
                        await uploadData(aggregatedPayload);
                    } catch (error) {
                        console.error("Upload failed for chunk", i + 1, error);
                    }
                }

                setIsUploadDisabled(true);
            } else {
                toast.error("No valid data to upload.", {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    className: "foo-bar",
                });
            }

            setIsLoading(false);
        }
    }, [callBrandId, callHotelId, expectedRow, programmeId, reportId, selectedClient?._id, selectedClient?.key, uploadData]);


    const onDrop = useCallback((acceptedFiles) => {
        const file = acceptedFiles[0];
        const reader = new FileReader();

        reader.onload = () => {
            const binaryStr = reader.result;

            if (file.name.endsWith('.xlsx') || file.name.endsWith('.xls')) {
                const workbook = XLSX.read(binaryStr, { type: 'binary' });
                const sheetName = workbook.SheetNames[0];
                const worksheet = workbook.Sheets[sheetName];
                const json = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

                const headers = json[0];
                if (validateHeadersAndContent(headers)) {
                    const dataRows = XLSX.utils.sheet_to_json(worksheet);
                    callApi(dataRows);
                }

            } else if (file.name.endsWith('.csv')) {
                Papa.parse(binaryStr, {
                    header: true,
                    complete: (results) => {
                        const headers = Object.keys(results.data[0]);
                        if (validateHeadersAndContent(headers)) {
                            callApi(results.data);
                        }
                    },
                });
            } else {
                console.error('Unsupported file format');
                alert('Unsupported file format. Please upload a valid .xlsx or .csv file.');
            }
        };
        reader.readAsBinaryString(file);
    }, [callApi, validateHeadersAndContent]);

    const { open } = useDropzone({ onDrop });

    const columns1 = [
        { name: 'Brand', selector: row => row.brand, },
        { name: 'Hotel', selector: row => row.hotel, },
        { name: 'City', selector: row => row.city, },
        { name: 'State', selector: row => row.state, },
        { name: 'Country', selector: row => row.country, },
        { name: 'Address', selector: row => row.address, },
        { name: 'Reason', selector: row => row.reason, },

    ];
    ////////////////////////////////////////////////////////////////////////////////////////////////////

    return (
        <>

            {reportId &&
                <RFPBreadCrumbPage isPageName="programmeSpendReport" selectedClient={selectedClient} selectedProgramme={selectedProgramme} selectedClientName={selectedClient?.companyName} selectedProgrammeName={selectedProgramme?.programmeName} reportId={reportId} programmeId={programmeId} startDate={reportName}></RFPBreadCrumbPage>
            }
            <div className="create-update-wrapper">
                <Form>
                    <Row className='formSpace'>
                        <Col md={3} style={{ textAlign: 'right', }}>
                            <Form.Label>
                                Company Name
                            </Form.Label>
                        </Col>

                        <Col md={9} style={{ textAlign: 'left' }}>
                            <p className="lead">{selectedClient?.companyName}</p>
                        </Col>
                    </Row>
                    <Row className='formSpace'>
                        <Col md={3} style={{ textAlign: 'right' }}>
                            <Form.Label> Name of programme</Form.Label>
                        </Col>

                        <Col md={9} style={{ textAlign: 'left' }}>
                            <p className="lead">{selectedProgramme?.programmeName}</p>
                        </Col>
                    </Row>
                    <Row className='formSpace'>
                        <Col md={3} style={{ textAlign: 'right' }}>
                            <Form.Label> Accommodation type</Form.Label>
                        </Col>

                        <Col md={9} style={{ textAlign: 'left' }}>
                            <p className="lead">{accommodationType}</p>
                        </Col>
                    </Row>

                    <Row style={{ paddingTop: '30px' }} className='formSpace'>
                        <Col md={3} style={{ textAlign: 'right' }}>

                        </Col>
                        <Col md={3} >
                            <Button id="create-button" onClick={open} disabled={isLoading || isUploadDisabled}>
                                {isLoading ? 'Uploading...' : 'Upload File'}
                            </Button>
                        </Col>
                    </Row>

                </Form>
            </div>


            <Modal
                show={finalModal}
                onHide={() => { setFinalModal(false); }}
                size="xl"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header>
                </Modal.Header>
                <Modal.Body>
                    {
                        invalidData?.length > 0 &&
                        <Row style={{ textAlign: 'center' }}>
                            <h3>Your upload is complete.</h3>
                            <div>
                                <p>There were {invalidData.length} records not imported.</p>
                            </div>
                            <Col>
                                <Button style={routingButton} id='routing-button' onClick={() => { setFinalModal(false); setIsLoading(false); }} ><b>Close</b></Button>
                            </Col>

                            <div className='mt-4 brandUser_table'>
                                <DataTable
                                    columns={columns1}
                                    data={invalidData}
                                    pagination
                                    striped={true}
                                    customStyles={tableUserStyles}
                                    theme='mysaTable'
                                    fixedHeader={true}
                                    noDataComponent={'Updating records'}
                                />
                            </div>
                        </Row>
                    }
                </Modal.Body>
            </Modal>
        </>
    )
}

export default UploadData;
