import { FC, useCallback, useEffect, useState } from "react";
import RFPBreadCrumbPage from "../../../common/RFPBredcrumPage";
import { Col, Row, Form, Button } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { formButton } from "../../../../common/components-style";
import { InviteInput } from "../../../../common/interfaces";

import { useStoreActions, useStoreState } from "easy-peasy";
import { toast } from "react-toastify";




const InviteParameters: FC<any> = ({ rfpColor, rfpStatus, rfpName, programmeId, setTabKey, rfpProcessId, selectedClient, selectedProgramme, selectedClientNames, selectedRfpProcess }) => {
    const [selectedClientName, setSelectedClientName] = useState<string>("");
    const [selectedProgrammeName, setSelectedProgrammeName] = useState<string>("");
    const [editEnable, setEditEnable] = useState<boolean>(false);
    const [data, setData] = useState<Array<any>>([]);
    const [selectInviteType, setSelectInviteType] = useState<string>('');
    const [isHere, setIsHere] = useState<boolean>(false);

    const { register, watch, handleSubmit } = useForm<InviteInput>();

    //actions
    const {

        updateRfpProcess,
        getRfpProcessById,
    
    } = useStoreActions<any>((actions) => ({
        updateRfpProcess: actions.rfpProcess.updateRfpProcess,
        getRfpProcessById: actions.rfpProcess.getRfpProcessById,
       
    }));

    //state
    const {

        updateRfpProcessSuccessResponse,
        updateRfpProcessErrorResponse,
        rfpProcessByIdSuccess,

   
    } = useStoreState<any>((state) => ({
        updateRfpProcessSuccessResponse: state.rfpProcess.updateRfpProcessSuccessResponse,
        updateRfpProcessErrorResponse: state.rfpProcess.updateRfpProcessErrorResponse,
        rfpProcessByIdSuccess: state.rfpProcess.rfpProcessByIdSuccess,
       

    }));


    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    useEffect(() => {
        if (selectedProgramme) {
            setSelectedProgrammeName(selectedProgramme?.programmeName);
        }
        if (selectedClientNames) {
            setSelectedClientName(selectedClientNames);
        }
        if (rfpProcessId) {
            getRfpProcessById(rfpProcessId);
        }

    }, [getRfpProcessById, rfpProcessId, selectedClientNames, selectedProgramme]);




    /////////////////////////////////////////////////
    const onChangeInvite = (event) => {
        // console.log(event.target.value, 'value');
        // if (event.target.value) {
        //     setSelectInviteType(event.target.value);
        // } else {
        //     setSelectInviteType("");
        // }
    };

    // const callApi = useCallback(() => {
    //     if (selectInviteType) {
    //         const payload = {
    //             rfpProcessId: rfpProcessId,
    //             programmeId: programmeId,
    //             inviteType: watch('inviteType'),
    //             propertyType: 'All',
    //             propertyStatus: 'All',
    //             propertyCountryCode: 'All',
    //             propertyCity: 'All'
    //         };
    //         getBrandsAndPropertiesForInvite(payload);
    //     }
    // }, [getBrandsAndPropertiesForInvite, programmeId, rfpProcessId, selectInviteType, watch]);



    // create rfp process logic ////////////////////////////////////////////////////////////////////////////////////////////////////////
    useEffect(() => {
        if (updateRfpProcessErrorResponse) {

            toast.error(updateRfpProcessErrorResponse?.message, {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'foo-bar'
            });
            setEditEnable(false);
        }
        if (updateRfpProcessSuccessResponse?.data) {
            if (isHere) {
                toast.success("Properties added to the invite list", {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    className: 'foo-bar'
                });
            }

            setEditEnable(false);
            setTabKey('inviteProperties');

            getRfpProcessById(updateRfpProcessSuccessResponse?.data?._id);
            // redirectInviteSelection();
            // if (data?.length > 0) {
            // callApi();
            // } else {
            // setTabKey('inviteProperties');
            // }

        }

    }, [getRfpProcessById, isHere, setTabKey, updateRfpProcessErrorResponse, updateRfpProcessSuccessResponse]);


    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    useEffect(() => {
        if (rfpProcessByIdSuccess?.data) {

            setSelectInviteType(rfpProcessByIdSuccess?.data?.inviteParameter);
            // if (rfpProcessByIdSuccess?.data?.inviteParameter) {
            //     setIsHere(false);
            // } else {
            //     setIsHere(true);
            // }
        }

    }, [rfpProcessByIdSuccess?.data, selectInviteType]);

    // useEffect(() => {
    //     if (addInvitePropertySuccess?.data) {
    //         // toast.success("Property added to 'Properties To Invite' successfully", {
    //         //     position: toast.POSITION.BOTTOM_RIGHT,
    //         //     className: "foo-bar",
    //         // });
    //         // callApi();
    //         setTabKey('inviteProperties');
    //     }

    //     if (addInvitePropertyError) {
    //         toast.error(addInvitePropertyError.message, {
    //             position: toast.POSITION.BOTTOM_RIGHT,
    //             className: "foo-bar",
    //         });
    //         // callApi();
    //     }
    // }, [addInvitePropertyError, addInvitePropertySuccess, setTabKey]);

    /////////////////////////////////////////////////////////////////////////////////////
    const changeFormEditable = useCallback(() => {
        // scrollToTop();
        if (editEnable) {
            setEditEnable(false);
        } else {
            setEditEnable(true);
        }
    }, [editEnable]);

    ///////////////////////////////////////////////////////////////////////////////
    const onSubmit = useCallback(() => {
        // setStorage('selectInviteType', watch('inviteType'));
        setIsHere(true);
        const data = {
            id: rfpProcessId,
            inviteParameter: watch('inviteType')
        }
        // callApi();
        updateRfpProcess(data);

    }, [rfpProcessId, updateRfpProcess, watch])



    /////////////////////////////////////////////////////////////////////////////////////
    // const insertMultipleRecords = useCallback(async (records) => {

    //     for (let i = 0; i < records.length; i++) {
    //         const payload = {

    //             id: records[i]?.propertyId,
    //             propertySelection: false

    //         }
    //         await addInviteProperty(payload);
    //     }


    // }, [addInviteProperty]);


    // useEffect(() => {
    //     if (isHere) {
    //         (async function () {

    //             if (data) {
    //                 await insertMultipleRecords(data);
    //             }


    //         }())
    //     } else {

    //     }
    // }, [data, insertMultipleRecords, isHere, setTabKey]);



    return (
        <>
            {(rfpProcessId || selectedRfpProcess?.key) &&
                <div className="flex justify-between">
                    <RFPBreadCrumbPage selectedRfpProcess={selectedRfpProcess} isPageName="invite" name={'Invite Selection'} selectedClient={selectedClient} selectedProgramme={selectedProgramme} selectedClientName={selectedClientName} selectedProgrammeName={selectedProgrammeName} rfpProcessId={rfpProcessId} programmeId={programmeId} startDate={rfpName}></RFPBreadCrumbPage>

                    <span className={rfpColor} style={{ marginTop: '-20px', marginRight: '110px' }}>{rfpStatus}</span>
                </div>
            }

            {<Row className="mt-5">
                <p style={{ fontWeight: 'bold', fontSize: '24px' }}>Please select the Parameters below to determine Which properties should be added to the RFP. </p>
                <p style={{ fontWeight: 'bold', fontSize: '24px' }}>Individual properties can be removed via 'Properties to Invite' once displayed or added via 'Other Properties'. </p>
            </Row>}

            <div>
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <Row >
                        <Col md={8} className="mt-3" style={{ textAlign: 'left', fontWeight: 'bold', fontSize: '20px', }}>

                            <div style={{
                                marginBottom: '25px',
                                padding: '10px',
                                backgroundColor: '#F2F2F2', // Light grey background color
                                borderRadius: '5px', // Rounded corners
                            }}>
                                {!editEnable && <Form.Check disabled {...register("inviteType")} checked={selectInviteType === 'All_Brands' ? true : false} onChange={onChangeInvite}
                                    type="radio" value={'All_Brands'} label="Add all properties relevant to the ‘Accommodation Type’ for all locations included in the RFP" />}
                                {editEnable && <Form.Check {...register("inviteType")} defaultChecked={selectInviteType === 'All_Brands' ? true : false} onChange={onChangeInvite}
                                    type="radio" value={'All_Brands'} label="Add all properties relevant to the ‘Accommodation Type’ for all locations included in the RFP" />}
                            </div>
                            <div style={{
                                marginBottom: '25px',
                                padding: '10px',
                                backgroundColor: '#F2F2F2', // Light grey background color
                                borderRadius: '5px', // Rounded corners
                            }}>
                                {!editEnable && <Form.Check disabled checked={selectInviteType === 'Current_programme_only' ? true : false} {...register("inviteType")} onChange={onChangeInvite} type="radio" value={'Current_programme_only'} label="Add all properties from the current programme" />}
                                {editEnable && <Form.Check defaultChecked={selectInviteType === 'Current_programme_only' ? true : false} {...register("inviteType")} onChange={onChangeInvite} type="radio" value={'Current_programme_only'} label="Add all properties from the current programme" />}
                            </div>
                        </Col>
                    </Row>

                    <Row className="mt-5">

                        {!editEnable && <Button
                            //    type="submit"
                            id="edit-button"
                            style={formButton}
                            onClick={changeFormEditable}
                        >
                            Edit
                        </Button>}

                        {editEnable && <Button
                            type="submit"
                            id="approve-button"
                            style={formButton}
                        >
                            Save
                        </Button>}


                    </Row>
                </Form>

            </div>
        </>
    );

}

export default InviteParameters