/* eslint-disable import/no-anonymous-default-export */
import { action, thunk } from 'easy-peasy';
import { call } from '../common/axios';


export default {
  createHotelSuccess: null,
  createHotelError: null,
  updateHotelError: null,
  updateHotelSuccess: null,
  getHotelsSuccess: null,
  getHotelsError: null,
  getHotelSuccess: null,
  getHotelError: null,
  reset: false,
  getAllHotelNameListSuccess: null,
  getAllHotelNameListError: null,

  /////////////////////////////////property location /////////////////////////////////////

  getHotelPropertyLocationTypesSuccess: null,
  getHotelPropertyLocationTypesError: null,

  /////////////////////////////////market tier /////////////////////////////////////

  getHotelMarketTierTypesSuccess: null,
  getHotelMarketTierTypesError: null,

  /////////////////////////////////property type /////////////////////////////////////

  getHotelPropertyTypesSuccess: null,
  getHotelPropertyTypesError: null,

  /////////////////////////////////transport model /////////////////////////////////////
  getTransportSuccess: null,
  getTransportError: null,
  createTransportSuccess: null,
  createTransportError: null,
  updateTransportSuccess: null,
  updateTransportError: null,

  /////////////////////////////////guest comfort model /////////////////////////////////////
  getGuestComfortSuccess: null,
  getGuestComfortError: null,
  createGuestComfortSuccess: null,
  createGuestComfortError: null,
  updateGuestComfortSuccess: null,
  updateGuestComfortError: null,
  getGuestComfortBreakFastTypesSuccess: null,
  getGuestComfortBreakFastTypesError: null,

  /////////////////////////////////amenity model /////////////////////////////////////

  getAmenityListSuccess: null,
  getAmenityListError: null,

  /////////////////////////////////image model /////////////////////////////////////

  uploadHotelImageSuccess: null,
  uploadHotelImageError: null,
  getHotelImageSuccess: null,
  getHotelImageError: null,
  updateHotelImageSuccess: null,
  removeHotelImageSuccess: null,
  getHotelImageTagSuccess: null,
  getHotelImageTagError: null,
  getHotelPrimaryImageSuccess: null,
  getHotelPrimaryImageError: null,

  /////////////////////////////////invoice model /////////////////////////////////////

  createHotelInvoiceSuccess: null,
  createHotelInvoiceError: null,
  updateHotelInvoiceSuccess: null,
  updateHotelInvoiceError: null,
  getHotelInvoiceDetailSuccess: null,
  getHotelInvoiceDetailError: null,

  /////////////////////////////////check in model /////////////////////////////////////

  getCheckInProcessListSuccess: null,
  getCheckInProcessListError: null,
  getHotelCheckInProcessDetailByIdSuccess: null,
  getHotelCheckInProcessDetailByIdError: null,
  createHotelCheckInProcessDetailSuccess: null,
  createHotelCheckInProcessDetailError: null,
  updateHotelCheckInProcessDetailSuccess: null,
  updateHotelCheckInProcessDetailError: null,

  getHotelAmenitiesSuccess: null,
  getHotelAmenitiesError: null,
  assignHotelAmenitySuccess: null,
  assignHotelAmenityError: null,

  createHotelTempDataSuccess: null,
  createHotelTempDataError: null,

  getHotelsTempDataSuccess: null,
  getHotelsTempDataError: null,


  getHotelsPaginationTempDataSuccess: null,
  getHotelsPaginationTempDataError: null,

  deleteHotelsTempDataSuccess: null,
  deleteHotelsTempDataError: null,

  deleteHotelsTempDataByIdSuccess: null,
  deleteHotelsTempDataByIdError: null,

  getReportDataFieldsSuccess: null,
  getReportDataFieldsError: null,

  getReportDataFilterByProgramSuccess: null,
  getReportDataFilterByProgramError: null,


  createReportDataFieldsDataSuccess: null,
  createReportDataFieldsDataError: null,
  updateReportDataFieldsDataSuccess: null,
  updateReportDataFieldsDataError: null,


  getLocationOverviewReportSuccess: null,
  getLocationOverviewReportError: null,
  createLocationOverviewReportSuccess: null,
  createLocationOverviewReportError: null,
  updateLocationOverviewReportSuccess: null,
  updateLocationOverviewReportError: null,


  uploadDataSuccess: null,
  uploadDataError: null,
  getHotelUploadDataSuccess: null,
  getHotelUploadDataError: null,
  getReportDataSuccess: null,
  getReportDataError: null,

  getReportDataContractedPropertiesSuccess: null,
  getReportDataContractedPropertiesError: null,
  getReportDataContractedPropertiesPerformanceByHotelSuccess: null,
  getReportDataContractedPropertiesPerformanceByHotelError: null,
  getReportDataNonContractedPropertiesSuccess: null,
  getReportDataNonContractedPropertiesError: null,

  getReportDataChainSuccess: null,
  getReportDataChainError: null,
  getReportDataChainOverviewSuccess: null,
  getReportDataChainOverviewError: null,
  getReportDataBrandOverviewSuccess: null,
  getReportDataBrandOverviewError: null,
  getReportDataBrandOverviewForParticularBrandSuccess: null,
  getReportDataBrandOverviewForParticularBrandError: null,

  // actions
  // ////////////////////////////////hotel ////////////////////////////////////////

  setAllHotelsSuccess: action((state, payload) => ({ getAllHotelsSuccess: payload })),
  setCreateHotel: action((state, payload) => ({ createHotelSuccess: payload })),
  setCreateHotelError: action((state, payload) => ({ createHotelError: payload })),
  setUpdateHotel: action((state, payload) => ({ updateHotelSuccess: payload })),
  setUpdateHotelError: action((state, payload) => ({ updateHotelError: payload })),
  setGetHotels: action((state, payload) => ({ getHotelsSuccess: payload, })),
  setGetHotelsError: action((state, payload) => ({ getHotelsError: payload })),
  setGetHotel: action((state, payload) => ({ getHotelSuccess: payload })),
  setGetHotelError: action((state, payload) => ({ getHotelError: payload })),
  resetHotel: action((state, payload) => ({ reset: true })),
  setGetAllHotelNameList: action((state, payload) => ({ getAllHotelNameListSuccess: payload })),
  setGetAllHotelNameListError: action((state, payload) => ({ getAllHotelNameListError: payload })),

  // ////////////////////////////////property types ////////////////////////////////////////

  setGetHotelPropertyTypes: action((state, payload) => ({ getHotelPropertyTypesSuccess: payload })),
  setGetHotelPropertyTypesError: action((state, payload) => ({ getHotelPropertyTypesError: payload })),

  // ////////////////////////////////property location ////////////////////////////////////////

  setGetHotelPropertyLocationTypes: action((state, payload) => ({ getHotelPropertyLocationTypesSuccess: payload })),
  setGetHotelPropertyLocationTypesError: action((state, payload) => ({ getHotelPropertyLocationTypesError: payload })),

  // ////////////////////////////////market tier ////////////////////////////////////////

  setGetHotelMarketTierTypes: action((state, payload) => ({ getHotelMarketTierTypesSuccess: payload })),
  setGetHotelMarketTierTypesError: action((state, payload) => ({ getHotelMarketTierTypesError: payload })),


  /////////////////////////////////transport model /////////////////////////////////////
  setCreateTransportSuccess: action((state, payload) => ({ createTransportSuccess: payload })),
  setCreateTransportError: action((state, payload) => ({ createTransportError: payload })),
  setGetTransportSuccess: action((state, payload) => ({ getTransportSuccess: payload })),
  setGetTransportError: action((state, payload) => ({ getTransportError: payload })),
  setUpdateTransportSuccess: action((state, payload) => ({ updateTransportSuccess: payload })),
  setUpdateTransportError: action((state, payload) => ({ updateTransportError: payload })),

  /////////////////////////////////guest comfort model /////////////////////////////////////
  setCreateGuestComfortSuccess: action((state, payload) => ({ createGuestComfortSuccess: payload })),
  setCreateGuestComfortError: action((state, payload) => ({ createGuestComfortError: payload })),
  setGetGuestComfortSuccess: action((state, payload) => ({ getGuestComfortSuccess: payload })),
  setGetGuestComfortError: action((state, payload) => ({ getGuestComfortError: payload })),
  setUpdateGuestComfortSuccess: action((state, payload) => ({ updateGuestComfortSuccess: payload })),
  setUpdateGuestComfortError: action((state, payload) => ({ updateGuestComfortError: payload })),
  setGetGuestComfortBreakFastTypes: action((state, payload) => ({ getGuestComfortBreakFastTypesSuccess: payload })),
  setGetGuestComfortBreakFastTypesError: action((state, payload) => ({ getGuestComfortBreakFastTypesError: payload })),

  /////////////////////////////////amenity model /////////////////////////////////////
  setGetAmenityList: action((state, payload) => ({ getAmenityListSuccess: payload })),
  setGetAmenityLisError: action((state, payload) => ({ getAmenityListError: payload })),

  /////////////////////////////////image model /////////////////////////////////////
  setUploadHotelImage: action((state, payload) => ({ uploadHotelImageSuccess: payload })),
  setUploadHotelImageError: action((state, payload) => ({ uploadHotelImageError: payload })),
  setGetHotelImage: action((state, payload) => ({ getHotelImageSuccess: payload })),
  setGetHotelImageError: action((state, payload) => ({ getHotelImageError: payload })),
  resetHotelImage: action((state, payload) => ({ reset: true })),
  setUpdateHotelImage: action((state, payload) => ({ updateHotelImageSuccess: payload })),
  setRemoveHotelImage: action((state, payload) => ({ removeHotelImageSuccess: payload })),
  setGetHotelImageTag: action((state, payload) => ({ getHotelImageTagSuccess: payload })),
  setGetHotelImageTagError: action((state, payload) => ({ getHotelImageTagError: payload })),
  setHotelPrimaryImageSuccess: action((state, payload) => ({ getHotelPrimaryImageSuccess: payload })),
  setHotelPrimaryImageError: action((state, payload) => ({ getHotelPrimaryImageError: payload })),

  /////////////////////////////////invoice model /////////////////////////////////////

  setCreateHotelInvoice: action((state, payload) => ({ createHotelInvoiceSuccess: payload })),
  setCreateHotelInvoiceError: action((state, payload) => ({ createHotelInvoiceError: payload })),
  setUpdateHotelInvoice: action((state, payload) => ({ updateHotelInvoiceSuccess: payload })),
  setUpdateHotelInvoiceError: action((state, payload) => ({ updateHotelInvoiceError: payload })),
  setGetHotelInvoiceDetail: action((state, payload) => ({ getHotelInvoiceDetailSuccess: payload })),
  setGetHotelInvoiceDetailError: action((state, payload) => ({ getHotelInvoiceDetailError: payload })),

  // ////////////////////////////////hotel check in ////////////////////////////////////////

  setGetCheckInProcessList: action((state, payload) => ({ getCheckInProcessListSuccess: payload })),
  setGetCheckInProcessListError: action((state, payload) => ({ getCheckInProcessListError: payload })),
  setGetHotelCheckInProcessDetailById: action((state, payload) => ({ getHotelCheckInProcessDetailByIdSuccess: payload })),
  setGetHotelCheckInProcessDetailByIdError: action((state, payload) => ({ getHotelCheckInProcessDetailByIdError: payload })),
  setCreateHotelCheckInProcessDetail: action((state, payload) => ({ createHotelCheckInProcessDetailSuccess: payload })),
  setCreateHotelCheckInProcessDetailError: action((state, payload) => ({ createHotelCheckInProcessDetailError: payload })),
  setUpdateHotelCheckInProcessDetail: action((state, payload) => ({ updateHotelCheckInProcessDetailSuccess: payload })),
  setUpdateHotelCheckInProcessDetailError: action((state, payload) => ({ updateHotelCheckInProcessDetailError: payload })),


  setGetHotelAmenities: action((state, payload) => ({ getHotelAmenitiesSuccess: payload })),
  setGetHotelAmenitiesError: action((state, payload) => ({ getHotelAmenitiesError: payload })),
  setAssignHotelAmenity: action((state, payload) => ({ assignHotelAmenitySuccess: payload })),
  setAssignHotelAmenityError: action((state, payload) => ({ assignHotelAmenityError: payload })),


  setCreateHotelTempData: action((state, payload) => ({ createHotelTempDataSuccess: payload })),
  setCreateHotelTempDataError: action((state, payload) => ({ createHotelTempDataError: payload })),


  setGetHotelsTempData: action((state, payload) => ({ getHotelsTempDataSuccess: payload, })),
  setGetHotelsTempDataError: action((state, payload) => ({ getHotelsTempDataError: payload })),

  setGetHotelsPaginationTempData: action((state, payload) => ({ getHotelsPaginationTempDataSuccess: payload, })),
  setGetHotelsPaginationTempDataError: action((state, payload) => ({ getHotelsPaginationTempDataError: payload })),


  setDeleteHotelsTempData: action((state, payload) => ({ deleteHotelsTempDataSuccess: payload, })),
  setDeleteHotelsTempDataError: action((state, payload) => ({ deleteHotelsTempDataError: payload })),

  setDeleteHotelsTempDataById: action((state, payload) => ({ deleteHotelsTempDataByIdSuccess: payload, })),
  setDeleteHotelsTempDataByIdError: action((state, payload) => ({ deleteHotelsTempDataByIdError: payload })),

  setGetReportDataFieldsData: action((state, payload) => ({ getReportDataFieldsSuccess: payload, })),
  setGetReportDataFieldsError: action((state, payload) => ({ getReportDataFieldsError: payload })),

  setGetReportDataFilterByProgram: action((state, payload) => ({ getReportDataFilterByProgramSuccess: payload, })),
  setGetReportDataFilterByProgramError: action((state, payload) => ({ getReportDataFilterByProgramError: payload })),

  setCreateReportDataFieldsData: action((state, payload) => ({ createReportDataFieldsDataSuccess: payload, })),
  setCreateReportDataFieldsError: action((state, payload) => ({ createReportDataFieldsDataError: payload })),

  setUpdateReportDataFieldsData: action((state, payload) => ({ updateReportDataFieldsDataSuccess: payload, })),
  setUpdateReportDataFieldsError: action((state, payload) => ({ updateReportDataFieldsDataError: payload })),


  setGetLocationOverviewReportData: action((state, payload) => ({ getLocationOverviewReportSuccess: payload, })),
  setGetLocationOverviewReportError: action((state, payload) => ({ getLocationOverviewReportError: payload })),

  setCreateLocationOverviewReportData: action((state, payload) => ({ createLocationOverviewReportSuccess: payload, })),
  setCreateLocationOverviewReportError: action((state, payload) => ({ createLocationOverviewReportError: payload })),

  setUpdateLocationOverviewReportData: action((state, payload) => ({ updateLocationOverviewReportSuccess: payload, })),
  setUpdateLocationOverviewReportError: action((state, payload) => ({ updateLocationOverviewReportError: payload })),


  setUploadData: action((state, payload) => ({ uploadDataSuccess: payload })),
  setUploadDataError: action((state, payload) => ({ uploadDataError: payload })),
  setGetHotelUploadData: action((state, payload) => ({ getHotelUploadDataSuccess: payload, })),
  setGetHotelUploadDataError: action((state, payload) => ({ getHotelUploadDataError: payload })),
  setGetReportData: action((state, payload) => ({ getReportDataSuccess: payload, })),
  setGetReportDataError: action((state, payload) => ({ getReportDataError: payload })),

  setGetReportDataContractedProperties: action((state, payload) => ({ getReportDataContractedPropertiesSuccess: payload, })),
  setGetReportDataContractedPropertiesError: action((state, payload) => ({ getReportDataContractedPropertiesError: payload })),


  setGetReportDataContractedPropertiesPerformanceByHotel: action((state, payload) => ({ getReportDataContractedPropertiesPerformanceByHotelSuccess: payload, })),
  setGetReportDataContractedPropertiesPerformanceByHotelError: action((state, payload) => ({ getReportDataContractedPropertiesPerformanceByHotelError: payload })),


  setGetReportDataNonContractedProperties: action((state, payload) => ({ getReportDataNonContractedPropertiesSuccess: payload, })),
  setGetReportDataNonContractedPropertiesError: action((state, payload) => ({ getReportDataNonContractedPropertiesError: payload })),

  setGetReportDataChain: action((state, payload) => ({ getReportDataChainSuccess: payload, })),
  setGetReportDataChainError: action((state, payload) => ({ getReportDataChainError: payload })),


  setGetReportDataChainOverview: action((state, payload) => ({ getReportDataChainOverviewSuccess: payload, })),
  setGetReportDataChainOverviewError: action((state, payload) => ({ getReportDataChainOverviewError: payload })),

  setGetReportDataBrandOverview: action((state, payload) => ({ getReportDataBrandOverviewSuccess: payload, })),
  setGetReportDataBrandOverviewError: action((state, payload) => ({ getReportDataBrandOverviewError: payload })),


  setGetReportDataBrandOverviewForParticularBrand: action((state, payload) => ({ getReportDataBrandOverviewForParticularBrandSuccess: payload, })),
  setGetReportDataBrandOverviewForParticularBrandError: action((state, payload) => ({ getReportDataBrandOverviewForParticularBrandError: payload })),

  // ////////////////////////////////hotel get ////////////////////////////////////////

  getAllHotels: thunk(async (actions: any, payload: any) => {
    try {
      const result = await call('get', `/hotel-sv/hotels?brand=${payload?.brand}&city=${payload?.city}&status=${payload?.status}&country=${payload?.country}&page=${payload?.page}&perPage=${payload?.perPage}`, null, null);
      actions.setAllHotelsSuccess(result.data);
    } catch (error: any) {
    }
  }),

  // ////////////////////////////////hotel create ////////////////////////////////////////

  createHotel: thunk(async (actions: any, payload: any) => {
    try {
      const result = await call('post', '/hotel-sv/hotel', payload, null);
      actions.setCreateHotel(result.data);
    } catch (error: any) {
      actions.setCreateHotelError(error.response.data);
    }
  }),

  // ////////////////////////////////hotel update ////////////////////////////////////////

  updateHotel: thunk(async (actions: any, payload: any) => {
    try {
      const result = await call('patch', '/hotel-sv/hotel', payload, null);
      actions.setUpdateHotel(result.data);
    } catch (error: any) {
      actions.setUpdateHotelError(error.response.data);
    }
  }),

  // ////////////////////////////////hotel get ////////////////////////////////////////

  getHotels: thunk(async (actions: any, payload: any) => {
    try {
      const result = await call('get', '/hotel-sv/hotel/list', null, payload);
      actions.setGetHotels(result.data);
    } catch (error: any) {
      actions.setGetHotelsError(error.response.data);
    }
  }),

  // ////////////////////////////////hotel get ////////////////////////////////////////


  getHotel: thunk(async (actions: any, id: any) => {
    try {
      const result = await call('get', `/hotel-sv/hotel/${id}`, null, null);
      actions.setGetHotel(result.data);
    } catch (error: any) {
      actions.setGetHotelError(error.response.data);
    }
  }),

  // ////////////////////////////////property location types ////////////////////////////////////////

  getHotelPropertyLocationTypes: thunk(async (actions: any, id: any) => {
    try {
      const result = await call('get', `/hotel-sv/hotel/propertyLocation/type/list`, null, null);
      actions.setGetHotelPropertyLocationTypes(result.data);
    } catch (error: any) {
      actions.setGetHotelPropertyLocationTypesError(error.response.data);
    }
  }),

  // ////////////////////////////////market tier types ////////////////////////////////////////

  getHotelMarketTierTypes: thunk(async (actions: any, id: any) => {
    try {
      const result = await call('get', `/hotel-sv/hotel/marketTier/type/list`, null, null);
      actions.setGetHotelMarketTierTypes(result.data);
    } catch (error: any) {
      actions.setGetHotelMarketTierTypesError(error.response.data);
    }
  }),

  // //////////////////////////////// property types ////////////////////////////////////////

  getHotelPropertyTypes: thunk(async (actions: any, id: any) => {
    try {
      const result = await call('get', `/hotel-sv/hotel/propertyType/list`, null, null);
      actions.setGetHotelPropertyTypes(result.data);
    } catch (error: any) {
      actions.setGetHotelPropertyTypesError(error.response.data);
    }
  }),

  // //////////////////////////////// Transport get by hotel id ////////////////////////////////////////
  getTransport: thunk(async (actions: any, hotelId: any) => {
    try {
      const result = await call('get', `/hotel-sv/hotel/transport/${hotelId}`, null, null);
      actions.setGetTransportSuccess(result.data);
    } catch (error: any) {
      actions.setGetTransportError(error.response.data);
    }
  }),

  // //////////////////////////////// Transport create ////////////////////////////////////////
  createTransport: thunk(async (actions: any, payload: any) => {
    try {
      const result = await call('post', '/hotel-sv/hotel/transport', payload, null);
      actions.setCreateTransportSuccess(result.data);
    } catch (error: any) {
      actions.setCreateTransportError(error.response.data);
    }
  }),

  // //////////////////////////////// Transport update ////////////////////////////////////////
  updateTransport: thunk(async (actions: any, payload: any) => {
    try {
      const result = await call('patch', `/hotel-sv/hotel/transport`, payload, null);
      actions.setUpdateTransportSuccess(result.data);
    } catch (error: any) {
      actions.setUpdateTransportError(error.response.data);
    }
  }),

  // //////////////////////////////// GuestComfort get by hotel id ////////////////////////////////////////
  getGuestComfort: thunk(async (actions: any, hotelId: any) => {
    try {
      const result = await call('get', `/hotel-sv/hotel/guest-comfort/${hotelId}`, null, null);
      actions.setGetGuestComfortSuccess(result.data);
    } catch (error: any) {
      actions.setGetGuestComfortError(error.response.data);
    }
  }),

  // //////////////////////////////// GuestComfort create ////////////////////////////////////////
  createGuestComfort: thunk(async (actions: any, payload: any) => {
    try {
      const result = await call('post', '/hotel-sv/hotel/guest-comfort', payload, null);
      actions.setCreateGuestComfortSuccess(result.data);
    } catch (error: any) {
      actions.setCreateGuestComfortError(error.response.data);
    }
  }),

  // //////////////////////////////// GuestComfort update ////////////////////////////////////////
  updateGuestComfort: thunk(async (actions: any, payload: any) => {
    try {
      const result = await call('patch', `/hotel-sv/hotel/guest-comfort`, payload, null);
      actions.setUpdateGuestComfortSuccess(result.data);
    } catch (error: any) {
      actions.setUpdateGuestComfortError(error.response.data);
    }
  }),

  // //////////////////////////////// Amenity get ////////////////////////////////////////
  getAmenityList: thunk(async (actions: any) => {
    try {
      const result = await call('get', `/hotel-sv/hotel/amenity`, null, null);
      actions.setGetAmenityList(result.data);
    } catch (error: any) {
      actions.setGetAmenityListError(error.response.data);
    }
  }),

  // //////////////////////////////// break fast type ////////////////////////////////////////

  getGuestComfortBreakFastTypes: thunk(async (actions: any, id: any) => {
    try {
      const result = await call('get', `/hotel-sv/hotel/breakfast/type/list`, null, null);
      actions.setGetGuestComfortBreakFastTypes(result.data);
    } catch (error: any) {
      actions.setGetGuestComfortBreakFastTypesError(error.response.data);
    }
  }),

  // //////////////////////////////// image upload ////////////////////////////////////////

  uploadHotelImage: thunk(async (actions: any, payload: any) => {
    try {
      const result = await call('post', '/hotel-sv/hotel/image', payload, null);
      if (payload.isDeleted) {
        actions.setRemoveHotelImage(result.data);
      }
      else if (payload.imageId !== '' && payload.isDeleted === false) {
        actions.setUpdateHotelImage(result.data);
      }
      else {
        actions.setUpdateHotelImage(result.data);
      }
    } catch (error: any) {
      actions.setUploadHotelImageError(error.response.statusText);
    }
  }),

  // //////////////////////////////// image list ////////////////////////////////////////

  getHotelImage: thunk(async (actions: any, hotelId: any) => {
    try {
      const result = await call('get', `/hotel-sv/hotel/image/${hotelId}`, null, null);
      actions.setGetHotelImage(result.data);
    } catch (error: any) {
      actions.setGetHotelImageError(error.response.data);
    }
  }),

  // //////////////////////////////// image tag ////////////////////////////////////////

  getHotelImageTag: thunk(async (actions: any, brandId: any) => {
    try {
      const result = await call('get', `/hotel-sv/hotel/image/tag/${brandId}`, null, null);
      actions.setGetHotelImageTag(result.data);
    } catch (error: any) {
      actions.setGetHotelImageTagError(error.response.data);
    }
  }),

  hotelPrimaryImage: thunk(async (actions: any, payload: any) => {
    try {
      const result = await call('post', '/hotel-sv/hotel/select-primary-image', payload, null);
      actions.setHotelPrimaryImageSuccess(result.data);
    } catch (error: any) {
      actions.setHotelPrimaryImageError(error.response.data);
    }
  }),

  // //////////////////////////////// invoice details ////////////////////////////////////////

  getHotelInvoiceDetailById: thunk(async (actions: any, hotelId: any) => {
    try {
      const result = await call('get', `/hotel-sv/hotel/hotelInvoice/${hotelId}`, null, null);
      actions.setGetHotelInvoiceDetail(result.data);
    } catch (error: any) {
      actions.setGetHotelInvoiceDetailError(error.response.data);
    }
  }),

  // //////////////////////////////// invoice create ////////////////////////////////////////

  createHotelInvoiceDetail: thunk(async (actions: any, payload: any) => {
    try {
      const result = await call('post', '/hotel-sv/hotel/hotelInvoice', payload, null);
      actions.setCreateHotelInvoice(result.data);
    } catch (error: any) {
      actions.setCreateHotelInvoiceError(error.response.data);
    }
  }),

  // //////////////////////////////// invoice update ////////////////////////////////////////

  updateHotelInvoiceDetail: thunk(async (actions: any, payload: any) => {
    try {
      const result = await call('patch', '/hotel-sv/hotel/hotelInvoice', payload, null);
      actions.setUpdateHotelInvoice(result.data);
    } catch (error: any) {
      actions.setUpdateHotelInvoiceError(error.response.data);
    }
  }),


  // get check in process list
  getCheckInProcessDetail: thunk(async (actions: any) => {
    try {
      const result = await call('get', `/hotel-sv/hotel/checkInProcess/list`, null, null);
      actions.setGetCheckInProcessList(result.data);
    } catch (error: any) {
      actions.setGetCheckInProcessListError(error.response.data);
    }
  }),

  // get hotel check in process list
  getHotelCheckInProcessDetailByHotelId: thunk(async (actions: any, hotelId: any) => {
    try {
      const result = await call('get', `/hotel-sv/hotel/hotelCheckInProcess/${hotelId}`, null, null);
      actions.setGetHotelCheckInProcessDetailById(result.data);
    } catch (error: any) {
      actions.setGetHotelCheckInProcessDetailByIdError(error.response.data);
    }
  }),

  // create hotel check in process
  createHotelCheckInProcessDetail: thunk(async (actions: any, payload: any) => {
    try {
      const result = await call('post', '/hotel-sv/hotel/hotelCheckInProcess', payload, null);
      actions.setCreateHotelCheckInProcessDetail(result.data);
    } catch (error: any) {
      actions.setCreateHotelCheckInProcessDetailError(error.response.data);
    }
  }),

  // update hotel check in process
  updateHotelCheckInProcessDetail: thunk(async (actions: any, payload: any) => {
    try {
      const result = await call('patch', '/hotel-sv/hotel/hotelCheckInProcess', payload, null);
      actions.setUpdateHotelCheckInProcessDetail(result.data);
    } catch (error: any) {
      actions.setUpdateHotelCheckInProcessDetailError(error.response.data);
    }
  }),

  // get hotel amenities
  getHotelAmenities: thunk(async (actions: any, hotelId: any) => {
    try {
      const result = await call('get', `/hotel-sv/hotel/assignAmenity/${hotelId}`, null, null);
      actions.setGetHotelAmenities(result.data);
    } catch (error: any) {
      actions.setGetHotelAmenitiesError(error.response.data);
    }
  }),

  //create and update hotel amenity
  assignHotelAmenity: thunk(async (actions: any, payload: any) => {
    try {
      const result = await call('post', '/hotel-sv/hotel/assignAmenity', payload, null);
      actions.setAssignHotelAmenity(result.data);
    } catch (error: any) {
      actions.setAssignHotelAmenityError(error.response.data);
    }
  }),

  // get all hotelName list
  getAllHotelNameList: thunk(async (actions: any) => {
    try {
      const result = await call('get', `/hotel-sv/hotel/name/list`, null, null);
      actions.setGetAllHotelNameList(result.data);
    } catch (error: any) {
      actions.setGetAllHotelNameListError(error.response.data);
    }
  }),


  createHotelTempData: thunk(async (actions: any, payload: any) => {
    try {
      const result = await call('post', '/hotel-sv/hotel/hotelTempData', payload, null);
      actions.setCreateHotelTempData(result.data);
    } catch (error: any) {
      actions.setCreateHotelTempDataError(error.response.data);
    }
  }),

  getAllHotelPaginationTempData: thunk(async (actions: any, payload: any) => {
    try {
      const result = await call('get', `/hotel-sv/hotel/hotelTempData/list?clientId=${payload?.clientId}&programmeId=${payload?.programmeId}&reportId=${payload?.reportId}&page=${payload?.page}&perPage=${payload?.perPage}`, null, null);
      return result?.data;
      // actions.setGetHotelsPaginationTempData(result.data);
    } catch (error: any) {
      //  actions.setGetHotelsPaginationTempDataError(error.response.data);
    }
  }),

  getAllHotelTempData: thunk(async (actions: any, payload: any) => {
    try {
      const result = await call('get', `/hotel-sv/hotel/hotelTempData/list?clientId=${payload?.clientId}&programmeId=${payload?.programmeId}&reportId=${payload?.reportId}&page=${payload?.page}&perPage=${payload?.perPage}`, null, null);
      actions.setGetHotelsTempData(result.data);
    } catch (error: any) {
      actions.setGetHotelsTempDataError(error.response.data);
    }
  }),

  deleteHotelsTempData: thunk(async (actions: any, payload: any) => {
    try {
      const result = await call('delete', `/hotel-sv/hotel/hotelTempData?clientId=${payload?.clientId}&programmeId=${payload?.programmeId}`, null, null);
      actions.setDeleteHotelsTempData(result.data);
    } catch (error: any) {
      actions.setDeleteHotelsTempDataError(error.response.data);
    }
  }),


  deleteHotelsTempDataById: thunk(async (actions: any, id: any) => {
    try {
      const result = await call('delete', `/hotel-sv/hotel/hotelTempData/${id}`, null, null);
      actions.setDeleteHotelsTempDataById(result.data);
    } catch (error: any) {
      actions.setDeleteHotelsTempDataByIdError(error.response.data);
    }
  }),

  createReportDataFields: thunk(async (actions: any, payload: any) => {
    try {
      const result = await call('post', '/hotel-sv/hotel/reportDataFields', payload, null);
      actions.setCreateReportDataFieldsData(result.data);

    } catch (error: any) {
      actions.setCreateReportDataFieldsError(error.response.data);
    }
  }),

  getReportDataFields: thunk(async (actions: any, id: any) => {
    try {
      const result = await call('get', `/hotel-sv/hotel/reportDataFields/${id}`, null, null);
      actions.setGetReportDataFieldsData(result.data);
    } catch (error: any) {
      actions.setGetReportDataFieldsError(error.response.data);
    }
  }),

  updateReportDataFields: thunk(async (actions: any, payload: any) => {
    try {
      const result = await call('patch', '/hotel-sv/hotel/reportDataFields', payload, null);
      actions.setUpdateReportDataFieldsData(result.data);

    } catch (error: any) {
      actions.setUpdateReportDataFieldsError(error.response.data);
    }
  }),

  createLocationOverviewReport: thunk(async (actions: any, payload: any) => {
    try {
      const result = await call('post', '/hotel-sv/hotel/locationOverviewReport', payload, null);
      actions.setCreateLocationOverviewReportData(result.data);

    } catch (error: any) {
      actions.setCreateLocationOverviewReportError(error.response.data);
    }
  }),

  getLocationOverviewReport: thunk(async (actions: any, id: any) => {
    try {
      const result = await call('get', `/hotel-sv/hotel/locationOverviewReport/${id}`, null, null);
      actions.setGetLocationOverviewReportData(result.data);
    } catch (error: any) {
      actions.setGetLocationOverviewReportError(error.response.data);
    }
  }),

  updateLocationOverviewReport: thunk(async (actions: any, payload: any) => {
    try {
      const result = await call('patch', '/hotel-sv/hotel/locationOverviewReport', payload, null);
      actions.setUpdateLocationOverviewReportData(result.data);

    } catch (error: any) {
      actions.setUpdateLocationOverviewReportError(error.response.data);
    }
  }),

  uploadData: thunk(async (actions: any, payload: any) => {
    try {
      const result = await call('post', '/hotel-sv/hotel/uploadData', payload, null);
      actions.setUploadData(result.data);
    } catch (error: any) {
      actions.setUploadDataError(error.response.data);
    }
  }),

  getHotelUploadData: thunk(async (actions: any, id: any) => {
    try {
      const result = await call('get', `/hotel-sv/hotel/uploadData/${id}`, null, null);
      actions.setGetHotelUploadData(result.data);
    } catch (error: any) {
      actions.setGetHotelUploadDataError(error.response.data);
    }
  }),

  getReportData: thunk(async (actions: any, payload: any) => {
    try {
      const result = await call('get', `/hotel-sv/hotel/reportData/list?reportId=${payload?.reportId}&programmeId=${payload?.programmeId}`, null, null);
      actions.setGetReportData(result.data);
    } catch (error: any) {
      actions.setGetReportDataError(error.response.data);
    }
  }),

  getReportDataFilterByProgram: thunk(async (actions: any, payload: any) => {
    try {
      const result = await call('get', `/hotel-sv/hotel/reportData/filterList?reportId=${payload?.reportId}&programmeId=${payload?.programmeId}`, null, null);
      actions.setGetReportDataFilterByProgram(result.data);
    } catch (error: any) {
      actions.setGetReportDataFilterByProgramError(error.response.data);
    }
  }),

  getReportDataContractedProperties: thunk(async (actions: any, payload: any) => {
    try {
      const result = await call('get', `/hotel-sv/hotel/reportData/contractedProperty?reportId=${payload?.reportId}&programmeId=${payload?.programmeId}`, null, null);
      actions.setGetReportDataContractedProperties(result.data);
    } catch (error: any) {
      actions.setGetReportDataContractedPropertiesError(error.response.data);
    }
  }),

  getReportDataContractedPropertiesPerformanceByHotel: thunk(async (actions: any, payload: any) => {
    try {
      const result = await call('get', `/hotel-sv/hotel/reportData/contractedProperty/PerformanceByHotel?reportId=${payload?.reportId}&programmeId=${payload?.programmeId}`, null, null);
      actions.setGetReportDataContractedPropertiesPerformanceByHotel(result.data);
    } catch (error: any) {
      actions.setGetReportDataContractedPropertiesPerformanceByHotelError(error.response.data);
    }
  }),

  getReportDataNonContracted: thunk(async (actions: any, payload: any) => {
    try {
      const result = await call('get', `/hotel-sv/hotel/reportData/nonContractedProperty?reportId=${payload?.reportId}&programmeId=${payload?.programmeId}`, null, null);
      actions.setGetReportDataNonContractedProperties(result.data);
    } catch (error: any) {
      actions.setGetReportDataNonContractedPropertiesError(error.response.data);
    }
  }),

  getReportDataChain: thunk(async (actions: any, payload: any) => {
    try {
      const result = await call('get', `/hotel-sv/hotel/reportData/chain?reportId=${payload?.reportId}&programmeId=${payload?.programmeId}`, null, null);
      actions.setGetReportDataChain(result.data);
    } catch (error: any) {
      actions.setGetReportDataChainError(error.response.data);
    }
  }),


  getReportDataChainOverview: thunk(async (actions: any, payload: any) => {
    try {
      const result = await call('get', `/hotel-sv/hotel/reportData/chainOverview?reportId=${payload?.reportId}&programmeId=${payload?.programmeId}&parentAccommodationCompany=${payload?.parentAccommodationCompany}`, null, null);
      actions.setGetReportDataChainOverview(result.data);
    } catch (error: any) {
      actions.setGetReportDataChainOverviewError(error.response.data);
    }
  }),

  getReportDataBrandOverview: thunk(async (actions: any, payload: any) => {
    try {
      const result = await call('get', `/hotel-sv/hotel/reportData/brandOverview?reportId=${payload?.reportId}&programmeId=${payload?.programmeId}&parentAccommodationCompany=${payload?.parentAccommodationCompany}`, null, null);
      actions.setGetReportDataBrandOverview(result.data);
    } catch (error: any) {
      actions.setGetReportDataBrandOverviewError(error.response.data);
    }
  }),

  getReportDataBrandOverviewForParticularBrand: thunk(async (actions: any, payload: any) => {
    try {
      const result = await call('get', `/hotel-sv/hotel/reportData/brandOverview/brand?reportId=${payload?.reportId}&programmeId=${payload?.programmeId}&parentAccommodationCompany=${payload?.parentAccommodationCompany}&brandId=${payload?.brandId}`, null, null);
      actions.setGetReportDataBrandOverviewForParticularBrand(result.data);
    } catch (error: any) {
      actions.setGetReportDataBrandOverviewForParticularBrandError(error.response.data);
    }
  }),

}