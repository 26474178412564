export const UserGroups = {
  super_admin: 'super_admin',
  chief_brand_admin: 'chief_brand_admin',
  corporate_admin: 'corporate_admin',
  brand_admin: 'brand_admin',
  corporate_sub_admin: 'corporate_sub_admin',
  chief_property_admin: 'chief_property_admin',
  booker: "booker",
  property_admin: 'property_admin',
  department_admin: 'department_admin',
  user: 'user',
  programme_admin: 'programme_admin',
  traveler: 'traveler',
  CHIEF_PROGRAMME_ADMIN: 'chief_programme_admin',
  CHIEF_ADMIN: 'chief_admin',
  chief_hotel_admin: 'chief_hotel_admin',
  hotel_admin: 'hotel_admin',
}

export const ClientPermission = {
  tender_admin: 'tender_admin',
  tender_viewer: 'tender_viewer',
  booking_admin: 'booking_admin',
  booking_viewer: 'booking_viewer',
  booking_travel_booker: 'booking_travel_booker',
  booking_traveller: 'booking_traveller',
}

export const QuestionTypes = {
  yes_no: 0,
  text: 1,
  number: 2,
  currency: 3,
  date: 4,
  one_of: 5,
  many_of: 6,
  rate: 7,
  read_doc: 8,
  sign: 9,
  contact_list: 10,
  user_defined_list: 11,
  image: 12,
  video: 13,
  document: 14,
  address: 15,
  unit: 16,
}

export const QuestionEvidenceTypes = {
  none: 0,
  photo: 1,
  video: 2,
  document: 3,
}

export const QuestionEvidenceRequired = {
  never: 0,
  always: 1,
  on_yes: 2,
  on_no: 3,
}

export const BrandAuditIds = {
  client_id: '60657eca0b45f3d24c30962b',
  template_id: '6063268ff40eba58e5cfe8a5',
}

export const BrandMyoManagementIds = {
  client_id: '60657eca0b45f3d24c30962b',
  template_id: '6063268ff40eba58e5cfe8a5',
}

export const AmenityType = {
  building: 'building',
  accommodation: 'accommodation',
  guest_comfort: 'guest_comfort',
  all: 'all'
}

export const RateStatus = {
  pending_approval: 'pending_approval',
  rejected: 'rejected',
  published: 'published'
}

export const UserAccess = {
  brand_access: {
    details: { code: 'BA_DETAILS', text: "Brand Details" },
    users: { code: 'BA_USERS', text: "Brand Users" },
    // audit: { code: 'BA_AUDIT', text: "Brand Audits / Surveys" },
    tenders: { code: 'BA_TENDERS', text: "Brand RFPs" },
    bookings: { code: 'BA_BOOKINGS', text: "Brand Booking Management (Accept, Decline, Refund, Upload Invoice, Refund, Message)" },
    reporting: { code: 'BA_REPORTS', text: "Brand Business Intelligence (reporting)" },
  },
  client_access: {
    details: { code: 'CA_DETAILS', text: "Client Information" },
    users: { code: 'CA_USERS', text: "Client Users" },
    audit: { code: 'CA_AUDIT', text: "Client Surveys" },
    tenders: { code: 'CA_TENDERS', text: "Client Tenders" },
    bookings: { code: 'CA_BOOKINGS', text: "Client Booking Management (Book, Cancel, Change) (if checkbox ticked then user can book for any user under this client))" },
    reporting: { code: 'CA_REPORTS', text: "Client Business Intelligence (reporting)" },
  },
  property_access: {
    details: { code: 'PA_DETAILS', text: "Property Information" },
    users: { code: 'PA_USERS', text: "Property Users" },
    audit: { code: 'PA_AUDIT', text: "Property Audits / Surveys" },
    tenders: { code: 'PA_TENDERS', text: "Property Tenders" },
    bookings: { code: 'PA_BOOKINGS', text: "Property Booking Management (Accept, Decline, Refund, Upload Invoice, Refund, Message)" },
    reporting: { code: 'PA_REPORTS', text: "Property Business Intelligence (reporting)" },
  },
  hotel_access: {
    details: { code: 'HA_DETAILS', text: "Hotel Information" },
    users: { code: 'HA_USERS', text: "Hotel Users" },
    audit: { code: 'HA_AUDIT', text: "Hotel Audits / Surveys" },
    tenders: { code: 'HA_TENDERS', text: "Hotel Tenders" },
    bookings: { code: 'HA_BOOKINGS', text: "Hotel Booking Management (Accept, Decline, Refund, Upload Invoice, Refund, Message)" },
    reporting: { code: 'HA_REPORTS', text: "Hotel Business Intelligence (reporting)" },
  },
  department_access: {
    details: { code: 'DA_DETAILS', text: "Department Information" },
    users: { code: 'DA_USERS', text: "Department Users" },
    audit: { code: 'DA_AUDIT', text: "Department Surveys" },
    tenders: { code: 'DA_TENDERS', text: "Department Tenders" },
    bookings: { code: 'DA_BOOKINGS', text: "Department Booking Management" },
    reporting: { code: 'DA_REPORTS', text: "Department Business Intelligence (reporting)" },
  },
  programme_access: {
    superAdmin: { code: 'PG_CORPORATE_SUPER_ADMIN', text: "Corporate super admin" },
    programmeAdmin: { code: 'PG_ADMIN', text: "Programme admin" },
    programmeUsers: { code: 'PG_USERS', text: "Programme users" },
    reporting: { code: 'PG_REPORTS', text: "Programme Intelligence (reporting)" },
    companyBooker: { code: 'CAN_BOOK_FOR_EVERYONE', text: "Company Booker" },
    traveller: { code: 'CAN_VIEW_BOOKING', text: "Traveller" },
  },

  company_access: {
    booker: { code: 'CU_BOOKER', text: "Booker Management" },
    traveler: { code: 'CU_TRAVELER', text: "Traveler Management" },
    booking: { code: 'CU_BOOKING', text: "Booking Management" },
    location: { code: 'CU_LOCATION', text: "Location Management" },
    payment: { code: 'CU_PAYMENT', text: "Payment Management" },
    proposal: { code: 'CU_PROPOSAL', text: "Proposal Management" },
    programme: { code: 'CU_PROGRAMME', text: "Programme Management" },
    permission: { code: 'CU_PERMISSION', text: "Company Permissions" },
  },

}

export const BrandStatus = {
  pending: 'pending',
  incomplete: 'incomplete',
  accepted: 'accepted',
  archive: 'archive',
  reActivate: 'reActivate',
  review: 'review',
  not_started: 'not_started',
  inviteSent: 'inviteSent'
}

export const PropertyStatus = {
  pending: 'pending',
  approved: 'complete',
  awaiting: 'incomplete',
  review: 'review',
  archived: 'archived',
  inprogress: 'inprogress'
}

export const HotelStatus = {
  pending: 'pending',
  approved: 'complete',
  awaiting: 'incomplete',
  review: 'review',
  archived: 'archived',
  inprogress: 'inprogress'
}

export const PropertyAuditStatus = {
  NOT_STARTED: 'NOT_STARTED',
  IN_PROGRESS: 'IN_PROGRESS',
  CERTIFIED: 'CERTIFIED',
  DECLINE: 'DECLINE',
  PENDING_APPROVAL: 'PENDING_APPROVAL',
  AWAITING_APPROVAL: 'AWAITING_APPROVAL',
  APPROVE: 'APPROVE'
}

export const HotelAuditStatus = {
  NOT_STARTED: 'NOT_STARTED',
  IN_PROGRESS: 'IN_PROGRESS',
  CERTIFIED: 'CERTIFIED',
  DECLINE: 'DECLINE',
  PENDING_APPROVAL: 'PENDING_APPROVAL',
  AWAITING_APPROVAL: 'AWAITING_APPROVAL',
  APPROVE: 'APPROVE'
}

export const ALL_INVENTORIES_APPROVAL_STATUS = {
  PENDING: 'pending',
  APPROVE: 'approve',
}

export const AvailabilityHashGet = {
  SEARCH_RESPONSE: 'searchResponse',
  SEARCH_RESPONSE_OTHER: 'searchResponseOther',
}

export const Status = {
  ACTIVE: 'active',
  INACTIVE: 'inactive',
  DELETED: 'delete',
}

export const UserProfile = {
  COMPLETED: 'COMPLETED',
  INCOMPLETE: 'INCOMPLETE',
}

export const CommonAnswer = {
  YES: 'Yes',
  NO: 'No',
}

export const Sizes = {
  CM: 'cm',
  INCH: 'inch',
}

export const RfpProcessStatus = {
  NOTSTARTED: 'not_started',
  INPROGRESS: 'in_progress',
  COMPLETED: 'completed',
  CANCELLED:'cancelled'
}

export const RfpReportStatus = {
  INCOMPLETE: 'incomplete',
  COMPLETE: 'complete'
}

export const RateGridStatus = {
  APPROVED: 'published',
  PENDING_APPROVAL: 'pending_approval',
}

export const BrandRfpProcessQuestionsStatus = {
  COMPLETE: 'complete',
  INCOMPLETE: 'incomplete',

}

export const rfpPropertyStatusToInventory = {
  PROPOSED: 'proposed',
  INCOMPLETE: 'incomplete',
  ACCEPTED: 'accepted',
  DECLINED: 'declined',
  REVIEW: 'review',
  REVIEWED: 'reviewed',
  NEGOTIATE: 'negotiate'
}

export const BrandRfpPropertyEvaluationStatus = {
  COMPLETE: 'complete',
  INCOMPLETE: 'incomplete',
  IN_PROGRESS: 'in_progress',
  ACCEPTED: 'accepted',
  DECLINED: 'declined',
  PART_ACCEPTED: 'part accepted',
  REVIEW: 'review',
  REVIEWED: 'reviewed',
  ACTIVATED: 'activated',
  ARCHIVE: 'archive'
}

export const BrandInvitationStatus = {
  sent: 'sent',
  pending: 'pending',
  rejected: 'rejected'
}

export const brandRfpStatus = {
  COMPLETED: 'completed',
  INCOMPLETE: 'incomplete',
  SUBMITTED: 'submitted',
}

export const InventoryStatus = {
  INCOMPLETE: 'incomplete',
  PENDING: 'pending',
  APPROVED: 'complete',
  REVIEW: 'review',
  INPROGRESS: 'inprogress'
}

export const BookerPermissions = {
  CAN_BOOK_FOR_EVERYONE: 'CAN_BOOK_FOR_EVERYONE',
  TRAVELER_SPECIFIED: 'TRAVELER_SPECIFIED',
  COMPANY_SPECIFIED: 'COMPANY_SPECIFIED',
}
export const TravelerPermissions = {
  CAN_BOOK_FOR_SELF: 'CAN_BOOK_FOR_SELF',
  CAN_VIEW_BOOKING: 'CAN_VIEW_BOOKING',
}

export const PaymentPermissions = {
  CREATE_CARD_FOR_BOOKING: 'CREATE_CARD_FOR_BOOKING',
  INVOICE: 'INVOICE',
}

export const ProgrammeStatus = {
  INCOMPLETE: 'incomplete',
  PENDING: 'pending',
  ACCEPTED: 'approved',
}

export const PropertyInventoryStatus = {
  PROPOSED: 'proposed',
  ACCEPTED: 'accepted',
  DECLINED: 'declined',
  NEGOTIATE: 'negotiate',
  REVIEW: 'review',
  REVIEWED: 'reviewed',
}

export const REQUEST_BOOKING_STATUS = {
  PENDING: 'pending',
  NOT_AVAILABLE: 'not_available',
  AVAILABLE: 'available',
  ALT_OFFER: 'alt_offer',
  DECLINED: 'declined',
  OFFERED: 'offered',
  BOOKED: 'booked',
  EXPIRED: 'expired',
};

export const LIVE_BOOKING_STATUS = {
  UP_COMING: 'upcoming', // UpComing
  COMPLETE: 'complete',
  CANCELLED: 'cancelled',
  AMEND_PENDING: 'amend_pending',
  CANCEL_PENDING: 'cancel_pending',
  CURRENT: 'current',
  AWAIT_INVOICE: 'await_invoice',
};


export const ACCOMMODATION_TYPE = {
  SA: 'serviced_apartments',
  HOTEL: 'hotel',
  BOTH: 'both',
};