import React, { useEffect, useState } from 'react';
import DataTable, { createTheme } from 'react-data-table-component';
import { $mysaBlack, $mysaTeal, $mysaYellow, mysaTableTheme, overlayTriggerDelay, tableStyles } from '../../../../common/components-style';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { RateGridStatus } from '../../../../common/constants';
import { Button, Col, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import ViewEdit from '../../../../images/icons/svg/outside/Icon-Function-View-Edit.svg';
import _ from 'lodash';

////////////////////// table theme setting ////////////////////////////////////
createTheme('mysaTable', mysaTableTheme, 'light');

const formButton = {
    height: "50px",
    borderRadius: 30,
    paddingLeft: "30px",
    paddingRight: "30px",
    marginRight: '20px',
    border: '1px solid #ffffff',
    background: $mysaYellow,
    color: $mysaBlack
} as React.CSSProperties;


const RateManagementSetUp: React.FC<any> = ({ propertyId, setIsMain, selectedInventory, brand, inventoryId, formOpen, setFormOpen, setRateId, setRateType, setMyoCorSeasonIndex, setMyoCorSeasonName, setClientId, setSeasonalRateId }): JSX.Element => {
    const [rateGridData, setRateGridData] = useState<any>([]);
    const [hideMYOClientCreate, setHideMYOClientCreate] = useState<boolean>(false);

    ////////////////////////////////////////////////////////////////////////////////////////////////////
    const { getRateGrid, brandRateByInventoryId } = useStoreActions<any>((actions) => ({
        getRateGrid: actions.rate.getRateGrid,
        brandRateByInventoryId: actions.rfpProcess.brandRateByInventoryId
    }));

    ////////////////////////////////////////////////////////////////////////////////////////////////////
    const { getRateGridListSuccess, getBrandRateByInventoryIdSuccess } = useStoreState<any>((state) => ({
        getRateGridListSuccess: state.rate.getRateGridListSuccess,
        getBrandRateByInventoryIdSuccess: state.rfpProcess.getBrandRateByInventoryIdSuccess
    }));

    ////////////////////////////////////////////////////////////////////////////////////////////////////////
    useEffect(() => {

        const params: any = {};
        if (inventoryId) {

            params.propertyId = propertyId;
            params.inventoryId = inventoryId;
          
                getRateGrid(params);
      
            const param: any = {};
            param.propertyId = propertyId;
            param.propertyInventoryId = inventoryId;
            param.brandId = brand?._id;
           
            brandRateByInventoryId(param);
        }
    }, [brand, brand?._id, brandRateByInventoryId, getRateGrid, inventoryId, propertyId]);

    //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    useEffect(() => {
        if (getRateGridListSuccess) {
            if (getRateGridListSuccess.data.length !== 0) {
                setHideMYOClientCreate(true);
                const data = _.map(getRateGridListSuccess.data, rate => {
                    return {
                        key: rate.id,
                        oneTo7: rate.oneTo7,
                        eightTo14: rate.eightTo14,
                        fifteenTo28: rate.fifteenTo28,
                        twentyNineTo90: rate.twentyNineTo90,
                        ninetyOnePlus: rate.ninetyOnePlus,
                        client: rate.client?.name,
                        clientId: rate.client?.id,
                        typeOfRate: rate.typeOfRate,
                        rateStatus: rate.rateStatus,
                        seasonalRateId: rate.seasonalRateId,
                        maximumStayed: selectedInventory?.maximumStayed,
                        minimumStayed: selectedInventory?.minimumStayed,
                        isNot: false
                    }
                });
                setRateGridData(data);
            } else {
                setHideMYOClientCreate(false);
                setRateGridData([]);
                // setRateGridData(getRateGridListSuccess.data);
            }
        }
    }, [getRateGridListSuccess, inventoryId, propertyId, selectedInventory?.maximumStayed, selectedInventory?.minimumStayed]);


    //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    useEffect(() => {
        if (getBrandRateByInventoryIdSuccess) {
            if (getBrandRateByInventoryIdSuccess.data.length !== 0) {
                setHideMYOClientCreate(true);
                const data = _.map(getBrandRateByInventoryIdSuccess.data, rate => {

                    return {
                        key: rate._id,
                        oneTo7: rate?.genericRate?.standardRate?.oneTo7,
                        eightTo14: rate?.genericRate?.standardRate?.eightTo14,
                        fifteenTo28: rate?.genericRate?.standardRate?.fifteenTo28,
                        twentyNineTo90: rate?.genericRate?.standardRate?.twentyNineTo90,
                        ninetyOnePlus: rate?.genericRate?.standardRate?.ninetyOnePlus,
                        client: rate.programme?.programmeName,
                        //   clientId: rate.client?.id,
                        typeOfRate: 'Standard',
                        rateStatus: rate?.evaluationStatus,
                        // seasonalRateId: rate.seasonalRateId,
                        maximumStayed: selectedInventory?.maximumStayed,
                        minimumStayed: selectedInventory?.minimumStayed,
                        isNot: true
                    }
                });
                setRateGridData(prevData => [...prevData, ...data]);

            } else {
                setHideMYOClientCreate(false);
                setRateGridData([]);
                // setRateGridData(getRateGridListSuccess.data);
            }
        }
    }, [getBrandRateByInventoryIdSuccess, selectedInventory?.maximumStayed, selectedInventory?.minimumStayed]);




    ////////////////////////////////////////////////////////////////////////////////////////////////////
    const columns = [
        {
            name: 'Client',
            selector: row => row.client,
        },
        {
            name: 'Rate Type',
            selector: row => row.typeOfRate,
        },
        {
            name: '1-7 nights',
            selector: row => selectedInventory?.minimumStayed > 7 ? 'N/A' : row.oneTo7,
        },
        {
            name: '8-14 nights',
            selector: row => selectedInventory?.minimumStayed > 14 ? 'N/A' : (selectedInventory?.doYouHaveMaximumLengthOfStay === 'yes' && selectedInventory?.maximumStayed <= 7) ? 'N/A' : row.eightTo14,
        },
        {
            name: '15-28 nights',
            selector: row => selectedInventory?.minimumStayed > 28 ? 'N/A' : (selectedInventory?.doYouHaveMaximumLengthOfStay === 'yes' && selectedInventory?.maximumStayed <= 7) ? 'N/A' : (selectedInventory?.doYouHaveMaximumLengthOfStay === 'yes' && selectedInventory?.maximumStayed <= 14) ? 'N/A' : row.fifteenTo28,
        },
        {
            name: '29-90 nights',
            selector: row => selectedInventory?.minimumStayed > 90 ? 'N/A' : (selectedInventory?.doYouHaveMaximumLengthOfStay === 'yes' && selectedInventory?.maximumStayed <= 7) ? 'N/A' : (selectedInventory?.doYouHaveMaximumLengthOfStay === 'yes' && selectedInventory?.maximumStayed <= 14) ? 'N/A' : (selectedInventory?.doYouHaveMaximumLengthOfStay === 'yes' && selectedInventory?.maximumStayed <= 28) ? 'N/A' : row.twentyNineTo90,
        },
        {
            name: '3 months +',
            selector: row => (selectedInventory?.doYouHaveMaximumLengthOfStay === 'yes' && selectedInventory?.maximumStayed <= 7) ? true : (selectedInventory?.doYouHaveMaximumLengthOfStay === 'yes' && selectedInventory?.maximumStayed <= 14) ? 'N/A' : (selectedInventory?.doYouHaveMaximumLengthOfStay === 'yes' && selectedInventory?.maximumStayed <= 28) ? 'N/A' : (selectedInventory?.doYouHaveMaximumLengthOfStay === 'yes' && selectedInventory?.maximumStayed <= 90) ? 'N/A' : row.ninetyOnePlus,
            // (row?.maximumStayed || row?.minimumStayed) ? 'N/A' : 
        },
        {
            name: 'Status',
            cell: (row) => {
                let color = "";
                let status = "";
                if (row.rateStatus === RateGridStatus.PENDING_APPROVAL) {
                    color = $mysaYellow;
                    status = "Pending";
                }
                if (row.rateStatus === RateGridStatus.APPROVED) {
                    color = $mysaTeal;
                    status = "Completed";
                }
                if (row.rateStatus === 'activated') {
                    color = $mysaTeal;
                    status = "Live";
                }
                if (row.rateStatus === 'accepted') {
                    color = $mysaTeal;
                    status = "Offline";
                }

                return (
                    <span style={{ color: color, fontWeight: 'bold' }} className='text-capitalize'>{status}</span>
                )
            }
        },
        {
            name: 'Action',
            button: true,
            cell: (row: any, index) => {
                return (<>
                    <OverlayTrigger
                        placement="top"
                        delay={overlayTriggerDelay}
                        overlay={<Tooltip id='tooltip-table-top'>View / Edit</Tooltip>}
                    >
                        {
                            (row.typeOfRate === "Standard") ?
                                <img className='iconSizes' src={ViewEdit} alt="" onClick={() => { setIsMain(row.isNot); setRateId(row.key); setFormOpen(true); setRateType("Standard"); }} /> :
                                (row.client === "MYO Corporate Rate") && (row.typeOfRate !== "Standard") ? <img className='iconSizes' src={ViewEdit} alt="" onClick={() => { setRateId(row.key); setFormOpen(true); setRateType("StandardSeasonal"); setMyoCorSeasonName(row.typeOfRate); setMyoCorSeasonIndex(index - 1) }} /> :
                                    (row.client !== "MYO Corporate Rate") && (row.typeOfRate === "Negotiated Rate") ? <img className='iconSizes' src={ViewEdit} alt="" onClick={() => { setRateId(row.key); setFormOpen(true); setRateType("NegotiatedRateUpdate"); setClientId(row.clientId) }} /> :
                                        (row.client !== "MYO Corporate Rate") && (row.typeOfRate !== "Negotiated Rate") ? <img className='iconSizes' src={ViewEdit} alt="" onClick={() => { setRateId(row.key); setFormOpen(true); setRateType("NegotiatedRateSeasonalUpdate"); setClientId(row.clientId); setMyoCorSeasonName(row.typeOfRate); setSeasonalRateId(row.seasonalRateId) }} /> : <></>
                        }

                    </OverlayTrigger>

                    {(row.isNot === false) && <OverlayTrigger
                        placement="top"
                        delay={overlayTriggerDelay}
                        overlay={<Tooltip id='tooltip-table-top'>Add Seasonal Rate</Tooltip>}
                    >
                        {
                            (row.client === "MYO Corporate Rate") ? <p onClick={() => { setRateId(row.key); setFormOpen(true); setRateType("StandardSeasonalCreate"); }} style={{ fontWeight: 'bold', fontSize: '33px', lineHeight: '45px', color: '#000000', marginTop: '-6px', marginLeft: '5%', cursor: 'pointer' }}>+</p> :
                                (row.client !== "MYO Corporate Rate") ? <p onClick={() => { setRateId(row.key); setClientId(row.clientId); setFormOpen(true); setRateType("NegotiatedRateSeasonalCreate"); }} style={{ fontWeight: 'bold', fontSize: '33px', lineHeight: '45px', color: '#000000', marginTop: '-6px', marginLeft: '5%', cursor: 'pointer' }}>+</p> : <></>
                        }
                    </OverlayTrigger>}

                </>)
            },
        },
    ];

    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    return (
        <>
            <div className='mt-1'>
                {
                    hideMYOClientCreate ? <Row>
                        <Col md={9}></Col>
                        <Col md={3}>
                            <div style={{
                                textAlign: "right",
                                position: "relative",
                                top: "-81px",
                                right: "-18px"
                            }}>
                                <Button style={formButton} hidden={formOpen} onClick={() => { setRateId(null); setFormOpen(true); setRateType("NegotiatedRate") }} ><b>Add New Rate</b></Button>
                            </div>
                        </Col>
                    </Row> : <Row>
                        <Col md={9}></Col>
                        <Col md={3}>
                            <div style={{
                                textAlign: "right",
                                position: "relative",
                                top: "-81px",
                                right: "-18px"
                            }}>
                                <Button style={formButton} hidden={formOpen} onClick={() => { setRateId(null); setFormOpen(true); setRateType("Standard"); }} ><b>Create MYO Corporate Rate</b></Button>
                            </div>
                        </Col>
                    </Row>
                }
            </div>
            <div className='mt-5'>
                <DataTable
                    columns={columns}
                    data={rateGridData}
                    pagination
                    striped={true}
                    customStyles={tableStyles}
                    theme='mysaTable'
                    fixedHeader={true}
                    noDataComponent={'Updating records'}
                />
            </div>
        </>
    )
};

export default RateManagementSetUp;