import { FC, useState, useEffect } from "react";
import { UserAccess } from "../../../../common/constants";
import { isCorporateProgrammeModuleAvailable } from "../../../../common/functions";
import AccessDenied from "../../../common/AccessDenied";
import { Tab, Row, Col, Nav } from 'react-bootstrap';
import { navStyle } from "../../../../common/components-style";
import { useHistory } from 'react-router-dom';
import ReportData from "../form/ReportData";
import ChainsReportData from "../form/ChainsReportData";

const LocationOverviewDataFrom: FC<any> = ({ reportId, programmeId, setFormOpen, reload, selectedProgramme, selectedClient, startDate, endDate, location, selectedClientName, accommodationType, reportName, associateProgrammes, currency }): JSX.Element => {
    const [tabKey, setTabKey] = useState<any>(location.state?.tabKey ? location.state?.tabKey : 'locationOverviewReport');
    const history = useHistory();

    useEffect(() => {
        if (location?.state?.defaultKey) {
            setTabKey(location?.state?.defaultKey);
        }
    }, [location?.state?.defaultKey]);

    const redirectContractPropertyData = () => {
        history.push('/admin/contracted-property-data', { programmeId: programmeId, selectedClient: selectedClient, reportId: reportId, reportName: reportName, currency: currency, accommodationType: accommodationType, associateProgrammes: associateProgrammes })
    }

    return (
        <div style={{ marginLeft: '-40px', marginTop: '-38px' }} >
            {reportId ? <Tab.Container defaultActiveKey="locationOverviewReport" key={tabKey} activeKey={tabKey} onSelect={(tab) => setTabKey(tab)}>
                <Row >
                    <Col sm={2}>
                        <Nav variant="pills" className="flex-column secondry-menu" style={navStyle}>
                            <div style={{ marginTop: '0%' }}>
                                {reportId && <Nav.Item>
                                    <Nav.Link eventKey="locationOverviewReport">Location Overview</Nav.Link>
                                </Nav.Item>}
                                {reportId && <Nav.Item>
                                    <Nav.Link eventKey="contractedProperties" onClick={redirectContractPropertyData}>Contracted Properties</Nav.Link>
                                </Nav.Item>}
                                {reportId && <Nav.Item>
                                    <Nav.Link eventKey="chains">Chains</Nav.Link>
                                </Nav.Item>}
                            </div>
                        </Nav>
                    </Col>
                    <Col sm={10}>
                        <Tab.Content>
                            <Tab.Pane eventKey="locationOverviewReport">
                                <div>
                                    {isCorporateProgrammeModuleAvailable(selectedProgramme?.clientId, selectedProgramme?.key, UserAccess.programme_access.programmeAdmin.code) ? <ReportData programmeId={programmeId} reportId={reportId} setFormOpen={setFormOpen} reload={reload} selectedClient={selectedClient} selectedProgramme={selectedProgramme} accommodationType={accommodationType} reportName={reportName} currency={currency} associateProgrammes={associateProgrammes} /> : <AccessDenied />}
                                </div>
                            </Tab.Pane>
                            <Tab.Pane eventKey="chains">
                                <div>
                                    {isCorporateProgrammeModuleAvailable(selectedProgramme?.clientId, selectedProgramme?.key, UserAccess.programme_access.programmeAdmin.code) ? <ChainsReportData programmeId={programmeId} reportId={reportId} setFormOpen={setFormOpen} reload={reload} selectedClient={selectedClient} selectedProgramme={selectedProgramme} accommodationType={accommodationType} reportName={reportName} currency={currency} associateProgrammes={associateProgrammes} /> : <AccessDenied />}
                                </div>
                            </Tab.Pane>
                        </Tab.Content>
                    </Col>
                </Row>
            </Tab.Container> : null
            }
        </div>
    )
}


export default LocationOverviewDataFrom;
